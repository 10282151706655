// import React, { Fragment } from "react";
// import { Link } from "react-router-dom";
// import icon18 from "../../assets/img/icons/7.png";
// import { Dropdown } from "react-bootstrap";
// import { Item } from "react-bootstrap";
// import { Menu } from "react-bootstrap";
// import swal from "sweetalert";
// import defaultAvatar from "../../assets/img/nophoto.png";

// const FeatureProperyListCard = ({
//   agentId,
//   auth,
//   userId,
//   propertyId,
//   propertyLink,
//   index,
//   image,
//   logo,
//   agentName,
//   title,
//   url,
//   newerprice,
//   olderprice,
//   ftitle,
//   imagealt,
//   area,
//   img,
//   featuresTitle,
//   featuresIcon,
//   currency,
//   address,
//   beds,
//   area_unit_name,
//   flat_type,
//   super_area,
//   carpet_area,
//   land_area,
//   sub_category_name,
//   actionDeleteAgentProperty,
//   userAuth,
//   handleSelectPropertyCard,
//   getPropertyId,
//   isSingle,
//   subDomain,
//   theme,
// }) => {
//   console.log(propertyId);
//   return (
//     <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 ">
//       <div className="single-feature">
//         {sub_category_name && (
//           <div className="overlay-sub-category-name">{sub_category_name}</div>
//         )}
//         {parseInt(userId) === agentId && (
//           <Dropdown>
//             <Dropdown.Toggle
//               className="delete-property"
//               variant="light"
//               id="dropdown-button-drop-left"
//             >
//               <i className="fa fa-ellipsis-v" />
//             </Dropdown.Toggle>
//             <Dropdown.Menu
//               className={"m-0"}
//               style={{ transform: "translate(84.1111px, 41.8889px)" }}
//             >
//               <Dropdown.Item>
//                 <Link to={`/update-property/${propertyId && propertyId}`}>
//                   <i className="fa fa-pencil mr-1" />
//                   Update
//                 </Link>
//               </Dropdown.Item>
//               <Dropdown.Item>
//                 <Link to={`/property-images/${propertyId && propertyId}`}>
//                   <i className="fa fa-picture-o mr-1" />
//                   Upload
//                 </Link>
//               </Dropdown.Item>
//               <Dropdown.Item>
//                 <Link
//                   to={"#exampleModalCenter"}
//                   data-toggle="modal"
//                   data-target="#exampleModalCenter"
//                   className=""
//                   onClick={() => {
//                     swal({
//                       title: "Are you sure you want to delete this Property?",
//                       text: "Once deleted, you will not be able to recover this property!",
//                       icon: "warning",
//                       buttons: true,
//                       dangerMode: true,
//                     }).then((willDelete) => {
//                       if (willDelete) {
//                         swal("Poof! Your property  has been deleted!", {
//                           icon: "success",
//                         });
//                         actionDeleteAgentProperty(propertyId, agentId);
//                       } else {
//                         swal("Your property is safe!");
//                       }
//                     });
//                     // window.confirm('Are you sure you want to delete this Property?')&&)
//                   }}
//                 >
//                   <i className="fa fa-trash-o mr-1" />
//                   Delete
//                 </Link>
//               </Dropdown.Item>

//               <Dropdown.Item>
//                 <Link
//                   to={"#singlePageWebModal"}
//                   data-toggle="modal"
//                   data-target="#singlePageWebModal"
//                   onClick={() =>
//                     getPropertyId(propertyId, isSingle, subDomain, theme)
//                   }
//                 >
//                   <i className="fa fa-globe mr-1" />
//                   Publish Web
//                 </Link>
//               </Dropdown.Item>
//               {propertyLink && (
//                 <Dropdown.Item>
//                   <Link
//                     onClick={() => {
//                       window.open(
//                         `${propertyLink}`,
//                         "_blank",
//                         "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1200,height=900"
//                       );
//                     }}
//                   >
//                     <i className="fa fa-eye mr-1" />
//                     Preview Web
//                   </Link>
//                 </Dropdown.Item>
//               )}
//             </Dropdown.Menu>
//           </Dropdown>
//         )}

//         <div className="thumb">
//           <img
//             className={"fix-property-image-size"}
//             src={image}
//             alt={imagealt}
//           />
//         </div>
//         <div className="details">
//           <a href="#" className="feature-logo">
//             <img
//               src={logo}
//               alt={agentName ? agentName : defaultAvatar}
//               title={agentName}
//             />
//           </a>
//           <p className="author">
//             <i className="fa fa-user" /> {agentName}
//           </p>
//           {/* <h6 className="title readeal-top">
//             <Link to={url}>

//                 {title ? `${title} ,` : ""}
//                 {featuresTitle ? `${featuresTitle} ,` : ""}
//                 {area &&
//                   `${area ? area : ""} ${
//                     area_unit_name ? area_unit_name : ""
//                   } ,`}
//                 {carpet_area && `${carpet_area} ${area_unit_name} `}
//                 {land_area && `${land_area} ${area_unit_name}`} {flat_type}
//               </Link>

//           </h6> */}
//           <h6 className="title readeal-top mt-2">
//             <Link to={url}>
//               {/* {title} `${area_unit_name}`
//               {land_area && `${land_area}${area_unit_name}`} */}
//               {title ? `${title} ,` : ""}
//               {featuresTitle ? `${featuresTitle} ,` : ""}
//               {area &&
//                 `${area ? area : ""} ${area_unit_name ? area_unit_name : ""} ,`}
//               {carpet_area && `${carpet_area} ${area_unit_name} `}
//               {land_area && `${land_area} ${area_unit_name}`} {flat_type}
//             </Link>
//           </h6>
//           <h6 className="price">Price:</h6>
//           <h6 className="price">
//             <i
//               className={currency === "INR" ? "fa fa-rupee" : "fa fa-dollar"}
//             />
//             {newerprice}
//           </h6>
//           <del>{olderprice}</del>
//           <ul className="info-list d-flex justify-content-between align-items-center">
//             {beds && (
//               <li>
//                 <i className="fa fa-bed" /> {beds} Bedroom
//               </li>
//             )}
//             <li>
//               <img src={icon18} alt="image" /> {area ? area : "0"}
//               {area_unit_name}
//             </li>
//           </ul>
//           <ul className="contact-list d-flex readeal-top align-items-center">
//             {userAuth ? (
//               <li>
//                 <Link
//                   className="message"
//                   to={auth ? `/private-chat/${agentId}` : "/register"}
//                 >
//                   <i className="fa fa-comment" />
//                 </Link>
//               </li>
//             ) : (
//               ""
//             )}
//             <li>
//               <Link
//                 className="message"
//                 id="settings"
//                 data-toggle="modal"
//                 data-target="#createBroadcastModal"
//                 data-backdrop="static"
//                 data-keyboard="false"
//                 title={"Share Property"}
//                 onClick={() => handleSelectPropertyCard(propertyId)}
//               >
//                 <i className="fa fa-share-alt" />
//               </Link>
//             </li>

//             <li className="readeal-top w-100 text-right">
//               <Link className="btn btn-yellow mt-0" to={url}>
//                 View Details
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };
// FeatureProperyListCard.defaultProps = {
//   property_id: "",
// };

// export default FeatureProperyListCard;
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import icon18 from "../../assets/img/icons/7.png";
import { Dropdown } from "react-bootstrap";
import { Item } from "react-bootstrap";
import { Menu } from "react-bootstrap";
import swal from "sweetalert";
import defaultAvatar from "../../assets/img/nophoto.png";

const FeatureProperyListCard = ({
  propertyLink,
  agentId,
  auth,
  userId,
  propertyId,
  index,
  image,
  logo,
  agentName,
  title,
  url,
  newerprice,
  olderprice,
  ftitle,
  imagealt,
  area,
  img,
  featuresTitle,
  featuresIcon,
  currency,
  address,
  beds,
  area_unit_name,
  flat_type,
  super_area,
  carpet_area,
  land_area,
  sub_category_name,
  actionDeleteAgentProperty,
  userAuth,
  handleSelectPropertyCard,
  getPropertyId,
  isSingle,
  subDomain,
  theme,
}) => {
  const d1 = newerprice && newerprice.replace(/[^0-9.-]/g, "");
  return (
    <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 ">
      <div className="single-feature">
        {sub_category_name && (
          <div className="overlay-sub-category-name">{sub_category_name}</div>
        )}
        {parseInt(userId) === agentId && (
          <Dropdown>
            <Dropdown.Toggle
              className="delete-property"
              variant="light"
              id="dropdown-button-drop-left"
            >
              <i className="fa fa-ellipsis-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className={"m-0"}
              style={{ transform: "translate(84.1111px, 41.8889px)" }}
            >
              <Dropdown.Item>
                <Link to={`/update-property/${propertyId && propertyId}`}>
                  <i className="fa fa-pencil mr-1" />
                  Update
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to={`/property-images/${propertyId && propertyId}`}>
                  <i className="fa fa-picture-o mr-1" />
                  Upload
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link
                  to={"#exampleModalCenter"}
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  className=""
                  onClick={() => {
                    swal({
                      title: "Are you sure you want to delete this Property?",
                      text: "Once deleted, you will not be able to recover this property!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        swal("Poof! Your property  has been deleted!", {
                          icon: "success",
                        });
                        actionDeleteAgentProperty(propertyId, agentId);
                      } else {
                        swal("Your property is safe!");
                      }
                    });
                    // window.confirm('Are you sure you want to delete this Property?')&&)
                  }}
                >
                  <i className="fa fa-trash-o mr-1" />
                  Delete
                </Link>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link
                  to="#singlePageWebModal"
                  data-toggle="modal"
                  data-target="#singlePageWebModal"
                  onClick={() =>
                    getPropertyId(propertyId, isSingle, subDomain, theme)
                  }
                >
                  <i className="fa fa-globe mr-1" />
                  Publish Web
                </Link>
              </Dropdown.Item>
              {propertyLink && (
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      window.open(
                        `${propertyLink}`,
                        "_blank",
                        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1200,height=900"
                      );
                    }}
                  >
                    <i className="fa fa-eye mr-1" />
                    preview Web
                  </Link>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}

        <div className="thumb">
          <img
            style={{ maxHeight: "173px" }}
            className={"fix-property-image-size"}
            src={image ? image : defaultAvatar}
            alt={imagealt ? imagealt : ""}
          />
        </div>
        <div className="details">
          <a href="#" className="feature-logo">
            <img
              src={logo}
              alt={agentName ? agentName : defaultAvatar}
              title={agentName}
            />
          </a>
          <p className="author">
            <i className="fa fa-user" /> {agentName}
          </p>
          <h6 className="title readeal-top">
            <Link to={url}>
              {title ? `${title} ,` : ""}
              {featuresTitle ? `${featuresTitle} ,` : ""}
              {area &&
                `${area ? area : ""} ${
                  area_unit_name ? area_unit_name : ""
                } ,`}
              {carpet_area && `${carpet_area} ${area_unit_name}`}
              {land_area && `${land_area} ${area_unit_name}`}
              {super_area && `${super_area} ${area_unit_name} ,`} {flat_type}
              {land_area}
            </Link>
          </h6>
          <h6 className="price">Price:</h6>
          <h6 className="price">
            <i
              className={currency === "INR" ? "fa fa-rupee" : "fa fa-dollar"}
            />
            {newerprice
              ? d1 >= 10000000
                ? (d1 / 10000000).toFixed(2) + " Cr"
                : d1 >= 100000
                ? (d1 / 100000).toFixed(2) + " Lac"
                : newerprice
              : ""}
          </h6>
          <del>{olderprice}</del>
          <ul className="info-list d-flex justify-content-between align-items-center">
            {beds && (
              <li>
                <i className="fa fa-bed" /> {beds} Bedroom
              </li>
            )}
            <li>
              <img src={icon18} alt="image" />
              {area
                ? area
                : carpet_area
                ? carpet_area
                : land_area
                ? land_area
                : super_area
                ? super_area
                : ""}
              {area_unit_name}
            </li>
          </ul>
          <ul className="contact-list d-flex readeal-top align-items-center">
            {userAuth ? (
              <li>
                <Link
                  className="message"
                  to={auth ? `/private-chat/${agentId}` : "/register"}
                >
                  <i className="fa fa-comment" />
                </Link>
              </li>
            ) : (
              ""
            )}
            <li>
              <Link
                className="message"
                id="settings"
                data-toggle="modal"
                data-target="#createBroadcastModal"
                data-backdrop="static"
                data-keyboard="false"
                title={"Share Property"}
                onClick={() => handleSelectPropertyCard(propertyId)}
              >
                <i className="fa fa-share-alt" />
              </Link>
            </li>

            <li className="readeal-top w-100 text-right">
              <Link className="btn btn-yellow mt-0" to={url}>
                View Details
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
FeatureProperyListCard.defaultProps = {
  property_id: "",
};

export default FeatureProperyListCard;
