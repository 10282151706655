import React, {Component, Fragment} from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer';
import AgentProfileDropdownMenu from "../global-components/Agent-Profile/agent-profile-dropdown-menu";
import connect from "react-redux/es/connect/connect";
import {
    actionAcceptFriendRequest,
    actionGetAgent,
    actionGetAgents
    , actionRejectRequest,
    actionSendFriendRequest,
    actionUnFriend,

} from "../../actions/agentAction";
import {getUserRelation} from '../../actions/authAction';
import img from '../../assets/img/news/21.png';
import {AGENT_PROFILE} from "../../actions/types";
import defaultAvatar from "../../assets/img/nophoto.png";
import AgentDataItemCard from "./AgentDataItemCard";
import {Link} from "react-router-dom";
import {IMAGE_BASE_URL} from "../../web-config";
import AgentProfileLoaderCard from "./agent-profile-loader-card";
import Slider from "react-slick/lib";
import PrevArrow from "../common/PrevArrow";
import NextArrow from "../common/NextArrow";
import sectiondata from '../../data/sections.json';


class AgentProfile extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    componentDidMount() {

        this.props.match.params.userId && this.props.actionGetAgent(this.props.match.params.userId);
        // this.props.actionGetAgents('');
        setTimeout(() => {
            (this.props.user && this.props.user) && this.props.getUserRelation(this.props.user.user_id, this.props.match.params.userId);
        }, 100);


    }

    componentDidUpdate(prevProps, prevState) {
        (prevProps.user !== this.props.user) && this.props.getUserRelation(this.props.user.user_id, this.props.match.params.userId);
    }


    render() {

        let {first_name, last_name, email, profile_pic, username, specialty, mobile, website, company_name, company_zip_code, agent_country, agent_state, agent_city, street_address, zip_code, company_street_address, team_name, company_state, home_number, number_of_years_in_business, designations, languages_spoken, hobbies, cites_expert, subdivisions_expert, user_relation_object, user_relation, facebook_link, instagram_link, twitter_link,testimonials} = this.props.agent.agentData;
        let userId = this.props.match.params.userId;
        let {isAuthenticated, getAgentLoader, history, user, actionSendFriendRequest, actionRejectRequest, actionAcceptFriendRequest, actionUnFriend} = this.props;
// console.log(this.props.agent.agents.);

        const settings = {

            loop: true,
            infinite: true,

            // autoPlayTimeout: 1000,
            dots: true,
            nav: false,
            smartSpeed: 1500,
            margin: 30,
            slidesToShow:3,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 2000,

            // dots: false,
            // slidesToScroll: 1,
            // infinite: true,
            // speed: 3000,
            // autoplaySpeed: 2000,
            // loop: true,
            // slidesToShow: 4,
            // autoplay: true,
            prevArrow: <PrevArrow/>,
            nextArrow: <NextArrow/>,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        dots: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]

        };
        let data = sectiondata.partner;

        return (
            <div className="agent-profile-section">
                <div className={"bg-light"}>
                    <Fragment>
                        <Navbar/>
                        <PageHeader headertitle="Agent Profile"/>
                        <AgentProfileDropdownMenu scenario={AGENT_PROFILE} match={this.props.match}
                                                  auth={{isAuthenticated}}
                                                  userRelation={user_relation && user_relation} loggedin={this.props.user.user_id}/>


                        {getAgentLoader &&
                        <div className="container">
                            <div className="row">
                                <div className="text-center col-12">
                                    <AgentProfileLoaderCard/>
                                </div>
                            </div>
                        </div>
                        }
                        {this.props.agent.agentData &&
                        <div className="add-new-property-area mg-bottom-100">
                            <div className="container w-100">
                                <div className="page-content page-container" id="page-content">
                                    <div className="padding">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="card user-card-full">
                                                    <div className="row">
                                                        <div
                                                            className="col-sm-12 col-md-4 col-lg-4 col-xl-3 bg-c-lite-green user-profile">
                                                            <div className="card-block text-center text-white">
                                                                <div className="mb-2">
                                                                    <img
                                                                        className="profile-img rounded-circle ml-2"
                                                                        src={profile_pic ? `${IMAGE_BASE_URL}uploads/${profile_pic}` : defaultAvatar}
                                                                        alt="img"/>
                                                                </div>
                                                                <h6 className="f-w-600 text-md-center">{`${first_name} ${last_name}`}</h6>
                                                                <p className={'username font-italic'}>@{username}</p>


                                                                {/*<div className="d-flex justify-content-around profile-btn">*/}
                                                                {/*<Link to='' className={'btn text-light btn-bg px-2'}><i*/}
                                                                {/*className="fa fa-user-plus"/> Add Friend</Link>*/}
                                                                {/*<Link to={`/private-chat/${userId}`}*/}
                                                                {/*className={'btn text-light btn-bg px-2'}><i*/}
                                                                {/*className="fa fa-comments-o"/> Message</Link>*/}
                                                                {/*</div>*/}

                                                                {/*HMS Logic Starts From Here*/}
                                                                {((user_relation && user_relation) === 'NoRelation' && parseInt(userId) !== parseInt(user.user_id)) &&
                                                                <div
                                                                    className="d-flex justify-content-around profile-btn">
                                                                    <Link to="/"
                                                                          className={'btn text-light btn-bg px-2'}
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              isAuthenticated ? actionSendFriendRequest(user.user_id, userId) : history.push('/register');
                                                                          }}> <i className="fa fa-user-plus"/> Add
                                                                        Friend</Link>
                                                                </div>
                                                                }


                                                                {(user_relation && user_relation) === 'FRIEND' &&
                                                                <div
                                                                    className="d-flex justify-content-around profile-btn">
                                                                    <Link to={`/private-chat/${userId}`}
                                                                          className={'btn text-light btn-bg px-2'}><i
                                                                        className="fa fa-comments-o"/> Message</Link>
                                                                    <Link to="/"
                                                                          className={'btn text-light btn-bg px-2'}
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              const request = {
                                                                                  user_id: user.user_id,
                                                                                  related_user_id: userId
                                                                              };
                                                                              actionUnFriend(request);
                                                                          }}><i
                                                                        className="fa fa-user-times"/> Remove</Link>
                                                                </div>}


                                                                {/*{(user_relation === 'REQUEST' && parseInt(user_relation_object.user_id) !== parseInt(user.user_id)) &&*/}
                                                                {/*<Link to="/" className={'btn text-warning 2'} onClick={(e) => {*/}
                                                                {/*e.preventDefault();*/}
                                                                {/*const request = {*/}
                                                                {/*user_id: user.user_id,*/}
                                                                {/*related_user_id: userId,*/}
                                                                {/*itemId: userId*/}
                                                                {/*};*/}
                                                                {/*isAuthenticated ? actionAcceptFriendRequest(request) : history.push('/register');*/}
                                                                {/*}}> <i className="fa fa-check"/> Accept</Link>}*/}


                                                                {(user_relation === 'REQUEST' && parseInt(user_relation_object.user_id) === parseInt(user.user_id)) &&
                                                                <div
                                                                    className="d-flex justify-content-around profile-btn">
                                                                    <Link to="/"
                                                                          className={'btn text-light btn-bg px-2'}
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              const request = {
                                                                                  user_id: user.user_id,
                                                                                  related_user_id: userId,
                                                                                  itemId: userId
                                                                              };
                                                                              isAuthenticated ? actionRejectRequest(request) : history.push('/register');
                                                                          }}> <i className="fa fa-close"/> Cancel</Link>
                                                                </div>}

                                                                {/*HMS Logic Ends From Here*/}

                                                                {((user_relation === 'REQUEST' && parseInt(user_relation_object.user_id) !== parseInt(user.user_id))) &&
                                                                <div
                                                                    className="d-flex justify-content-around profile-btn">
                                                                    <Link to="/"
                                                                          className={'btn text-light btn-bg px-2'}
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              const request = {
                                                                                  user_id: user.user_id,
                                                                                  related_user_id: userId,
                                                                                  itemId: userId
                                                                              };
                                                                              isAuthenticated ? actionAcceptFriendRequest(request) : history.push('/register');
                                                                          }}> <i className="fa fa-check"/> Accept</Link>

                                                                    <Link to="/"
                                                                          className={'btn text-light btn-bg px-2'}
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              const request = {
                                                                                  user_id: user.user_id,
                                                                                  related_user_id: userId,
                                                                                  itemId: userId
                                                                              };
                                                                              isAuthenticated ? actionRejectRequest(request) : history.push('/register');
                                                                          }}> <i className="fa fa-close"/> Cancel</Link>
                                                                </div>}

                                                                <ul className="social-link list-unstyled d-flex justify-content-center text-dark">
                                                                    {facebook_link &&
                                                                    <li><a href={facebook_link} target="_blank"><i
                                                                        className="fa fa-facebook"
                                                                        aria-hidden="true"/></a></li>}
                                                                    {twitter_link &&
                                                                    <li><a href={twitter_link} target="_blank"><i
                                                                        className="fa fa-twitter"
                                                                        aria-hidden="true"/></a></li>}
                                                                    {instagram_link &&
                                                                    <li><a href={instagram_link} target="_blank"> <i
                                                                        className="fa fa-instagram"
                                                                        aria-hidden="true"/></a></li>}

                                                                </ul>


                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-8 col-lg-8  col-xl-9">
                                                            <div className="card-block">
                                                                <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Personal
                                                                    Information</h6>
                                                                <div className="row">

                                                                    <AgentDataItemCard titile={"Mobile"} value={' '}
                                                                                       mobile={mobile}
                                                                                       icon={'fa fa-phone link_img'}/>
                                                                    <AgentDataItemCard titile={"Email"} value={email}
                                                                                       email={email}
                                                                                       icon={'fa fa-envelope link_img'}/>
                                                                    <AgentDataItemCard titile={"Specialty"}
                                                                                       value={specialty}
                                                                                       icon={'fa fa-star link_img'}/>
                                                                    <AgentDataItemCard ItemCard titile={"Website"}
                                                                                       value={website}
                                                                                       url={website ? website : ''}
                                                                                       icon={'fa fa-chrome link_img'}/>

                                                                </div>
                                                                <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">Address</h6>
                                                                <div className="row">

                                                                    <AgentDataItemCard titile={"Country"}

                                                                                       value={agent_country}
                                                                                       icon={'fa fa-globe link_img'}/>
                                                                    <AgentDataItemCard titile={"State"}
                                                                                       value={agent_state}
                                                                                       icon={'fa fa-building link_img'}/>

                                                                    <AgentDataItemCard titile={"City"}
                                                                                       value={agent_city}
                                                                                       icon={'fa fa-building-o link_img'}/>
                                                                    <AgentDataItemCard titile={"Zip"} value={zip_code}
                                                                                       icon={'fa fa-map-marker link_img'}/>


                                                                </div>
                                                                <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">Company
                                                                    Information</h6>
                                                                <div className="row">


                                                                    <AgentDataItemCard titile={"Company Name"}
                                                                                       value={company_name}
                                                                                       icon={'fa fa-building-o link_img'}/>
                                                                    <AgentDataItemCard titile={"Team Name"}
                                                                                       value={team_name}
                                                                                       icon={'fa fa-group link_img'}/>
                                                                    <AgentDataItemCard titile={"Street Address"}
                                                                                       value={company_street_address}
                                                                                       icon={'fa fa-map-signs link_img'}/>
                                                                    <AgentDataItemCard titile={"State"}
                                                                                       value={company_state}
                                                                                       icon={'fa fa-podcast link_img'}/>
                                                                    <AgentDataItemCard titile={"Zip"}
                                                                                       value={company_zip_code}
                                                                                       icon={'fa fa-map-marker link_img'}/>
                                                                    <AgentDataItemCard titile={"Home No."}
                                                                                       value={home_number}
                                                                                       icon={'fa fa-home link_img'}/>
                                                                    <AgentDataItemCard
                                                                        titile={"No.Of Years in Business"}
                                                                        value={number_of_years_in_business}
                                                                        icon={'fa fa-calendar-check-o link_img'}/>
                                                                    <AgentDataItemCard titile={"Designations"}
                                                                                       value={designations}
                                                                                       icon={'fa fa-street-view link_img'}/>
                                                                    <AgentDataItemCard titile={"No.Of Languages Spoken"}
                                                                                       value={languages_spoken}
                                                                                       icon={'fa fa-language link_img'}/>
                                                                    <AgentDataItemCard titile={"Hobbies"}
                                                                                       value={hobbies}
                                                                                       icon={'fa fa-hashtag link_img'}/>
                                                                    <AgentDataItemCard titile={"Cites Expert"}
                                                                                       value={cites_expert}
                                                                                       icon={'fa fa-user-circle link_img'}/>
                                                                    <AgentDataItemCard titile={"Sub divisions Expert"}
                                                                                       value={subdivisions_expert}
                                                                                       icon={'fa fa-user-secret link_img'}/>

                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <Slider {...settings}>
                                                {testimonials && testimonials.map((item, i) =>
                                                    <div key={i} className="item">
                                                        <div className="single-service  text-center">
                                                            <div className="thumb double-img w-50">
                                                                <img src={item.image}
                                                                     style={{borderRadius: '20px'}} alt="Agent_profile"/>
                                                            </div>
                                                            <div className="details readeal-top mt-4">
                                                                <h4 className="text-dark"> {item.title} </h4>
                                                                <p style={{ height: '120px',overflowY: 'auto'}}>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        <Footer/>
                    </Fragment>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        agent: state.agent,
        getAgentLoader: state.getAgentLoader,
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated,
    };
};
export default connect(mapStateToProps, {
    actionGetAgent,
    actionGetAgents,
    actionSendFriendRequest,
    actionUnFriend,
    actionAcceptFriendRequest,
    actionRejectRequest,
    getUserRelation,
})(AgentProfile);