import React from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import UserListSection from '../section-components/agent-list';
import Footer from '../global-components/footer';
import {AGENTS} from "../../actions/types";

const AgentList = () => {
    window.scrollTo(0, 0);
    return <div>
        <Navbar scenario={AGENTS}/>
        <PageHeader headertitle="Agents List" />
        <UserListSection />
        <Footer />
    </div>
};

export default AgentList;

