import React from 'react';
import Navbar from "../global-components/navbar";
import {useParams} from "react-router-dom";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import PropertyImageList from "./property-image-list";

const PropertyImages = (props) => {
    let params = useParams();
    return (
        <div>
            <Navbar/>
            <PageHeader headertitle="Property Images"/>
            <PropertyImageList propertyId={params.propertyId}/>
            <Footer/>
        </div>
    );
};

export default PropertyImages;