import React from 'react';
import defaultUser from "../../assets/img/nophoto.png";
import broadcastIcon from '../../assets/img/broadcast_icon.png'
import classnames from "classnames";

const ChatUser = ({user_id, name, lastMessage, profile_pic, handleSelectChatPerson, chatPerson, isActive, forBroadCastSelect,type}) => {
    return (
        <li className={classnames('contact', {
            'active-chat': isActive
        })} onClick={() => handleSelectChatPerson(user_id,type)}>
            <div className="wrap">
                <div className="row">
                    <div className="col-2 chat-user-img">
                        {type === "Agent" &&<img src={profile_pic ? profile_pic : defaultUser} alt=""/>}
                        {type === "Broadcast" &&<img src={broadcastIcon} alt="Broadcast" style={{background:'#e0e0e0'}}/>}

                    </div>
                    <div className="col-10 chat-recent-msg">
                        <div className="meta">
                            <p className="name" >{name} </p>
                            {type === "Broadcast" && <i className="fa fa-bullhorn broadcast-icon"/> }
                            <p className="preview">{lastMessage ? lastMessage : 'Say Hey!!!'}</p>

                        </div>
                    </div>
                </div>

            </div>
        </li>
    );
};
ChatUser.defaultProps = {
    forBroadCastSelect: false
};
export default ChatUser;