import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {actionGetAgents} from "../../actions/agentAction";
import HomePageAgentCard from "../common/HomePageAgentCard";
import DataNotFound from "./DataNotFound";
import Spinner from "./Spinner";
import HomePageAgentListLoaderCard from "../agent-list/home-page-agent-list-loader-card";

class Explore extends Component {

    constructor(props) {
        super(props);

        this.state = {
            q: ''
        }
    }

    componentDidMount() {
        this.props.actionGetAgents(this.state.q);
    }


    render() {
        const {agents,agentLoader} = this.props.agent;

        return <div className="explore-area pd-top-85">
            <div className="container">
                <div className="section-title text-center">
                    <h2 className="title">Meet our Agents</h2>
                </div>
                <div className="row">
                    {(!agentLoader && agents && agents.length >0 )&& agents.map((item, i) => i < 4 &&
                        <HomePageAgentCard key={i} index={i} item={item}/>)}

                    {(!agentLoader && agents.length < 1) &&
                    <div className="text-center col-md-12"><DataNotFound
                        message={`Data not found.`}/></div>}
                    {/*{agentLoader && <div className="text-center col-md-12"><Spinner width={120}/></div>}*/}
                    {agentLoader && <div className="row">
                        <HomePageAgentListLoaderCard/>
                        <HomePageAgentListLoaderCard/>
                        <HomePageAgentListLoaderCard/>
                        <HomePageAgentListLoaderCard/>
                    </div>}
                </div>
                {!agentLoader &&<div className="text-center">
                    <Link to="/agent-list" className="btn btn-yellow">View All</Link>
                </div>
                }

            </div>
        </div>


    }
}


const mapStateToProps = state => {
    return {
        agent: state.agent,
    };
};

export default connect(mapStateToProps, {actionGetAgents})(Explore);
