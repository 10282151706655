import React from 'react';
import Navbar from "../global-components/navbar";
import PageNotFound404Card from "./page-not-found-404-card";
import Footer from "../global-components/footer";
import PageHeader from "../global-components/page-header";

const PageNotFound404 = () => {
    return (
        <div>
            <Navbar/>
            <PageHeader headertitle="Page Not Found"/>
            <div style={{background:'#ededed'}}>
            <PageNotFound404Card/>
            </div>
            <Footer/>
        </div>
    );
};

export default PageNotFound404;