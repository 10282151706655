import React, {Component} from 'react';
import classnames from "classnames";
import {connect} from 'react-redux';
import {actionLogin} from "../../actions/authAction";
import btnLoader from "../../assets/img/button-loader.svg";
import {Link} from "react-router-dom";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errors: {}
        };
    }

    onChange = e => this.setState({[e.target.name]: e.target.value});

    handleLoginValidation = () => {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;

        // Checkpoint for email is empty
        if (!fields.email.trim()) {
            formIsValid = false;
            errors.email = "Please Enter Your Email or Username";
        }
        if (!fields.password) {
            formIsValid = false;
            errors.password = "Please Enter Your Password";
        }

        this.setState({errors: errors});
        return formIsValid;
    };

    onLoginSubmit = e => {
        e.preventDefault();
        if (this.handleLoginValidation()) {
            // Login Success Now API Calling begin
            // Register Success Now API Calling begin
            const {email, password} = this.state;
            const registerRequest = {email, password};
            this.props.actionLogin(registerRequest, this.props.history);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        (prevProps.auth.loginErrors !== this.props.auth.loginErrors && this.props.auth.loginErrors) && this.setState({errors: this.props.auth.loginErrors});
    }



    render() {
        const {errors} = this.state;
        const {loginLoading} = this.props.auth;
        return (
            <div className="col-xl-4 col-lg-5 col-md-6 mb-5 mb-md-0">
                <form className="contact-form-wrap contact-form-bg" autoComplete="off" noValidate={true}
                      onSubmit={this.onLoginSubmit}>
                    <h4>Login</h4>
                    <div className="rld-single-input">
                        <input
                            type="text"
                            name="email"
                            id="email-mobb"
                            value={this.state.email.trim()}
                            onChange={this.onChange}
                            placeholder="Enter Your Email or UserName"/>
                        {errors.email && <span className='text-danger'>{errors.email}</span>}
                    </div>
                    <div className="rld-single-input">
                        <input
                            type="password"
                            name="password"
                            id="password-mobb"
                            value={this.state.password}
                            onChange={this.onChange}
                            className={classnames({
                                'is-invalid': errors.password
                            })}
                            placeholder="Enter Password"/>
                        {errors.password && <span className='text-danger'>{errors.password}</span>}
                    </div>
                    <div className="d-flex justify-content-between flex-wrap  ">
                    <label><input type="checkbox" className="mr-1" id="remcheck"/>Remember Me</label>
                        <Link to={'/forgot-password'}><i className='fa fa-lock'/>Forgot Password ? </Link>
                    </div>


                    <div className="btn-wrap">
                        {loginLoading ?
                            <button
                                className="btn btn-yellow"
                                type="button">
                                <img src={btnLoader} alt="shapemore-login-loader"
                                     width={15}/>
                            </button> :
                            <button
                                className="btn btn-yellow"
                                type="submit">Sign In
                            </button>}

                    </div>
                    <div>

                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps, {actionLogin})(Login);