import React, { Component, Fragment } from "react";

import AgentProfileDropdownMenu from "../global-components/Agent-Profile/agent-profile-dropdown-menu";
import { AGENT_FRIEND_LIST, AGENT_PROPERTY_LIST } from "../../actions/types";
import connect from "react-redux/es/connect/connect";
import {
  actionAddSinglePageWeb,
  actionDeleteAgentProperty,
  actionGetPropertyByFilter,
  actionGetUnits,
} from "../../actions/propertyAction";
import Spinner from "./Spinner";
import DataNotFound from "./DataNotFound";
import FeatureProperyListCard from "../property-listing/feature-propery-list-card";
import InfiniteScroll from "react-infinite-scroll-component";
import PropertyListLoaderCard from "../property-listing/property-list-loader-card";
import btnLoader from "../../assets/img/button-loader.svg";
import BroadcastUserListLoaderCard from "../common/broadcast-user-list-loader-card";
import BroadcastModelChatUser from "../common/broadcast-model-chat-user";
import broadcastIcon from "../../assets/img/broadcast_icon.png";
import {
  actionGetChatList,
  actionLiveSharePropertyUrl,
  actionSharePropertyUrl,
} from "../../actions/chatAction";
import classnames from "classnames";
import defaultAvatar from "../../assets/img/nophoto.png";
import layout1 from "../../assets/img/them-layout/layout1.png";
import layout2 from "../../assets/img/them-layout/layout2.png";
import layout3 from "../../assets/img/them-layout/layout3.png";
import ReactCrop from "react-image-crop";
import jwt_decode from "jwt-decode";

class AgentProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      chatPerson: "",
      chatFilter: "",
      broadcastSearchFilter: "",
      broadcast_members: [],
      broadcast_members_index: [],
      propertyId: "",
      sub_domain: "",
      theme: "layout1",
      property_id: "",
      is_single: 0,
      themLoader: false,
      layout: 1,
      errors: {},
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let scrollHeight = document.documentElement.scrollHeight;
      let scrollTop = document.documentElement.scrollTop;
      let clientHeight = document.documentElement.clientHeight;

      if (
        scrollTop + clientHeight > scrollHeight - 300 &&
        this.props.propertyData.length !== parseInt(this.props.propertyCount)
      ) {
        this.setState(
          {
            offset: this.state.offset + 1,
          },
          () =>
            this.props.user &&
            this.props.actionGetPropertyByFilter(
              `?agentId=${
                this.props.showTabs
                  ? this.props.match.params.userId
                  : this.props.user.user_id
              }`,
              `&offset=${this.state.offset}`
            )
          // this.props.getPropertyLoader(true)
        );
      }
    });
    this.setState({ offset: 0 });
    this.props.user &&
      this.props.actionGetPropertyByFilter(
        `?agentId=${
          this.props.showTabs
            ? this.props.match.params.userId
            : this.props.user.user_id
        }`,
        `&offset=${this.state.offset}`
      );

    setTimeout(() => {
      this.props.actionGetChatList(this.props.user.user_id);
    }, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    prevProps.user !== this.props.user &&
      this.props.actionGetPropertyByFilter(
        `?agentId=${
          this.props.showTabs
            ? this.props.match.params.userId
            : this.props.user.user_id
        }`,
        `&offset=${parseInt(this.state.offset)}`
      );
    if (prevProps.user.user_id !== this.props.user.user_id) {
      this.props.actionGetChatList(this.props.user.user_id);
    }
    prevProps.chatAgent !== this.props.chatAgent &&
      this.setState({ chatPerson: this.props.chatAgent });
  }

  fetchMoreData = () => {
    this.setState(
      { offset: this.state.offset + 1 },
      () =>
        this.props.user &&
        this.props.actionGetPropertyByFilter(
          `?agentId=${
            this.props.showTabs
              ? this.props.match.params.userId
              : this.props.user.user_id
          }`,
          `&offset=${this.state.offset}`
        )
    );
  };

  // handleDeleteProperty =(propertyId,agentId)=>{
  //     console.log(propertyId);
  //     console.log(agentId);
  //     this.props.actionDeleteAgentProperty(propertyId,agentId)
  // };

  onChangeBroadcastFilter = (e) =>
    this.setState({ [e.target.name]: e.target.value });

  handleSelectBroadcastPerson = (personIndex) => {
    console.log(personIndex);
    console.log(this.state.broadcast_members_index);
    this.setState({
      broadcast_members_index: this.state.broadcast_members_index.find(
        (m) => parseInt(m) === parseInt(personIndex)
      )
        ? [
            ...this.state.broadcast_members_index.filter(
              (m) => parseInt(personIndex) !== parseInt(m)
            ),
          ]
        : [...this.state.broadcast_members_index, personIndex],
    });
    console.log(personIndex);
    console.log(this.state.broadcast_members_index);
  };

  handleSelectPropertyCard = (propertyId) => {
    console.log(propertyId);
    if (this.props.isAuthenticated) {
      this.setState({ propertyId });
      console.log(propertyId);
    } else {
      document.getElementById("closeBtn").click();
      window.location.pathname = "/register";
    }
  };

  // onChange=e=>this.setState({[e.target.name]:e.target.value});
  onChange = (e) => {
    this.setState({ themLoader: true });
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ themLoader: false });
  };
  getPropertyId = (id, isSingle, subDomain, theme) =>
    this.setState(
      {
        property_id: id,
        sub_domain: subDomain,
        theme: theme ? theme : "layout1",
        is_single: isSingle,
      },
      () => document.getElementById("onSingleBtn").click()
    );
  onShareProperty = (e) => {
    e.preventDefault();
    // this.setState({broadcast_members:this.state.broadcast_members_index.map(val=>val-1)});

    // this.props.actionSharePropertyUrl(`https://globalindiarealestate.com/property-detail/${this.state.propertyId}`);
    const selectedUsers =
      this.state.broadcast_members_index &&
      this.state.broadcast_members_index.map((userIndex) =>
        this.props.chatPersonList.find(
          (person, i) => parseInt(userIndex - 1) === i
        )
      );
    // this.setState({broadcast_members: selectedUsers.map(val => val.user_id)});
    //
    // if (this.state.broadcast_members) {
    //     this.ws.send(JSON.stringify({
    //         // action: this.state.chatPerson.chat_type === 'Agent' ? 'sendMessage' : 'sendBroadcastMessage',
    //         action: this.state.chatPerson.chat_type === 'Agent' ? 'sharedMessage' : 'shareBroadcastMessage',
    //         payload: {
    //             sender_id: this.props.user.user_id,
    //             receiver_id: this.state.broadcast_members,
    //             message: 'Share test property',
    //             property_id: this.state.propertyId,
    //             attachment_name: "",
    //             file_ext: "",
    //             mime_type: "",
    //             ip_address: ""
    //         },
    //         token: ''
    //     }));
    //
    // }

    this.setState({
      broadcast_members: selectedUsers.map((val) => [val.user_id]),
    });
    this.props.actionSharePropertyUrl(
      `https://globalindiarealestate.com/property-detail/${this.state.propertyId}`
    );

    setTimeout(() => {
      if (localStorage.jwtToken) {
        const decoded = jwt_decode(localStorage.jwtToken);
        const request = {
          sender_id: parseInt(decoded.uid),
          payload: selectedUsers.map((user) => ({
            user_id: user.user_id,
            type: user.chat_type,
            message: this.props.sharePropertyUrl,
          })),
        };
        this.props.actionLiveSharePropertyUrl(request);
      }
    }, 600);

    this.setState({ broadcast_members_index: [], broadcast_members: "" });
  };

  handleValidationFrom = () => {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (!fields.sub_domain) {
      formIsValid = false;
      errors.sub_domain = "Sub Domain Name Required!";
    }
    this.setState({ errors: errors }, () => {
      const ID = Object.keys(errors)[0];
      document.getElementById(ID) &&
        document.getElementById(ID).scrollIntoView();
    });
    return formIsValid;
  };
  handleSubmitSinglePageWeb = () => {
    if (this.handleValidationFrom()) {
      const { property_id, theme, sub_domain } = this.state;
      const propertyData = {
        property_id,
        is_single: 1,
        theme,
        sub_domain,
      };
      this.props.actionAddSinglePageWeb(propertyData);
      this.setState({
        property_id: "",
        is_single: "",
        theme: "layout1",
        sub_domain: "",
      });
      document.getElementById("singlePageWebClose").click();
    }
  };

  render() {
    //style

    const serachlStle = {
      position: "absolute",
      right: "35px",
      top: "14px",
    };
    const serachiStle = {
      width: "100%",
      // height:'37px',
      lineHeight: "36px",
      padding: "1px 20px",
      fontSize: "15px",
      border: "1px solid #d4d4d4",
    };

    const { chatPerson } = this.state;
    const { errors } = this.state;
    let publicUrl = process.env.PUBLIC_URL + "/";
    // const {propertyData, getPropertyLoader,propertyCount} = this.props;
    const {
      categories,
      subCategories,
      units,
      propertyData,
      getPropertyLoader,
      chatPersonList,
      createBroadcastLoader,
    } = this.props;

    let filteredBroadcastPersonList = !this.state.broadcastSearchFilter
      ? chatPersonList
      : chatPersonList.filter((a) => {
          return (
            (a.first_name &&
              a.first_name
                .toLowerCase()
                .indexOf(this.state.broadcastSearchFilter.toLowerCase()) >
                -1) ||
            (a.last_name &&
              a.last_name
                .toLowerCase()
                .indexOf(this.state.broadcastSearchFilter.toLowerCase()) >
                -1) ||
            (a.email &&
              a.email
                .toLowerCase()
                .indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1)
          );
        });
    // console.log(this.state.them);
    return (
      <Fragment>
        {this.props.showTabs && (
          <AgentProfileDropdownMenu
            scenario={AGENT_PROPERTY_LIST}
            match={this.props.match}
          />
        )}
        <div className="agent-property-area">
          <div className="property-area pd-top-50 mt-2">
            <div className="container">
              <div className="row mt-5">
                {propertyData &&
                  propertyData.length > 0 &&
                  propertyData.map((item, i) => (
                    <FeatureProperyListCard
                      actionDeleteAgentProperty={
                        this.props.actionDeleteAgentProperty
                      }
                      sub_category_name={item.sub_category_name}
                      auth={this.props.isAuthenticated}
                      agentId={item.agent_id}
                      userId={this.props.user.user_id}
                      land_area={item.land_area ? item.land_area : ""}
                      imagealt={item.title}
                      image={item.image}
                      propertyId={item.property_id}
                      propertyLink={item.property_link}
                      logo={item.agent_profile_pic}
                      agentName={`${item.agent_first_name} ${item.agent_last_name}`}
                      title={item.title}
                      address={item.address}
                      url={`/property-detail/${item.property_id}`}
                      newerprice={item.price}
                      beds={item.bedroom}
                      area={
                        item.super_area ? item.super_area : item.carpet_area
                      }
                      area_unit_name={
                        item.area_unit_name
                          ? item.area_unit_name
                          : item.area_unit_name
                      }
                      key={i}
                      index={i}
                      currency={item.currency}
                      img={publicUrl + item.features}
                      userAuth={item.agent_id === this.props.user.user_id}
                      isSingle={item.is_single}
                      subDomain={item.sub_domain}
                      theme={item.theme}
                      handleSelectPropertyCard={this.handleSelectPropertyCard}
                      getPropertyId={this.getPropertyId}
                    />
                  ))}

                {/*{getPropertyLoader &&<div className="mx-auto mt-5"><Spinner width={150} /></div> }*/}

                {getPropertyLoader && (
                  <div className="row">
                    <PropertyListLoaderCard />
                    <PropertyListLoaderCard />
                    <PropertyListLoaderCard />
                    <PropertyListLoaderCard />
                    <PropertyListLoaderCard />
                    <PropertyListLoaderCard />
                  </div>
                )}
              </div>

              {!getPropertyLoader && propertyData.length < 1 && (
                <div className="text-center col-md-12">
                  <DataNotFound message={`Sorry! No Property Found.`} />
                </div>
              )}
            </div>

            <Fragment>
              {/* Create Broadcast Modal */}

              <div
                className="modal fade share-property-modal"
                id="createBroadcastModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="createBroadcastModalLabel"
                aria-hidden="true"
                style={{ marginTop: "2em" }}
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div
                    className="modal-content"
                    style={{ zIndex: "99999999999" }}
                  >
                    <div className="modal-header ">
                      <h5
                        className="modal-title w-100 text-center"
                        id="createBroadcastModalLabel "
                      >
                        Share this property..
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true h3">×</span>
                      </button>
                    </div>
                    <div className="modal-body pt-0">
                      <Fragment>
                        <div id="sidepanel" className="p-2">
                          <div id="search" className="mb-2">
                            <label htmlFor="" style={serachlStle}>
                              <i className="fa fa-search" aria-hidden="true" />
                            </label>
                            <input
                              type="text"
                              style={serachiStle}
                              name="broadcastSearchFilter"
                              onChange={this.onChangeBroadcastFilter}
                              value={this.state.broadcastSearchFilter}
                              placeholder="Search Friend"
                            />
                          </div>

                          {this.state.broadcast_members_index.length > 0 && (
                            <div className="d-flex align-items-center justify-content-between pl-2 pr-2">
                              {this.state.broadcast_members_index.length >
                                0 && (
                                <div>
                                  {this.state.broadcast_members_index.length}{" "}
                                  Person Selected.
                                </div>
                              )}
                              {
                                createBroadcastLoader ? (
                                  <div className="broadcast-next-btn">
                                    <img
                                      src={btnLoader}
                                      alt="contact-loader"
                                      width={15}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="btn btn-yellow h4"
                                    id="clg"
                                    data-dismiss="modal"
                                    onClick={this.onShareProperty}
                                  >
                                    <i className="fa fa-share-alt mr-2" />
                                    Share
                                  </div>
                                )
                                //
                                // <button className="btn btn-yellow" onClick={this.handlePropertyImgChange}>Save
                                // changes
                                // </button>
                              }
                            </div>
                          )}

                          <hr />
                          <div
                            id="contacts"
                            className="broadcast-model-contact"
                          >
                            <ul>
                              {!filteredBroadcastPersonList.length && (
                                <div className="">
                                  <BroadcastUserListLoaderCard />
                                  <BroadcastUserListLoaderCard />
                                  <BroadcastUserListLoaderCard />
                                  <BroadcastUserListLoaderCard />
                                  <BroadcastUserListLoaderCard />
                                </div>
                              )}
                              {filteredBroadcastPersonList &&
                                filteredBroadcastPersonList.length > 0 &&
                                filteredBroadcastPersonList.map(
                                  (person, index) => (
                                    <BroadcastModelChatUser
                                      key={index}
                                      handleSelectChatPerson={
                                        this.handleSelectBroadcastPerson
                                      }
                                      name={`${
                                        person.first_name
                                          ? person.first_name
                                          : ""
                                      } ${
                                        person.last_name ? person.last_name : ""
                                      }`}
                                      profile_pic={
                                        person.chat_type === "Agent"
                                          ? person.profile_pic
                                          : broadcastIcon
                                      }
                                      lastMessage={person.last_message}
                                      chatPerson={chatPerson}
                                      isActive={this.state.broadcast_members_index.find(
                                        (m) =>
                                          parseInt(index + 1) === parseInt(m)
                                      )}
                                      user_id={parseInt(index + 1)}
                                    />
                                  )
                                )}
                            </ul>
                          </div>
                        </div>
                      </Fragment>
                    </div>
                    <div className="modal-footer"></div>
                  </div>
                </div>
              </div>
            </Fragment>
            <Fragment>
              {/*Single Page Web..Modal*/}
              {/*<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">*/}
              {/*Launch demo modal*/}
              {/*</button>*/}

              <div
                className="modal fade"
                id="singlePageWebModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ zIndex: "99999999" }}
              >
                <div className="modal-dialog" role="document">
                  <div
                    className="modal-content"
                    style={{ top: "55px", zIndex: "9999999" }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Create Your Single Page Website
                      </h5>
                      <button
                        id="singlePageWebClose"
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <div className="row align-items-center text-center">
                        <div className="col-8 text-dark ">
                          Publish Single Page Web
                        </div>
                        <div className="col-4 ">
                          <label className="switch">
                            <input
                              id="onSingleBtn"
                              type="checkbox"
                              data-toggle="collapse"
                              data-target="#collapseExample"
                              aria-controls="collapseExample"
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                      </div>

                      <div className="collapse" id="collapseExample">
                        <div className="card card-body">
                          <div className="row">
                            <div className="col-lg-12 mb-3">
                              <div className="rld-single-input">
                                <input
                                  type="text"
                                  name="sub_domain"
                                  id="sub_domain"
                                  value={this.state.sub_domain}
                                  onChange={this.onChange}
                                  className={classnames({
                                    "is-invalid": errors.sub_domain,
                                  })}
                                  placeholder="Sub Domain Name"
                                />
                                {errors.sub_domain && (
                                  <span className="text-danger">
                                    {errors.sub_domain}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="rld-single-select">
                                <select
                                  id="theme"
                                  name="theme"
                                  className="select single-select"
                                  onChange={this.onChange}
                                  value={this.state.theme}
                                >
                                  <option value={"layout1"}>Layout 1</option>
                                  <option value={"layout2"}>Layout 2</option>
                                  <option value={"layout3"}>Layout 3</option>

                                  {/*{units && units.map(unit => <option*/}
                                  {/*value={unit.units_of_measures_id}*/}
                                  {/*key={unit.units_of_measures_id}*/}
                                  {/*>{unit.name}</option>)}*/}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-12 mt-2">
                              <div className="card ">
                                {this.state.themLoader && (
                                  <Spinner width={150} />
                                )}
                                {this.state.theme === "layout1" && (
                                  <div
                                    className="layout-section"
                                    title={"View Image.."}
                                    data-toggle="modal"
                                    data-target="#imgPreviewModal"
                                    onClick={() => {
                                      window.open(
                                        "http://layout1.globalpropertysites.com/",
                                        "_blank",
                                        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1200,height=900"
                                      );
                                    }}
                                  >
                                    <img
                                      className="card-img-top"
                                      height="400px"
                                      src={layout1}
                                      alt="Card image cap"
                                    />
                                    <i className="fa fa-search img-view" />
                                  </div>
                                )}

                                {this.state.theme === "layout2" && (
                                  <div
                                    className="layout-section"
                                    title={"View Image.."}
                                    data-toggle="modal"
                                    data-target="#imgPreviewModal"
                                    onClick={() => {
                                      window.open(
                                        "http://layout2.globalpropertysites.com/",
                                        "_blank",
                                        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1200,height=900"
                                      );
                                    }}
                                  >
                                    <img
                                      className="card-img-top"
                                      height="400px"
                                      src={layout2}
                                      alt="Card image cap"
                                    />
                                    <i className="fa fa-search img-view" />
                                  </div>
                                )}

                                {this.state.theme === "layout3" && (
                                  <div
                                    className="layout-section"
                                    title={"View Image.."}
                                    onClick={() => {
                                      window.open(
                                        "http://layout3.globalpropertysites.com/",
                                        "_blank",
                                        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1200,height=900"
                                      );
                                    }}
                                  >
                                    <img
                                      className="card-img-top"
                                      height="400px"
                                      src={layout3}
                                      alt="Card image cap"
                                    />
                                    <i className="fa fa-search img-view" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-yellow"
                        onClick={this.handleSubmitSinglePageWeb}
                      >
                        Create Sub Domain Website
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="modal " id="imgPreviewModal" data-backdrop="static"*/}
              {/*style={{zIndex: '99999999', marginTop: '100px', width:'100%'}}>*/}
              {/*<div className="modal-dialog w-100">*/}
              {/*<div className="modal-content">*/}
              {/*<div className="modal-header">*/}
              {/*<h4 className="modal-title">Them Layout</h4>*/}
              {/*<button type="button" className="close" data-dismiss="modal"*/}
              {/*aria-hidden="true">×*/}
              {/*</button>*/}
              {/*</div>*/}
              {/*<div className="container"></div>*/}
              {/*<div className="modal-body text-center">*/}
              {/*<div className="row w-100">*/}
              {/*{(this.state.theme === "layout1") &&*/}
              {/*<img className=""*/}
              {/*src={layout_full1}*/}
              {/*alt="Card image cap"/>}*/}

              {/*{(this.state.theme === "layout2") &&*/}
              {/*<img className=""*/}
              {/*src={layout2}*/}
              {/*alt="Card image cap"/>}*/}

              {/*{(this.state.theme === "layout3") &&*/}
              {/*<img className=""*/}
              {/*src={layout3}*/}
              {/*alt="Card image cap"/>}*/}
              {/*</div>*/}

              {/*</div>*/}

              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
            </Fragment>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    agent: state.agent,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    chatPersonList: state.chat.chatPersonList,
    createBroadcastLoader: state.chat.createBroadcastLoader,
    getPropertyLoader: state.property.getPropertyLoader,
    units: state.property.units,
    propertyData: state.property.propertyData,
    propertyCount: state.property.propertyCount,
    sharePropertyUrl: state.chat.sharePropertyUrl,
  };
};
AgentProperty.defaultProps = {
  showTabs: true,
};
export default connect(mapStateToProps, {
  actionGetUnits,
  actionGetPropertyByFilter,
  actionDeleteAgentProperty,
  actionGetChatList,
  actionAddSinglePageWeb,
  actionSharePropertyUrl,
  actionLiveSharePropertyUrl,
})(AgentProperty);
