import React from 'react';
import ContentLoader from 'react-content-loader'

const AgentListLoaderCard = () => {
    return (

        <div className="col-lg-4 col-md-6">
            <div className="single-user-list text-center bg-white">
                <ContentLoader
                    speed={2}
                    width={400}
                    height={250}
                    viewBox="0 0 420 270"
                    backgroundColor="#d4d4d4"
                    foregroundColor="#ecebeb"
                    style={{width: '100%', textAlign: 'center'}}

                >
                    <circle cx="200" cy="59" r="49"/>
                    <circle cx="190" cy="66" r="8"/>
                    <rect x="120" y="120" rx="0" ry="0" width="156" height="8"/>
                    <rect x="150" y="140" rx="0" ry="0" width="100" height="8"/>
                    <rect x="120" y="160" rx="0" ry="0" width="156" height="8"/>
                    {/*<rect x="150" y="160" rx="0" ry="0" width="100" height="8"/>*/}
                    <rect x="150" y="180" rx="0" ry="0" width="100" height="8"/>
                    <rect x="248" y="128" rx="0" ry="0" width="0" height="1"/>
                    <rect x="247" y="126" rx="0" ry="0" width="1" height="8"/>
                    <rect x="252" y="166" rx="0" ry="0" width="1" height="0"/>

                </ContentLoader>


            </div>
        </div>


    );
};

export default AgentListLoaderCard;