export const LOGOUT = "LOGOUT";
export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const OTP_LOADING = "OTP_LOADING";
export const SET_OTP_SENT_TO = "SET_OTP_SENT_TO";

export const AUTH_SUCCESS = "AUTH_SUCCESS";

export const GET_AGENT = "GET_AGENT";
export const GET_AGENTS = "GET_AGENTS";
export const REMOVE_AGENTS = "REMOVE_AGENTS";

export const GET_AGENT_LOADING = "GET_AGENT_LOADING";
export const GET_AGENTS_LOADING = "GET_AGENTS_LOADING";

export const SEND_FRIEND_REQUEST_LOADING = "SEND_FRIEND_REQUEST_LOADING";
export const ACCEPT_FRIEND_REQUEST_LOADING = "ACCEPT_FRIEND_REQUEST_LOADING";
export const REJECT_FRIEND_REQUEST_LOADING = "REJECT_FRIEND_REQUEST_LOADING";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REMOVE_ALL_ALERT = "REMOVE_ALL_ALERT";

export const MAKE_REQUESTED = "MAKE_REQUESTED";

// scenario for Main Navbar
export const HOME = "HOME";
export const FAQ = "FAQ";
export const AGENTS = "AGENTS";
export const PROPERTIES = "PROPERTIES";
export const ABOUT_US = "ABOUT_US";
// export const CATEGORY = "CATEGORY";
export const CONTACT_US = "CONTACT_US";
export const REGISTER = "REGISTER";
export const USER_PROFILE = "USER_PROFILE";
export const FRIENDS = "FRIENDS";
export const ADD_PROPERTY = "ADD_PROPERTY";
export const MY_PROPERTY = "MY_PROPERTY";
export const FAVOURITE = "FAVOURITE";
export const TESTIMONIAL = "TESTIMONIAL";
export const CHAT = "CHAT";
export const CITIES = "CITIES";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// scenario for agent profile Navbar
export const AGENT_PROFILE = "AGENT_PROFILE";
export const AGENT_FRIEND_LIST = "AGENT_FRIEND_LIST";
export const AGENT_PROPERTY_LIST = "AGENT_PROPERTY_LIST";

export const DELETE_AGENT_PROPERTY_LOADING = "DELETE_AGENT_PROPERTY_LOADING";
export const DELETE_AGENT_PROPERTY_SUCCESS = "DELETE_AGENT_PROPERTY_SUCCESS";
export const DELETE_AGENT_PROPERTY_FAILED = "DELETE_AGENT_PROPERTY_FAILED";

export const AGENT_PRIVATE_CHAT = "AGENT_PRIVATE_CHAT";
export const GET_RESIDENTIAL_DATA_LOADING = "GET_RESIDENTIAL_DATA_LOADING";
export const GET_RESIDENTIAL_DATA = "GET_RESIDENTIAL_DATA";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const NOTIFICATION_READ_LOADER = "NOTIFICATION_READ_LOADER";
export const NOTIFICATION_READ = "NOTIFICATION_READ";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRY_LOADER = "GET_COUNTRY_LOADER";
export const GET_STATES = "GET_STATES";
export const GET_STATE_LOADER = "GET_STATE_LOADER";
export const GET_CITIES = "GET_CITIES";
export const GET_CITY_LOADER = "GET_CITY_LOADER";
export const CHANGE_PROFILE_PICTURE = "CHANGE_PROFILE_PICTURE";
export const CHANGE_COMPANY_PROFILE_PICTURE = "CHANGE_COMPANY_PROFILE_PICTURE";

export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const GET_CHAT_LIST_LOADER = "GET_CHAT_LIST_LOADER";
export const GET_CHAT_LIST = "GET_CHAT_LIST";

export const GET_MESSAGE_HISTORY_LOADER = "GET_MESSAGE_HISTORY_LOADER";
export const GET_MESSAGE_HISTORY = "GET_MESSAGE_HISTORY";

export const APPEND_MESSAGE = "APPEND_MESSAGE";

export const UN_FRIEND_LOADING = "UN_FRIEND_LOADING";
export const UN_FRIEND = "UN_FRIEND";
export const RESET_USER_RELATION = "RESET_USER_RELATION";
export const GET_USER_DATA_LOADER = "GET_USER_DATA_LOADER";
export const ACCEPT_FRIEND_REQUEST = "ACCEPT_FRIEND_REQUEST";
export const GET_PROPERTY = "GET_PROPERTY";
export const REMOVE_PROPERTY = "REMOVE_PROPERTY";
export const GET_PROPERTY_LOADER = "GET_PROPERTY_LOADER";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_LOADER = "GET_CATEGORIES_LOADER";
export const GET_SUB_CATEGORIES_LOADER = "GET_SUB_CATEGORIES_LOADER";
export const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";
export const GET_UNIT_LOADER = "GET_UNIT_LOADER";
export const GET_UNIT = "GET_UNIT";
export const GET_AMENITIES_LOADER = "GET_AMENITIES_LOADER";
export const GET_AMENITIES = "GET_AMENITIES";

export const GET_USER_RELATION_LOADER = "GET_USER_RELATION _LOADER";
export const GET_USER_RELATION = "GET_USER_RELATION";

export const GET_PARTNERS_LOADER = "GET_PARTNERS_LOADER";
export const GET_PARTNERS = "GET_PARTNERS";

export const ADD_PROPERTY_LOADING = "ADD_PROPERTY_LOADING";
export const ADD_PROPERTY_SUCCESS = "ADD_PROPERTY_SUCCESS";
export const ADD_PROPERTY_FAILED = "ADD_PROPERTY_FAILED";

export const GET_COUNTRY = "GET_COUNTRY";

export const GET_STATE = "GET_STATE";

export const GET_CITY = "GET_CITY";

export const GET_PROPERTY_BY_FILTER_LOADING = "GET_PROPERTY_BY_FILTER_LOADING";
export const GET_PROPERTY_BY_FILTER = "GET_PROPERTY_BY_FILTER";
export const GET_SUGGESTED_LOCATION_LOADER = "GET_SUGGESTED_LOCATION_LOADER";
export const GET_SUGGESTED_LOCATIONS = "GET_SUGGESTED_LOCATIONS";

export const GET_PROPERTY_DETAILS = "GET_PROPERTY_DETAILS";
export const GET_PROPERTY_DETAILS_LOADER = "GET_PROPERTY_DETAILS_LOADER";

export const GET_PREMIUM_FEATURED_PROPERTY = "GET_PREMIUM_FEATURED_PROPERTY";
export const GET_PREMIUM_FEATURED_PROPERTY_LOADER =
  "GET_PREMIUM_FEATURED_PROPERTY_LOADER";

export const GET_FEATURED_PROPERTY = "GET_FEATURED_PROPERTY";
export const GET_FEATURED_PROPERTY_LOADER = "GET_FEATURED_PROPERTY_LOADER";

//Contact From
export const CONTACT_FORM_LOADING = "CONTACT_FORM_LOADING";
export const CONTACT_FORM_SUCCESS = "CONTACT_FORM_SUCCESS";
export const CONTACT_FORM_FAILED = "CONTACT_FORM_FAILED";

//Forgot Password
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

//Change Password
export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

//Get Multipule Images of Property
export const GET_PROPERTY_IMAGES = "GET_PROPERTY_IMAGES";
export const GET_PROPERTY_IMAGES_LOADER = "GET_PROPERTY_IMAGES_LOADER";

//Delete Image of Property
export const DELETE_PROPERTY_IMAGE = "DELETE_PROPERTY_IMAGE";
export const DELETE_PROPERTY_IMAGE_LOADER = "DELETE_PROPERTY_IMAGE_LOADER";

//Add Multiple Image of Property
export const ADD_MULTIPLE_PROPERTY_IMAGE_FAILED =
  "ADD_MULTIPLE_PROPERTY_IMAGE_FAILED";
export const ADD_MULTIPLE_PROPERTY_IMAGE_SUCCESS =
  "ADD_MULTIPLE_PROPERTY_IMAGE_SUCCESS";
export const ADD_MULTIPLE_PROPERTY_IMAGE_LOADER =
  "ADD_MULTIPLE_PROPERTY_IMAGE_LOADER";

//Get Team members
export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS";
export const GET_TEAM_MEMBERS_LOADER = "GET_TEAM_MEMBERS_LOADER";

//Subscribe Form
export const SUBSCRIBE_FORM_LOADING = "SUBSCRIBE_FORM_LOADING";
export const SUBSCRIBE_FORM_SUCCESS = "SUBSCRIBE_FORM_SUCCESS";
export const SUBSCRIBE_FORM_FAILED = "SUBSCRIBE_FORM_FAILED";

//Create Broadcast
export const CREATE_BROADCAST_LOADING = "CREATE_BROADCAST_LOADING";
export const CREATE_BROADCAST_SUCCESS = "CREATE_BROADCAST_SUCCESS";
export const CREATE_BROADCAST_FAILED = "CREATE_BROADCAST_FAILED";

//Update Broadcast
export const UPDATE_BROADCAST_LOADING = "UPDATE_BROADCAST_LOADING";
export const UPDATE_BROADCAST_SUCCESS = "UPDATE_BROADCAST_SUCCESS";
export const UPDATE_BROADCAST_FAILED = "UPDATE_BROADCAST_FAILED";

//Delete Broadcast
export const DELETE_BROADCAST_LOADING = "DELETE_BROADCAST_LOADING";
export const DELETE_BROADCAST_SUCCESS = "DELETE_BROADCAST_SUCCESS";
export const DELETE_BROADCAST_FAILED = "DELETE_BROADCAST_FAILED";

export const GET_CHAT_AGENT_LOADER = "GET_CHAT_AGENT_LOADER";
export const GET_CHAT_AGENT = "GET_CHAT_AGENT";

////Favourite Property
export const ADD_FAVOURITE_PROPERTY = "ADD_FAVOURITE_PROPERTY";
export const ADD_FAVOURITE_PROPERTY_LOADING = "ADD_FAVOURITE_PROPERTY_LOADING";

////Favourite Property
export const REMOVE_FAVOURITE_PROPERTY = "REMOVE_FAVOURITE_PROPERTY";
export const REMOVE_FAVOURITE_PROPERTY_LOADING =
  "REMOVE_FAVOURITE_PROPERTY_LOADING";
//// GET Favourite Property
export const GET_FAVOURITE_PROPERTY = "GET_FAVOURITE_PROPERTY";
export const GET_FAVOURITE_PROPERTY_LOADING = "GET_FAVOURITE_PROPERTY_LOADING";

//// GET Testimonials
export const GET_TESTIMONIALS = "GET_TESTIMONIALS";
export const GET_TESTIMONIALS_LOADING = "GET_TESTIMONIALS_LOADING";

//// DELETE Testimonials
export const DELETE_TESTIMONIALS_SUCCESS = "DELETE_TESTIMONIALS_SUCCESS";
export const DELETE_TESTIMONIALS_LOADING = "DELETE_TESTIMONIALS_LOADING";

//// UPDATE Testimonials
export const UPDATE_TESTIMONIALS = "UPDATE_TESTIMONIALS";
export const UPDATE_TESTIMONIALS_LOADING = "UPDATE_TESTIMONIALS_LOADING";
export const UPDATE_TESTIMONIALS_FAILED = "UPDATE_TESTIMONIALS_FAILED";

//// ADD Testimonials
export const ADD_TESTIMONIAL = "ADD_TESTIMONIAL";
export const ADD_TESTIMONIAL_LOADING = "ADD_TESTIMONIAL_LOADING";
export const ADD_TESTIMONIAL_FAILED = "ADD_TESTIMONIAL_FAILED";

//// ADD Sub Domain Website
export const ADD_SINGLE_PAGE_WEB = "ADD_SINGLE_PAGE_WEB";
export const ADD_SINGLE_PAGE_WEB_LOADING = "ADD_SINGLE_PAGE_WEB_LOADING";
export const ADD_SINGLE_PAGE_WEB_FAILED = "ADD_SINGLE_PAGE_WEB_FAILED";

//// Share Property URL
export const SHARE_PROPERTY_URL_LOADING = "SHARE_PROPERTY_URL_LOADING";
export const SHARE_PROPERTY_URL = "SHARE_PROPERTY_URL";

//// Share Property URL
export const LIVE_SHARE_PROPERTY_URL_LOADING =
  "LIVE_SHARE_PROPERTY_URL_LOADING";
export const LIVE_SHARE_PROPERTY_URL = "LIVE_SHARE_PROPERTY_URL";

//// GETCITIESBYFLITER-----CITIES PAGE
export const GET_CITY_FILTER_LOADER = "GET_CITY_FILTER_LOADER";
export const GET_CITY_FILTER = "GET_CITY_FLITER";