import React from "react";
import { Link } from "react-router-dom";
import icon18 from "../../assets/img/icons/7.png";
import classnames from "classnames";
import defaultAvatared from "../../assets/img/company-logo-placeholder.png";

const PropertyListCard = ({
  index,
  image,
  logo,
  agentName,
  title,
  url,
  newerprice,
  olderprice,
  ftitle,
  imagealt,
  area,
  img,
  featuresTitle,
  featuresIcon,
  address,
  beds,
  wash,
  bath,
  area_unit_name,
  currency,
  flat_type,
  land_area,
  super_area,
  carpet_area,
  sub_category_id,
  sub_category_name,
  agentId,
  auth,
  handleSelectPropertyCard,
  propertyId,
  handleFavouriteSelect,
  isWishlist,
  userAuth,
}) => {
  // const title1= `${title} ${flat_type} ${sub_category_id === 1 && "Flat"} ${carpet_area && `${carpet_area} ${area_unit_name}`} ${super_area && `${super_area} ${area_unit_name}`} ${land_area && `${land_area}${area_unit_name}`}`;
  // const title1= title && title + carpet_area && `${carpet_area} ${area_unit_name}`;
  // console.log(title1);

  // console.log(userAuth);
  // console.log(agentId);

  return (
    <div key={index} className="col-xl-4 col-sm-6">
      <div className="single-feature bg-light">
        {sub_category_name && (
          <div className="overlay-sub-category-name">{sub_category_name}</div>
        )}
        <div className="wishlist" style={{ cursor: "pointer" }}>
          <i
            className={classnames("fa fa-heart wishlist-hart ", {
              active: isWishlist,
            })}
            aria-hidden="true"
            onClick={() => handleFavouriteSelect(propertyId, isWishlist)}
          />
        </div>

        <div className="thumb">
          <img
            height="180"
            className="fix-proerty-image-size"
            src={image ? image : defaultAvatared}
            alt={title}
            title={title}
          />
        </div>

        <div className="details">
          <div className="feature-logo">
            <img src={logo} alt={agentName} title={agentName} />
          </div>
          <h6 className="title readeal-top mt-2">
            <Link to={url}>
              {title} {flat_type} {sub_category_id === 1 && "Flat"}
              {carpet_area && `${carpet_area} ${area_unit_name}`}
              {super_area && `${super_area} ${area_unit_name}`}
              {land_area && `${land_area}${area_unit_name}`}
            </Link>
          </h6>
          <p className="author p-0 m-0">
            <i className="fa fa-user" /> {agentName}
          </p>
          <p className="author p-0 m-0">
            <i className="fa fa-location-arrow" /> {address}
          </p>
          <div className="d-flex align-items-center ">
            <h6 className="price text-warning">Price: </h6>
            <h6 className="price text-warning">
              {currency === "INR" && <i className={"fa fa-inr"} />}
              {currency === "USD" && <i className={"fa fa-dollar"} />}
              {newerprice}
            </h6>
          </div>
          <del>{olderprice}</del>
          <ul className="info-list d-flex justify-content-between align-items-center">
            {beds && (
              <li>
                <i className="fa fa-bed" /> {beds} Bedroom
              </li>
            )}

            <li>
              <img src={icon18} alt="image" /> {area} {land_area}
              {area_unit_name}
            </li>
          </ul>

          <ul className="contact-list d-flex readeal-top align-items-center">
            {agentId !== userAuth && (
              <li>
                <Link
                  className="phone"
                  to={auth ? `/private-chat/${agentId}` : "/register"}
                >
                  <i className="fa fa-commenting-o" />
                </Link>
              </li>
            )}
            <li>
              <a
                className="message"
                href={""}
                id="settings"
                data-toggle="modal"
                data-target="#createBroadcastModal"
                data-backdrop="static"
                data-keyboard="false"
                title={"Share Property"}
                onClick={() => handleSelectPropertyCard(propertyId)}
              >
                <i className="fa fa-share-alt" />
              </a>
            </li>

            <li className="readeal-top w-100 text-right">
              <Link className="btn btn-yellow mt-0" to={url}>
                View Details
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PropertyListCard;
