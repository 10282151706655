import React from 'react';

const PropertyListDetailsCard = (props) => {
    return (
        <div className="col-md-6 col-sm-12 mt-1 mb-1">
            <div className="single-property-info d-flex align-items-center justify-content-between">
                <h6 className="p-0 m-0"><i className={`${props.icon} mr-2`} />{props.title}</h6>
                <p className="p-0 mt-0 mb-0 mr-0 ml-4">{props.data}</p>
            </div>
        </div>
    );
};

export default PropertyListDetailsCard;