import React, { Component } from "react";
import btnLoader from "../../assets/img/button-loader.svg";
import connect from "react-redux/es/connect/connect";
import {
  actionVerifyOtp,
  actionSendVerificationCode,
} from "../../actions/authAction";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";

class MobileVerificationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: null,
      otpSentTo: "",
      counter: 0,
      errors: {},
    };
    document.title = "Mobile Verification";
  }


  

  componentDidMount() {
    if (this.state.counter <= 0) {
      this.setState({ counter: 120 }, () =>
        this.state.counter > 0
          ? setInterval(
              () =>
                this.state.counter > 0
                  ? this.setCounter(this.state.counter - 1)
                  : null,
              1000
            )
          : null
      );
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  setCounter = (counter) => this.setState({ counter });

  handleVerifyOTP = (e) => {
    e.preventDefault();
    const verificationRequest = {
      user_id: this.props.user_id,
      otp: this.state.otp,
    };
    const { history } = this.props;
    this.props.actionVerifyOtp(verificationRequest, history);
    this.setState({ otp: null });
  };

  handleReSendOTP = (e) => {
    e.preventDefault();
    const { user_id, temp_mobile, history } = this.props;
    const resendOtpReq = {
      user_id,
      mobile: temp_mobile,
    };
    this.props.actionSendVerificationCode(resendOtpReq, history);
  };

  render() {
    let { errors } = this.state;
    let { otpLoading } = this.props.auth; // TODO: remove this
    return (
      <div className="col-xl-5 col-lg-5 col-md-6 register-container otp-input-design">
        <form
          className="contact-form-wrap contact-form-bg"
          autoComplete="off"
          noValidate={true}
          onSubmit={this.handleVerifyOTP}
        >
          <h4>OTP Verification</h4>
          <div id="user">
            <div className="rld-single-input">
              <OtpInput
                onChange={(otp) => this.setState({ otp })}
                numInputs={4}
                value={this.state.otp}
                containerStyle="otp-input-design"
                separator={<span className={"separatorItem"}>&nbsp;</span>}
                isInputNum={true}
              />
              {errors.otp && <span className="text-danger">{errors.otp}</span>}
            </div>
          </div>
          <small>
            Don't Receive the OTP? &nbsp;{" "}
            {this.state.counter > 0 ? (
              <i className="m-l-5">
                <b>Please wait {this.state.counter} sec to resend OTP</b>
              </i>
            ) : (
              <a className="m-l-5" href="#" onClick={this.handleReSendOTP}>
                {" "}
                RESEND OTP
              </a>
            )}
          </small>
          <br />
          <small>
            <Link
              to={"/change-mobile-number"}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/change-mobile-number";
              }}
            >
              Change Mobile Number
            </Link>
          </small>
          <div className="btn-wrap">
            {otpLoading ? (
              <button className="btn btn-yellow" type="button">
                <img src={btnLoader} alt="shapemore-login-loader" width={15} />
              </button>
            ) : (
              <button className="btn btn-yellow" type="submit">
                Verify
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    temp_mobile: state.auth.user.temp_mobile,
    user_id: state.auth.user.user_id,
    auth: state.auth,
  };
};

export default connect(mapStatetoProps, {
  actionVerifyOtp,
  actionSendVerificationCode,
})(MobileVerificationCard);
