import {combineReducers} from 'redux';
import auth from './authReducer'
import agent from './agentReducer'
import alert from './alertReducer';
import property from './propertyReducer'
import chat from './chatReducer'

export default combineReducers({
    alert,
    auth: auth,
    agent: agent,
    property: property,
    chat: chat

});