import React, {Fragment} from 'react';
import moment from 'moment';
import connect from "react-redux/es/connect/connect";
import {actionAcceptFriendRequest, actionRejectRequest} from "../../actions/agentAction";

const NotificationItem = ({notificationId, user_id, userProfile, title, time, first_name, last_name, action, actionAcceptFriendRequest, user, actionRejectRequest}) => {
    return (
        <div
            className="notification-list notification-list--unread">
            <div className="notification-list_img">
                <img src={userProfile} alt={`${first_name} ${last_name}`}/>
            </div>
            <div className="notification-list_detail">
                <p>{title}</p>
                <hr/>
                <p className={'notification-footer'}>
                    <small><i className="fa fa-clock-o"/> {moment(time).utc().fromNow(true)}</small>
                    <span className="button-container">
                        {action === 'REQUEST' && <Fragment>
                            <button className="btn btn-yellow"
                                    onClick={() => actionAcceptFriendRequest({
                                        user_id: user.user_id,
                                        related_user_id: user_id,
                                        notification_id: notificationId
                                    })}><span className="fa fa-check"/>
                            </button>

                            <button className="btn btn-danger ml-1"
                                    onClick={() => actionRejectRequest({
                                        user_id: user.user_id,
                                        related_user_id: user_id,
                                        notification_id: notificationId
                                    })}><span className="fa fa-close"/>
                            </button>
                        </Fragment>}
                    </span>
                </p>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user
});
export default connect(mapStateToProps, {actionAcceptFriendRequest, actionRejectRequest})(NotificationItem);