import * as actionTypes from '../actions/types';

const initialState = {
    recipient: {},
    chatPersonList: [],
    getChatListLoader: false,
    messageHistory: [],
    getMessageHistoryLoader: false,
    createBroadcastLoader: false,
    updateBroadcastLoader: false,
    getChatAgentLoader: false,
    deleteBroadcastLoader:false,
    chatAgent: '',
    sharePropertyUrl: '',
    sharePropertyUrlLoader: false,
    errors: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_CHAT_LIST:
            return {
                ...state,
                chatPersonList: action.payload
            };
        case actionTypes.GET_CHAT_LIST_LOADER:
            return {
                ...state,
                getChatListLoader: action.payload
            };
        case actionTypes.GET_MESSAGE_HISTORY_LOADER:
            return {
                ...state,
                getMessageHistoryLoader: action.payload
            };
        case actionTypes.GET_MESSAGE_HISTORY:
            return {
                ...state,
                messageHistory: action.payload
            };
        case actionTypes.APPEND_MESSAGE:
            return {
                ...state,
                messageHistory: [...state.messageHistory, action.payload]
            };
        //Create Broadcast
        case actionTypes.CREATE_BROADCAST_LOADING:
            return {
                ...state,
                createBroadcastLoader: action.payload
            };

        case actionTypes.CREATE_BROADCAST_SUCCESS:
            return {
                ...state,
                chatPersonList: [...state.chatPersonList, {
                    user_id: action.payload.broadcast_id,
                    first_name: action.payload.broadcast_name,
                    last_name: '',
                    email: '',
                    username: '',
                    dob: null,
                    mobile: '',
                    is_mobile_verified: '',
                    profile_pic: action.payload.image,
                    last_message: '',
                    broadcast_members: action.payload.broadcast_members,
                    chat_type: 'Broadcast'
                }]
            };
        case actionTypes.CREATE_BROADCAST_FAILED:
            return {
                ...state,
                errors: action.payload
            };

        //Update Broadcast
        case actionTypes.UPDATE_BROADCAST_LOADING:
            return {
                ...state,
                updateBroadcastLoader: action.payload
            };

        case actionTypes.UPDATE_BROADCAST_SUCCESS:
            return {
                ...state,
                chatPersonList: [...state.chatPersonList.filter(val=>  parseInt(val.user_id) !== parseInt(action.payload.broadcast_id)), {
                    user_id: action.payload.broadcast_id,
                    first_name: action.payload.broadcast_name,
                    last_name: '',
                    email: '',
                    username: '',
                    dob: null,
                    mobile: '',
                    is_mobile_verified: '',
                    profile_pic: action.payload.image,
                    last_message: '',
                    broadcast_members: action.payload.broadcast_members,
                    chat_type: 'Broadcast'
                }]
            };
        case actionTypes.UPDATE_BROADCAST_FAILED:
            return {
                ...state,
                errors: action.payload
            };

        //Delete Broadcast
        case actionTypes.DELETE_BROADCAST_SUCCESS:
            return {
                ...state,
                // propertyData: [...state.propertyData.filter(p => parseInt(p.property_id) !== parseInt(action.payload))]
                chatPersonList: [...state.chatPersonList.filter(val=>  parseInt(val.user_id) !== parseInt(action.payload))]
            };
        case actionTypes.DELETE_BROADCAST_LOADING:
            return {
                ...state,
                // getPropertyImages: [...state.getPropertyImages.filter(img => parseInt(img.property_image_id) !== parseInt(action.payload))]
                deleteBroadcastLoader: action.payload
            };


        //Share Property URl
        case actionTypes.SHARE_PROPERTY_URL:
            return {
                ...state,
                sharePropertyUrl: action.payload
            };

        case actionTypes.SHARE_PROPERTY_URL_LOADING:
            return {
                ...state,
                sharePropertyUrlLoader: action.payload
            };



        case actionTypes.GET_CHAT_AGENT_LOADER:
            return {
                ...state,
                getChatAgentLoader: action.payload
            };
        case actionTypes.GET_CHAT_AGENT:
            return {
                ...state,
                chatAgent: action.payload
            };


        default:
            return state;
    }
}