import React from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import TermsConditions from '../section-components/terms-conditions';
import Footer from '../global-components/footer';

const Terms = () => {
    window.scrollTo(0, 0);
    return <div>
        <Navbar />
        <PageHeader headertitle="Terms of Service" />
       <TermsConditions/>
        <Footer />
    </div>
}

export default Terms;