import React, { Component, useEffect, useState } from "react";
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import connect from "react-redux/es/connect/connect";

import { Link } from "react-router-dom";
import city from "../../assets/img/citi.png";
import test from "../../assets/img/citi.png";
import { actionGetCityByFilter } from "../../actions/propertyAction";
import classNames from "classnames";
import DataNotFound from "../section-components/DataNotFound";
import AgentListLoaderCard from "../agent-list/agent-list-loader-card";

class cities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      q: "",
      loader: "",
    };
  }
  componentDidMount() {
    this.props.actionGetCityByFilter();
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = (e) => {
    e.preventDefault();
    this.state.q
      ? this.props.actionGetCityByFilter(this.state.q)
      : this.props.actionGetCityByFilter();
  };

  render() {
    return (
      <div>
        <Navbar />
        <PageHeader headertitle="Cities" />
        <div className="container my-lg-5 my-3">
          <div className="row">
            <div className="col-12 py-3">
              {/* <div className="widget-sidebar-item-wrap rld-single-input left-icon ">
                            <input type="text" className="form-control rld-single-input left-icon" id="q" name={'q'}
                                value={this.state.q} onChange={this.onChange}
                                placeholder="Search City" />
                        </div> */}{" "}
              <div className="widget-sidebar-item-wrap rld-single-input left-icon">
                <form onSubmit={this.onSubmit}>
                  <div className="row">
                    <div className="col-xl-10 col-lg-8 col-md-6 col-sm-12">
                      <div className="rld-single-input left-icon">
                        <input
                          type="text"
                          name={"q"}
                          placeholder="Search City"
                          id="rdl-mobb"
                          value={this.state.q}
                          onChange={this.onChange}
                          // className={classNames('form-control br-tl-3 br-bl-3 ', {
                          //     'empty-input-error': errors.query
                          // })}
                        />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-4 col-md-6 readeal-top">
                      <button type="submit" className="btn btn-yellow">
                        SEARCH NOW
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {this.props.cityFilter &&
              this.props.cityFilter.length === 0 &&
              !this.props.cityFilterLoader && (
                <div className="text-center col-md-12">
                  <DataNotFound message={`Sorry! No City Found.`} />
                </div>
              )}

            {this.props.cityFilterLoader && (
              <div className="row">
                <AgentListLoaderCard />
                <AgentListLoaderCard />
                <AgentListLoaderCard />
                <AgentListLoaderCard />
                <AgentListLoaderCard />
                <AgentListLoaderCard />
              </div>
            )}
            {this.props.cityFilter &&
              this.props.cityFilter.length > 0 &&
              this.props.cityFilter.map((e, index) => {
                return (
                  <div className="col-lg-3 col-md-4 col-6" key={index}>
                    <div className={"rld-city-item"}>
                      <div className={"single-city "}>
                        <div
                          className="sc-overlay-newPage"
                          style={{
                            objectFit: "contain",
                            height: "300px",
                            borderRadius: 15,
                          }}
                        />
                        <div className="">
                          <img
                            src={e.image ? e.image : { test }}
                            height={300}
                            style={{ borderRadius: 15 }}
                            className="w-100"
                            alt="image"
                          />
                        </div>
                        <div className="details">
                          <h5 className="title">
                            <Link to={`/property-listing?location=`}>
                              {e.name}{" "}
                            </Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cityFilter: state.property.cityFilter,
  cityFilterLoader: state.property.cityFilterLoader,
});
export default connect(mapStateToProps, { actionGetCityByFilter })(cities);
