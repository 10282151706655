import axios from "axios";
import * as actionTypes from "../actions/types";
import { BASE_URL } from "../web-config";
import setAuthToken from "../utils/setAuthToken";
import showToast from "../utils/showToast";
import jwt_decode from "jwt-decode";
import { AUTH_SUCCESS } from "../actions/types";
import { GET_USER_RELETION } from "../actions/types";

// Register
export const actionRegister =
  (userData, history) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.REGISTER_LOADING, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/register`,
        JSON.stringify(userData)
      );
      dispatch({ type: actionTypes.REGISTER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        const { accessToken } = result.data.data;
        // set token to local storage
        localStorage.setItem("jwtToken", accessToken);
        setAuthToken(accessToken);

        if (parseInt(result.data.data.is_mobile_verified) === 0) {
          dispatch({
            type: actionTypes.REGISTER_SUCCESS,
            payload: result.data.data,
          });
          history &&
            setTimeout(() => {
              history.push("/mobile-verification");
            }, 1000);
        } else history && setTimeout(() => history.push(`/`), 1000);
      } else
        dispatch({
          type: actionTypes.REGISTER_FAILED,
          payload: result.data.data,
        });
    } catch (error) {
      showToast(error.message, "error");
    }
  };

export const actionLogin =
  (userData, history) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.LOGIN_LOADING, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/login`,
        JSON.stringify(userData)
      );
      dispatch({ type: actionTypes.LOGIN_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        const { accessToken } = result.data.data;
        // Setting Auth Token to local storage
        //localStorage.setItem('jwtToken', accessToken);
        // Setting auth token to axios header
        setAuthToken(accessToken);
        dispatch({ type: actionTypes.AUTH_SUCCESS, payload: result.data.data });

        if (parseInt(result.data.data.is_mobile_verified) === 0) {
          history &&
            setTimeout(() => {
              // history.push('/change-mobile-number');
              window.location.href = "/change-mobile-number";
            }, 1000);
        } else history && setTimeout(() => history.push(`/`), 1000);
      } else showToast(result.data.message, "error");
    } catch (error) {
      showToast(error.message, "error");
    }
  };

export const actionSendVerificationCode =
  (request, history) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.OTP_LOADING, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/send-verification-code`,
        JSON.stringify(request)
      );
      dispatch({ type: actionTypes.OTP_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.SET_OTP_SENT_TO,
          payload: request.mobile,
        });
        //dispatch(setAlert(result.data.message, 'success'));
        setTimeout(() => {
          //history.push('/mobile-verification');
          window.location.href = "/mobile-verification";
        }, 1000);
      } else {
        console.log({ result });
        showToast(result.data.mobile, "error");
      }
    } catch (error) {
      dispatch({ type: actionTypes.OTP_LOADING, payload: false });
      showToast(error.message, "error");
    }
  };

export const actionVerifyOtp =
  (request, history) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.OTP_LOADING, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/verify-otp`,
        JSON.stringify(request)
      );
      dispatch({ type: actionTypes.OTP_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch(getUserData(request.user_id));
        setTimeout(() => {
          history.push("/");
        }, 1000);
      } else {
        //dispatch(setAlert(result.data.message, 'error'));
        showToast(result.data.message, "error");
      }
    } catch (error) {
      dispatch({ type: actionTypes.OTP_LOADING, payload: false });
      // dispatch(setAlert(error.message, 'error'));
      showToast(error.message, "error");
    }
  };

export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch({ type: actionTypes.AUTH_SUCCESS, payload: {} });
  window.location.href = "/register";
};

export const actionMakeNotificationRead =
  (user_id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.NOTIFICATION_READ_LOADER, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/make-notification-read?user_id=${user_id}`
      );
      dispatch({ type: actionTypes.NOTIFICATION_READ_LOADER, payload: false });
      parseInt(result.data.status) === 200 &&
        dispatch({
          type: actionTypes.MAKE_REQUESTED,
          payload: "",
        });
    } catch (error) {
      dispatch({ type: actionTypes.NOTIFICATION_READ_LOADER, payload: false });
      showToast(error.message, "error");
    }
  };

export const actionGetCountries = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_COUNTRY_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/get-countries`);
    dispatch({ type: actionTypes.GET_COUNTRY_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.GET_COUNTRIES, payload: result.data.data });
    } else {
      dispatch({ type: actionTypes.GET_COUNTRIES, payload: [] });
      showToast(result.data.message, "error");
    }
  } catch (error) {
    showToast(error.message, "error");
  }
};

export const actionGetStates = (country_id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_STATE_LOADER, payload: true });
  try {
    let result = await axios.get(
      `${BASE_URL}/get-states?country_id=${country_id}`
    );
    dispatch({ type: actionTypes.GET_STATE_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.GET_STATES, payload: result.data.data });
    } else {
      dispatch({ type: actionTypes.GET_STATES, payload: [] });
      showToast(result.data.message, "error");
    }
  } catch (error) {
    showToast(error.message, "error");
  }
};

export const actionGetCities = (state_id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_CITY_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/get-cities?state_id=${state_id}`);
    dispatch({ type: actionTypes.GET_CITY_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.GET_CITIES, payload: result.data.data });
    } else {
      dispatch({ type: actionTypes.GET_CITIES, payload: [] });
      showToast(result.data.message, "error");
    }
  } catch (error) {
    showToast(error.message, "error");
  }
};
// export const actionGetCitiesFliter = (state_id) => async (dispatch, getState) => {
//   dispatch({ type: actionTypes.GET_CITY_FLITER_LOADER, payload: true });
//   try {
//     let result = await axios.get(`${BASE_URL}/get-cities?state_id=${state_id}`);
//     dispatch({ type: actionTypes.GET_CITY_FLITER_LOADER, payload: false });
//     if (parseInt(result.data.status) === 200) {
//       dispatch({ type: actionTypes.GET_CITIES_FLITER, payload: result.data.data });
//     } else {
//       dispatch({ type: actionTypes.GET_CITIES_FLITER, payload: [] });
//       showToast(result.data.message, "error");
//     }
//   } catch (error) {
//     showToast(error.message, "error");
//   }
// };

export const changeProfilePic = (profilePicInfo) => async (dispatch) => {
  try {
    const result = await axios.post(
      `${BASE_URL}/change-profile-picture`,
      profilePicInfo,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (result.data.status === 200) {
      dispatch({
        type: actionTypes.CHANGE_PROFILE_PICTURE,
        payload: result.data.data,
      });
    }
  } catch (error) {
    console.error({ "error updating user-profile data": error });
  }
};

export const actionUpdateUserProfile =
  (userData) => async (dispatch, getState) => {
    console.log(userData);
    dispatch({ type: actionTypes.UPDATE_PROFILE_LOADING, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/update-user-profile`,
        JSON.stringify(userData)
      );
      dispatch({ type: actionTypes.UPDATE_PROFILE_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        showToast(result.data.message, "success");
        dispatch({
          type: actionTypes.UPDATE_PROFILE,
          payload: result.data.data,
        });
      } else showToast(result.data.message, "error");
    } catch (error) {
      showToast(error.message, "error");
    }
  };

export const getUserData = (user_id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_USER_DATA_LOADER, payload: true });
  try {
    let result = await axios.post(
      `${BASE_URL}/get-user-data`,
      JSON.stringify({ user_id })
    );

    dispatch({ type: actionTypes.GET_USER_DATA_LOADER, payload: false });

    if (parseInt(result.data.status) === 200) {
      dispatch({ type: AUTH_SUCCESS, payload: result.data.data });
    } else {
      dispatch({ type: actionTypes.AUTH_SUCCESS, payload: [] });
      showToast(result.data.message, "error");
    }
  } catch (error) {
    showToast(error.message, "error");
  }
};

export const changeCompanyProfilePic = (profilePicInfo) => async (dispatch) => {
  try {
    const result = await axios.post(
      `${BASE_URL}/change-company-logo`,
      profilePicInfo,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (result.data.status === 200) {
      dispatch({
        type: actionTypes.CHANGE_COMPANY_PROFILE_PICTURE,
        payload: result.data.data,
      });
    }
  } catch (error) {
    console.error({ "error updating user-profile data": error });
  }
};

export const getUserRelation =
  (user_id, related_user_id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_USER_RELATION_LOADER, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/get-user-relation?user_id=${user_id}&related_user_id=${related_user_id}`
      );
      dispatch({ type: actionTypes.GET_USER_RELATION_LOADER, payload: false });
      parseInt(result.data.status) === 200 &&
        dispatch({
          type: actionTypes.GET_USER_RELATION,
          payload: result.data.data,
        });
    } catch (error) {
      dispatch({ type: actionTypes.GET_USER_RELATION_LOADER, payload: false });
      showToast(error.message, "error");
      // showToast(error.message, 'error');
    }
  };

export const actionGetPartners = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_PARTNERS_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/get-partners`);
    dispatch({ type: actionTypes.GET_PARTNERS_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.GET_PARTNERS, payload: result.data.data });
    } else {
      dispatch({ type: actionTypes.GET_PARTNERS, payload: [] });
      showToast(result.data.message, "error");
    }
  } catch (error) {
    showToast(error.message, "error");
  }
};

export const actionGetSuggestedLocations =
  (query) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.GET_SUGGESTED_LOCATION_LOADER,
      payload: true,
    });
    try {
      let result = await axios.get(
        `${BASE_URL}/get-suggested-locations?query=${query}`
      );
      dispatch({
        type: actionTypes.GET_SUGGESTED_LOCATION_LOADER,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_SUGGESTED_LOCATIONS,
          payload: result.data.data,
        });
      } else
        dispatch({ type: actionTypes.GET_SUGGESTED_LOCATIONS, payload: [] });
    } catch (error) {
      dispatch({
        type: actionTypes.GET_SUGGESTED_LOCATION_LOADER,
        payload: false,
      });
    }
  };

//Contact From

export const actionContactForm = (contactFormData) => async (dispatch) => {
  dispatch({ type: actionTypes.CONTACT_FORM_LOADING, payload: true });
  try {
    let result = await axios.post(
      `${BASE_URL}/contact-request`,
      JSON.stringify(contactFormData)
    );
    dispatch({ type: actionTypes.CONTACT_FORM_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.CONTACT_FORM_SUCCESS,
        payload: result.data.data,
      });
      showToast(result.data.message, "success");
    } else {
      dispatch({
        type: actionTypes.CONTACT_FORM_FAILED,
        payload: result.data.data,
      });
      showToast(result.data.message, "error");
    }
  } catch (error) {
    showToast(error.message, "error");
  }
};

//Forgot Password

export const actionForgotPassword =
  (email, history) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FORGOT_PASSWORD_LOADING, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/forgot-password`,
        JSON.stringify({ email: email })
      );
      dispatch({ type: actionTypes.FORGOT_PASSWORD_LOADING, payload: false });
      showToast(
        result.data.message,
        parseInt(result.data.status) === 200 ? "success" : "error"
      );
    } catch (error) {
      showToast(error.message, "error");
    }
  };

// Change Passowrd
export const actionChangePassword =
  (userData, history) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.CHANGE_PASSWORD_LOADING, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/change-password`,
        JSON.stringify(userData)
      );
      dispatch({ type: actionTypes.CHANGE_PASSWORD_LOADING, payload: false });
      showToast(
        result.data.message,
        parseInt(result.data.status) === 200 ? "success" : "error"
      );
    } catch (error) {
      showToast(error.message, "error");
    }
  };

export const actionGetTeamMembers = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_TEAM_MEMBERS_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/get-team`);
    dispatch({ type: actionTypes.GET_TEAM_MEMBERS_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_TEAM_MEMBERS,
        payload: result.data.data,
      });
    } else {
      dispatch({ type: actionTypes.GET_TEAM_MEMBERS, payload: [] });
      showToast(result.data.message, "error");
    }
  } catch (error) {
    showToast(error.message, "error");
  }
};

//Subscribe From
export const actionSubscribeForm = (subscribeFormData) => async (dispatch) => {
  dispatch({ type: actionTypes.SUBSCRIBE_FORM_LOADING, payload: true });
  try {
    let result = await axios.post(
      `${BASE_URL}/add-news-latter`,
      JSON.stringify(subscribeFormData)
    );
    dispatch({ type: actionTypes.SUBSCRIBE_FORM_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.SUBSCRIBE_FORM_SUCCESS, payload: false });
      showToast(result.data.message, "success");
    } else {
      dispatch({ type: actionTypes.SUBSCRIBE_FORM_FAILED, payload: true });
      showToast(result.data.message, "error");
    }
  } catch (error) {
    showToast(error.message, "error");
  }
};
