import React, { Component, Fragment } from "react";
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import connect from "react-redux/es/connect/connect";
import {
  actionGetCategories,
  actionGetSubCategories,
  actionGetUnits,
  actionGetAmenities,
  uploadPropertyImage,
  actionAddProperty,
  actionGetCountry,
  actionGetState,
  actionGetCity,
  actionRemoveStates,
  actionRemoveCities,
  actionGetPropertyDetails,
} from "../../actions/propertyAction";
import classnames from "classnames";
import PropertyCommonInput from "./PropertyCommonInput";
import PropertyCommonSelect from "./PropertyCommonSelect";
import PropertyCommonPagerSelection from "./PropertyCommonPagerSelection";
import PropertyCommonTextArea from "./residential-property/PropertyCommonTextArea";
import btnLoader from "../../assets/img/button-loader.svg";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import companyPlaceholder from "../../assets/img/company-logo-placeholder.png";
import SelectSearch from "react-select-search";
import "../../assets/sass/elements/_react-select-search.scss";
import { ADD_PROPERTY } from "../../actions/types";

class AddProperty extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      property_id: "",
      category_id: 1,
      sub_category_id: 1,
      title: "",
      short_desc: "",
      description: "",
      image: "",
      agent_id: this.props.user.user_id,
      square_feet_area: "",
      builder_name: "",
      owner_name: "",
      status: "",
      flat_type: "",
      transaction_type: "",
      furnished_status: "",
      currency: "INR",
      price: "",
      breakup_price: "",
      address: "",
      flooring: "",
      rera_id: "",
      water_availability: "",
      status_of_electricity: "",
      street: "",
      zip_code: "",
      city_id: "",
      country_id: "",
      state_id: "",
      cross_street: "",
      direction: "",
      home_size: "",
      bedroom: "",
      bathrooms: "",
      washroom: "",
      no_of_cabins: "",
      no_of_conf_room: "",
      name_of_industrial_estate: "",
      list_date: "",
      lift: "",
      floor_availability: "",
      disclaimer: "",
      land_area: "",
      authority_approval: "",
      floor_allowed_for_construction: "",
      no_of_open_sides: "",
      gated_colony: "",
      boundary_wall: "",
      car_parking: "",
      type_of_ownership: "",
      overlooking: "",
      facing: "",
      corner_plot: "",
      units_on_floor: "",
      leed_certificate: "",
      super_area: "",
      carpet_area: "",
      area_unit_id: 1,
      pantry: "",
      construction_status: "",
      building_class: "",
      booking_amount: "",
      fire_equipment: "",
      store_room: "",
      pre_leased_property: "",
      landmarks: "",
      additional_features: "",
      property_type: "",
      propertyImgLoader: false,
      amenities: [],
      age_of_property: "",
      city: "",
      src: null,

      crop: {
        unit: "px",
        height: 299,
        aspect: 16 / 9,
      },
      blob: "",
      uploadedImagePreview: "",
      water_availability_input: "",
      errors: {},

      // New Fields For Arizona
      cooling: "",
      heating: "",
      year_built: "",
      lot: "",
      atrium: "",
      ceiling_Fan: "",
      central_vacuum: "",
      cfl_lights: "",
      counters_laminate: "",
      fireplace_gas: "",
      kit_din_combo: "",
      liv_din_combo: "",
      live_on_one_level: "",
      master_on_main: "",
      raised_ceilings_nine_plus_ft: "",
      smoke_detector: "",
      wash_dry_connection: "",
      window_features: "",
      dishwasher: "",
      disposal: "",
      microwave: "",
      range_electric: "",
      range_oven: "",
      refrigerator: "",
      deck_open: "",
      dog_run: "",
      driveway_circular: "",
      driveway_concrete: "",
      fence_backyard: "",
      landscaping_front: "",
      landscaping_rear: "",
      native_species: "",
      patio_covered: "",
      porch_covered: "",
      screens_sun_screens: "",
      sprinkler_drip: "",
      storm_gutters: "",
      xeriscaping: "",
      roads: "",
      carport_spaces: "",
      parking_features: "",
      parking_total: "",
      view: "",
      other_equipment: "",
      calculated_total_monthly_association_fees: "",
      annual_tax_amount: "",
      source_listing_status: "",
      county: "",
      directions: "",
      tax_year: "",
      source_property_type: "",
      exclusions: "",
      source_neighborhood: "",
      parcel_number: "",
      subdivision: "",
      zoning: "",
      lot_number: "",
      source_system_name: "",
      total_square_feet_living: "",
      construction_materials: "",
      property_age: "",
      roof: "",
      levels_or_stories: "",
      structure_type: "",
      house_style: "",
      electric_information: "",
      sewer_information: "",
      utilities_for_property: "",
      services_included: "",
      tax_assessed_value: "",
      air_conditioning: "",
      bike_storage: "",
      business_center: "",
      clubhouse: "",
      doorman: "",
      elevator: "",
      fitness_center: "",
      grill: "",
      high_speed_internet_accessheating: "",
      lounge: "",
      maid_service: "",
      maintenance_on_site: "",
      on_site_retail: "",
      package_service: "",
      picnic_area: "",
      pool: "",
      property_manager_on_site: "",
      range_property: "",
      roof_terrace: "",
      sundeck: "",
      washer_dryer: "",
      wheelchair_accessible: "",
      land_category: "",
      potential_use: "",
      land_features: "",
      mls_id: "",
      property_land_id: "",
      irrigation: "",
      land_improvement: "",
      building: "",
      utility_service: "",
      percent_leased: "",
    };

    if (!localStorage.jwtToken) {
      window.location.href = "/register";
    }
  }

  componentDidMount() {
    this.props.actionGetCategories();
    this.props.actionGetSubCategories(1);
    this.props.actionGetUnits();
    this.props.actionGetAmenities();
    this.props.actionGetCountry();
    this.props.match.params.propertyId &&
      this.props.actionGetPropertyDetails(
        `property_id=${this.props.match.params.propertyId}`
      );

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = `${yyyy}-${mm}-${dd}`;
    this.setState({ list_date: today });
  }

  onChangeCategory = (e) =>
    this.setState({ [e.target.name]: e.target.value }, () => {
      // Select Sub Category API calling
      // this.resetFormField();
      // this.props.actionGetSubCategories(this.state.category_id);

      if (this.props.match.params.propertyId) {
        // Do not reset the field in the selected category
        // console.log('isfbubsdf us')
      } else {
        // Reset the field for other categories
        this.resetFormField();
      }
      if (parseInt(this.state.category_id) === 1) {
        this.setState({
          category_id: 1,
          sub_category_id: 1,
        });
      }
      if (parseInt(this.state.category_id) === 2) {
        this.setState({
          category_id: 2,
          sub_category_id: 4,
        });
      }
      if (parseInt(this.state.category_id) === 4) {
        this.setState({
          category_id: 4,
          sub_category_id: 11,
        });
      }
    });

  onChangeSubCategory = (e) =>
    this.setState({ [e.target.name]: e.target.value }, () => {
      // this.resetFormField();
      if (this.props.match.params.propertyId) {
        // Do not reset the field in the selected category
        // console.log('isfbubsdf us')
      } else {
        // Reset the field for other categories
        this.resetFormField();
      }
    });

  onChange = (e) =>
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState({ agent_id: this.props.user.user_id });
      if (
        this.state.title.length > 0 ||
        this.state.short_desc.length > 0 ||
        this.state.description.length > 0 ||
        this.state.square_feet_area.length > 0 ||
        this.state.builder_name.length > 0 ||
        this.state.owner_name.length > 0 ||
        this.state.status.length > 0 ||
        this.state.flat_type.length > 0 ||
        this.state.transaction_type.length > 0 ||
        this.state.furnished_status.length > 0 ||
        this.state.price.length > 0 ||
        this.state.address.length > 0 ||
        this.state.flooring.length > 0 ||
        this.state.rera_id.length > 0 ||
        this.state.street.length > 0 ||
        this.state.zip_code.length > 0 ||
        this.state.direction.length > 0 ||
        this.state.home_size.length > 0 ||
        this.state.name_of_industrial_estate.length > 0 ||
        this.state.disclaimer.length > 0 ||
        this.state.land_area.length > 0 ||
        this.state.authority_approval.length > 0 ||
        this.state.landmarks.length > 0 ||
        this.state.super_area.length > 0 ||
        this.state.carpet_area.length > 0
      ) {
        this.setState({ errors: {} });
      }
    });

  setPropertyImagePreview = (uploadedImagePreview) =>
    this.setState({ uploadedImagePreview });

  onChangePagerValue = (name, value) => this.setState({ [name]: value });

  onSelectAmenity = (value) =>
    this.state.amenities.filter((p) => parseInt(p) === parseInt(value)).length
      ? this.setState({
          amenities: [
            ...this.state.amenities.filter(
              (p) => parseInt(p) !== parseInt(value)
            ),
          ],
        })
      : this.setState({ amenities: [...this.state.amenities, value] });

  /*  Image Crop */
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newPropertyImgFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;
    // maximum width/height
    var maxWidth = 1200,
      maxHeight = 1200 / (16 / 9);
    var targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }
    // set canvas size
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
        this.setState({ blob }); //added by SO huy nguyen
      }, "image/jpeg");
    });
  }

  handlePropertyImgChange = async (e) => {
    const config = { responseType: "blob" };
    /*let file = axios.get(this.state.croppedImageUrl, config).then(response => {
            new File([response.data], 'my_image.png', {type: "image/jpg", lastModified: new Date()});
        });*/
    var file = new File([this.state.croppedImageUrl], "property_image.png", {
      type: "image/png",
      lastModified: new Date(),
    });
    let formData = new FormData();

    formData.append("property_img", this.state.blob);

    this.setState(
      { propertyImgLoader: true },
      document.getElementById("close-profilePictureUpload") &&
        document.getElementById("close-profilePictureUpload").click()
    );
    try {
      await this.props.uploadPropertyImage(
        formData,
        this.setPropertyImagePreview
      );
      this.setState(
        {
          propertyImgLoader: false,
          src: null,
          blob: "",
        },
        () => (document.getElementById("property-img-selector").value = "")
      );
    } catch (error) {
      this.setState({ propertyImgLoader: false });
    }
  };

  /*  Image Crop Ends */

  handleValidation = () => {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (parseInt(this.state.category_id) === 1) {
      if (!fields.carpet_area && !fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Carpet Area Required!";
        errors.super_area = "Super Area Required!";
      } else if (fields.carpet_area && fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Provide either Carpet Area or Super Area";
        errors.super_area = "Provide either Carpet Area or Super Area";
      }

      if (!fields.builder_name) {
        formIsValid = false;
        errors.builder_name = "Builder Name Required!";
      }

      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }
      if (!fields.street) {
        formIsValid = false;
        errors.street = "Street Required!";
      }
      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }
      if (!fields.age_of_property) {
        formIsValid = false;
        errors.age_of_property = "Age Of Property Required!";
      }
      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }

    if (
      parseInt(this.state.category_id) === 1 ||
      parseInt(this.state.sub_category_id) === 1
    ) {
      if (!fields.carpet_area && !fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Carpet Area Required!";
        errors.super_area = "Super Area Required!";
      } else if (fields.carpet_area && fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Provide either Carpet Area or Super Area";
        errors.super_area = "Provide either Carpet Area or Super Area";
      }

      if (!fields.builder_name) {
        formIsValid = false;
        errors.builder_name = "Builder Name Required!";
      }

      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }
      if (!fields.street) {
        formIsValid = false;
        errors.street = "Street Required!";
      }
      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }
      if (!fields.age_of_property) {
        formIsValid = false;
        errors.age_of_property = "Age Of Property Required!";
      }
      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }

    if (
      parseInt(this.state.category_id) === 1 &&
      parseInt(this.state.sub_category_id) === 2
    ) {
      if (!fields.carpet_area && !fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Carpet Area Required!";
        errors.super_area = "Super Area Required!";
      } else if (fields.carpet_area && fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Provide either Carpet Area or Super Area";
        errors.super_area = "Provide either Carpet Area or Super Area";
      }
      if (!fields.builder_name) {
        formIsValid = false;
        errors.builder_name = "Builder Name Required!";
      }

      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }
      if (!fields.street) {
        formIsValid = false;
        errors.street = "Street Required!";
      }
      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }
      if (!fields.age_of_property) {
        formIsValid = false;
        errors.age_of_property = "Age Of Property Required!";
      }
      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }

    if (
      parseInt(this.state.category_id) === 1 &&
      parseInt(this.state.sub_category_id) === 3
    ) {
      if (!fields.land_area) {
        formIsValid = false;
        errors.land_area = "Land /Plot Area Required!";
      }
      if (!fields.owner_name) {
        formIsValid = false;
        errors.owner_name = "Owner Name Required!";
      }

      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }
      if (!fields.street) {
        formIsValid = false;
        errors.street = "Street Required!";
      }
      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }
      if (!fields.age_of_property) {
        formIsValid = false;
        errors.age_of_property = "Age Of Property Required!";
      }
      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }

    if (parseInt(this.state.category_id) === 2) {
      if (!fields.carpet_area && !fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Carpet Area Required!";
        errors.super_area = "Super Area Required!";
      } else if (fields.carpet_area && fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Provide either Carpet Area or Super Area";
        errors.super_area = "Provide either Carpet Area or Super Area";
      }
      // if (!fields.owner_name) {
      //   formIsValid = false;
      //   errors.owner_name = "owner name Required!";
      // }
      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }
      if (!fields.street) {
        formIsValid = false;
        errors.street = "Street Required!";
      }
      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }
      if (!fields.age_of_property) {
        formIsValid = false;
        errors.age_of_property = "Age Of Property Required!";
      }
      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }

    if (
      parseInt(this.state.category_id) === 2 &&
      parseInt(this.state.sub_category_id) === 5
    ) {
      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }
      if (!fields.owner_name) {
        formIsValid = false;
        errors.owner_name = "Owner Name Required!";
      }

      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }

      if (!fields.age_of_property) {
        formIsValid = false;
        errors.age_of_property = "Age Of Property Required!";
      }
      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }

      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }

    if (
      parseInt(this.state.category_id) === 2 &&
      parseInt(this.state.sub_category_id) === 6
    ) {
      if (!fields.carpet_area && !fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Carpet Area Required!";
        errors.super_area = "Super Area Required!";
      } else if (fields.carpet_area && fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Provide either Carpet Area or Super Area";
        errors.super_area = "Provide either Carpet Area or Super Area";
      }
      if (!fields.builder_name) {
        formIsValid = false;
        errors.builder_name = "Builder Name Required!";
      }
      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }
      if (!fields.street) {
        formIsValid = false;
        errors.street = "Street Required!";
      }
      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }
      if (!fields.age_of_property) {
        formIsValid = false;
        errors.age_of_property = "Age Of Property Required!";
      }
      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }

    if (
      parseInt(this.state.category_id) === 2 &&
      parseInt(this.state.sub_category_id) === 7
    ) {
      if (!fields.carpet_area && !fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Carpet Area Required!";
        errors.super_area = "Super Area Required!";
      } else if (fields.carpet_area && fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Provide either Carpet Area or Super Area";
        errors.super_area = "Provide either Carpet Area or Super Area";
      }
      if (!fields.builder_name) {
        formIsValid = false;
        errors.builder_name = "Builder Name Required!";
      }
      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }
      if (!fields.street) {
        formIsValid = false;
        errors.street = "Street Required!";
      }
      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }

      if (!fields.age_of_property) {
        formIsValid = false;
        errors.age_of_property = "Age Of Property Required!";
      }
      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }
      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }

    if (
      parseInt(this.state.category_id) === 2 &&
      parseInt(this.state.sub_category_id) === 8
    ) {
      if (!fields.owner_name) {
        formIsValid = false;
        errors.owner_name = "Owner Name Required!";
      }
      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }

      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }

      if (!fields.age_of_property) {
        formIsValid = false;
        errors.age_of_property = "Age Of Property Required!";
      }
      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }
      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }

    if (
      parseInt(this.state.category_id) === 2 &&
      parseInt(this.state.sub_category_id) === 9
    ) {
      if (!fields.builder_name) {
        formIsValid = false;
        errors.builder_name = "Builder Name Required!";
      }
      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }
      if (!fields.street) {
        formIsValid = false;
        errors.street = "Street Required!";
      }
      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }

      if (!fields.age_of_property) {
        formIsValid = false;
        errors.age_of_property = "Age Of Property Required!";
      }
      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }

    if (
      parseInt(this.state.category_id) === 2 &&
      parseInt(this.state.sub_category_id) === 12
    ) {
      if (!fields.carpet_area && !fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Carpet Area Required!";
        errors.super_area = "Super Area Required!";
      } else if (fields.carpet_area && fields.super_area) {
        formIsValid = false;
        errors.carpet_area = "Provide either Carpet Area or Super Area";
        errors.super_area = "Provide either Carpet Area or Super Area";
      }
      if (!fields.builder_name) {
        formIsValid = false;
        errors.builder_name = "Builder Name Required!";
      }
      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }
      if (!fields.street) {
        formIsValid = false;
        errors.street = "Street Required!";
      }
      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }

      if (!fields.age_of_property) {
        formIsValid = false;
        errors.age_of_property = "Age Of Property Required!";
      }
      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }
    if (parseInt(this.state.sub_category_id) === 11) {
      if (!fields.address) {
        formIsValid = false;
        errors.address = "Address Required!";
      }
      if (!fields.owner_name) {
        formIsValid = false;
        errors.owner_name = "Owner Name Required!";
      }
      if (!fields.street) {
        formIsValid = false;
        errors.street = "Street Required!";
      }
      if (!fields.zip_code) {
        formIsValid = false;
        errors.zip_code = "Zip code Required!";
      }
      if (!fields.city_id) {
        formIsValid = false;
        errors.city_id = "City Name Required!";
      }
      if (!fields.country_id) {
        formIsValid = false;
        errors.country_id = "Country Name Required!";
      }
      if (!fields.state_id) {
        formIsValid = false;
        errors.state_id = "State Name Required!";
      }

      if (!fields.price) {
        formIsValid = false;
        errors.price = "Price Required!";
      }
      this.setState({ errors: errors }, () => {
        const ID = Object.keys(errors)[0];
        document.getElementById(ID) &&
          document.getElementById(ID).scrollIntoView();
      });
      return formIsValid;
    }
  };
  resetFormField = () => {
    this.setState({
      property_id: "",
      title: "",
      short_desc: "",
      description: "",
      image: "",
      agent_id: this.props.user.user_id,
      square_feet_area: "",
      builder_name: "",
      owner_name: "",
      status: "",
      flat_type: "",
      transaction_type: "",
      furnished_status: "",
      currency: "INR",
      price: "",
      breakup_price: "",
      address: "",
      flooring: "",
      rera_id: "",
      water_availability: "",
      status_of_electricity: "",
      street: "",
      zip_code: "",
      city_id: "",
      country_id: "",
      state_id: "",
      cross_street: "",
      direction: "",
      home_size: "",
      bedroom: "",
      bathrooms: "",
      no_of_cabins: "",
      no_of_conf_room: "",
      name_of_industrial_estate: "",
      list_date: "",
      lift: "",
      floor_availability: "",
      disclaimer: "",
      land_area: "",
      authority_approval: "",
      floor_allowed_for_construction: "",
      no_of_open_sides: "",
      gated_colony: "",
      boundary_wall: "",
      car_parking: "",
      type_of_ownership: "",
      overlooking: "",
      facing: "",
      corner_plot: "",
      units_on_floor: "",
      leed_certificate: "",
      super_area: "",
      carpet_area: "",
      area_unit_id: 1,
      pantry: "",
      construction_status: "",
      building_class: "",
      booking_amount: "",
      fire_equipment: "",
      store_room: "",
      pre_leased_property: "",
      landmarks: "",
      additional_features: "",
      property_type: "",
      propertyImgLoader: false,
      amenities: [],
      age_of_property: "",
      city: "",
      src: null,

      crop: {
        unit: "px",
        height: 299,
        aspect: 5 / 4,
      },
      blob: "",
      uploadedImagePreview: "",
      water_availability_input: "",

      errors: {},
    });
  };

  onPropertySubmit = async (e) => {
    e.preventDefault();
    console.log(this.state.errors);

    if (this.handleValidation()) {
      this.setState({ agent_id: this.props.user.user_id });
      const {
        property_id,
        category_id,
        sub_category_id,
        title,
        short_desc,
        agent_id,
        description,
        square_feet_area,
        owner_name,
        builder_name,
        status,
        transaction_type,
        furnished_status,
        currency,
        price,
        address,
        flooring,
        rera_id,
        water_availability,
        status_of_electricity,
        street,
        zip_code,
        cross_street,
        direction,
        home_size,
        bedroom,
        bathrooms,
        list_date,
        lift,
        floor_availability,
        disclaimer,
        land_area,
        authority_approval,
        floor_allowed_for_construction,
        no_of_open_sides,
        gated_colony,
        boundary_wall,
        car_parking,
        type_of_ownership,
        overlooking,
        facing,
        corner_plot,
        units_on_floor,
        leed_certificate,
        area_unit_id,
        super_area,
        carpet_area,
        pantry,
        construction_status,
        building_class,
        booking_amount,
        fire_equipment,
        store_room,
        pre_leased_property,
        landmarks,
        additional_features,
        property_type,
        amenities,
        age_of_property,
        no_of_cabins,
        no_of_conf_room,
        name_of_industrial_estate,
        flat_type,
        city_id,
        country_id,
        state_id,
        washroom,
      } = this.state;

      const propertyData = {
        ...this.state,
        image: this.state.uploadedImagePreview.substring(
          this.state.uploadedImagePreview.lastIndexOf("/") + 1
        ),
        amenities: amenities.join(","),
      };
      console.log({ propertyData });
      await this.props.actionAddProperty(propertyData, this.props.history);
      this.resetFormField();
    } else {
      console.log("Validation failed. Property not submitted.");
      console.log(this.state.errors);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    prevProps.categories !== this.props.categories &&
      this.props.categories.length < 1 &&
      this.props.actionGetCategories();
    prevProps.categories !== this.props.categories &&
      this.props.categories.length < 1 &&
      this.props.actionGetSubCategories(1);
    prevProps.units !== this.props.units &&
      this.props.units.length < 1 &&
      this.props.actionGetUnits();
    prevProps.amenities !== this.props.amenities &&
      this.props.amenities.length < 1 &&
      this.props.actionGetAmenities();
    prevProps.getPropertyDetails !== this.props.getPropertyDetails &&
      this.props.getPropertyDetails &&
      this.setState(
        {
          ...this.state,
          ...this.props.getPropertyDetails,
          uploadedImagePreview: this.props.getPropertyDetails.image,
          country_id: parseInt(this.props.getPropertyDetails.country_id),
          state_id: parseInt(this.props.getPropertyDetails.state_id),
          city_id: parseInt(this.props.getPropertyDetails.city_id),
          amenities: this.props.getPropertyDetails.amenities
            ? this.props.getPropertyDetails.amenities.map((a) => a.amenity_id)
            : [],
        },
        () => {
          this.props.actionGetSubCategories(
            this.props.getPropertyDetails &&
              this.props.getPropertyDetails.category_id
          );
          this.props.actionGetCountry();
          this.props.actionGetState(
            `country_id=${this.props.getPropertyDetails.country_id}&q=`
          );
          this.props.actionGetCity(
            `state_id=${this.props.getPropertyDetails.state_id}&q=`
          );
        }
      );
  }

  render() {
    const {
      categories,
      subCategories,
      getPropertySubCategoryLoader,
      units,
      amenities,
      addPropertyLoader,
    } = this.props;
    const { errors, category_id, sub_category_id, src, crop } = this.state;

    let minOffset = 0,
      maxOffset = 100;
    let thisYear = new Date().getFullYear();
    let allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
      allYears.push(thisYear - x);
    }
    const yearList = allYears.map((y) => y);

    window.addEventListener("click", () => {
      if (document.getElementById("country-select"))
        document.getElementById("country-select").style.display = "none";

      if (document.getElementById("state-select"))
        document.getElementById("state-select").style.display = "none";

      if (document.getElementById("city-select"))
        document.getElementById("city-select").style.display = "none";
    });

    const countryData =
      this.props.country &&
      this.props.country.length > 0 &&
      this.props.country.map((c) => {
        return {
          name: c.name,
          value: c.id,
        };
      });
    const stateData =
      this.props.states &&
      this.props.states.length > 0 &&
      this.props.states.map((s) => ({
        name: s.name,
        value: s.id,
      }));
    const cityData =
      this.props.city &&
      this.props.city.length > 0 &&
      this.props.city.map((c) => ({
        name: c.name,
        value: c.id,
      }));

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    return (
      <Fragment>
        <Navbar scenario={ADD_PROPERTY} />
        <PageHeader headertitle="Add-Property" />
        <Fragment>
          <div className="container property-container">
            <div className="row justify-content-center mt-5">
              <div className="col-12">
                <form
                  className="contact-form-wrap contact-form-bg"
                  autoComplete="off"
                  onSubmit={this.onPropertySubmit}
                >
                  <div className="row">
                    <div className="col-12 text-center">
                      <h6>Choose Category</h6>
                    </div>

                    <div className="col-12 d-sm-flex radio_type justify-content-center">
                      {categories &&
                        categories.map((category, index) => (
                          <div className="form-check radio_sty" key={index}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="category_id"
                              id="flexRadioDefault1"
                              value={category.category_id}
                              checked={
                                parseInt(this.state.category_id) ===
                                parseInt(category.category_id)
                              }
                              onChange={this.onChangeCategory}
                            />

                            <label
                              className="form-check-label mr-2"
                              htmlFor="flexRadioDefault1"
                            >
                              {category.name}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>

                  {!getPropertySubCategoryLoader &&
                    subCategories &&
                    subCategories.length !== 0 && (
                      <div className="row mt-5">
                        <div className="col-12 text-center">
                          <h6>Choose Sub Category</h6>
                        </div>
                        <div className="col-12">
                          <div className="sub-category-container">
                            {subCategories &&
                              subCategories.map((subCategory, index) => (
                                <div
                                  key={subCategory.sub_category_id}
                                  className="sub-category"
                                >
                                  <div className="radio ">
                                    <input
                                      className="radio_type ml-2"
                                      type="radio"
                                      value={subCategory.sub_category_id}
                                      id="sub_category_id"
                                      name="sub_category_id"
                                      checked={
                                        parseInt(sub_category_id) ===
                                        parseInt(subCategory.sub_category_id)
                                      }
                                      onChange={this.onChangeSubCategory}
                                    />{" "}
                                    {subCategory.name}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}

                  {getPropertySubCategoryLoader && (
                    <div className="row mt-5">
                      <div className="col-12 text-center">
                        <i className="fa fa-spinner" /> Please Wait...
                      </div>
                    </div>
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2 ||
                    parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 12) && (
                    <div className="row mt-4 justify-content-center">
                      <div className="col-12 text-center">
                        <hr />
                        <h6>Area</h6>
                        <small className="text-info">
                          (Provide either Carpet Area or Super Area)
                        </small>
                      </div>
                      <div className="col-12 text-center">
                        <hr />
                        <h6>Property Features</h6>
                        <small className="text-info">
                          (Provide Cooling, Heating, Year built, Lot(sqrt),
                          Price/Per sqft etc.)
                        </small>
                      </div>
                      {/* <div className="col-md-6 text-center mt-2">
                        <div className="row justify-content-center">
                          <div className="col-12 text-left">
                            <label>
                              Carpet Area <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 mb-sm-3">
                            <div className="rld-single-input mt-0">
                              <input
                                type="text"
                                name="carpet_area"
                                id="carpet_area"
                                value={
                                  this.state.carpet_area
                                    ? Number(
                                        this.state.carpet_area.replace(
                                          /[^0-9.-]+/g,
                                          ""
                                        )
                                      )
                                    : ""
                                }
                                onChange={this.onChange}
                                className={classnames({
                                  "is-invalid": errors.carpet_area,
                                })}
                                placeholder="Please Enter Carpet Area"
                              />
                              {errors.carpet_area && (
                                <span className="text-danger">
                                  {errors.carpet_area}
                                </span>
                              )}
                            </div>
                          </div>  <div className="col-md-4">
                            <div className="rld-single-select">
                              <select
                                id="area_unit_id"
                                name="area_unit_id"
                                className="select single-select"
                                onChange={this.onChange}
                                value={this.state.area_unit_id}
                              >
                                {units &&
                                  units.map((unit) => (
                                    <option
                                      value={unit.units_of_measures_id}
                                      key={unit.units_of_measures_id}
                                    >
                                      {unit.name}
                                    </option>
                                  ))}
                              </select>
                              {errors.area_unit_id && (
                                <span className="text-danger">
                                  {errors.area_unit_id}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div className="col-md-6 text-center mt-1">
                        <div className="row justify-content-center">
                          <div className="col-12 text-left">
                            <label>
                              Super Area <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 mb-sm-3">
                            <div className="rld-single-input mt-0">
                              <input
                                type="text"
                                name="super_area"
                                id="super_area"
                                value={
                                  this.state.super_area
                                    ? Number(
                                        this.state.super_area.replace(
                                          /[^0-9.-]+/g,
                                          ""
                                        )
                                      )
                                    : ""
                                }
                                onChange={this.onChange}
                                className={classnames({
                                  "is-invalid": errors.super_area,
                                })}
                                placeholder="Please Enter Super Area"
                              />
                              {errors.super_area && (
                                <span className="text-danger">
                                  {errors.super_area}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="rld-single-select">
                              <select
                                id="area_unit_id"
                                name="area_unit_id"
                                className="select single-select"
                                onChange={this.onChange}
                                value={this.state.area_unit_id}
                              >
                                {units &&
                                  units.map((unit) => (
                                    <option
                                      value={unit.units_of_measures_id}
                                      key={unit.units_of_measures_id}
                                    >
                                      {unit.name}
                                    </option>
                                  ))}
                              </select>
                              {errors.area_unit_id && (
                                <span className="text-danger">
                                  {errors.area_unit_id}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-6 text-center mt-2">
                        <div className="row justify-content-center">
                          <div className="col-12 text-left">
                            <label>
                              Carpet Area <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 mb-sm-3">
                            <div className="rld-single-input mt-0">
                              <input
                                type="text"
                                name="carpet_area"
                                id="carpet_area"
                                // value={this.state.carpet_area}
                                value={
                                  this.state.carpet_area
                                    ? Number(
                                        this.state.carpet_area.replace(
                                          /[^0-9.-]+/g , 
                                          ""
                                        )
                                      )
                                    : ""
                                }
                                onChange={this.onChange}
                                className={classnames({
                                  "is-invalid": errors.carpet_area,
                                })}
                                placeholder="Please Enter Carpet Area"
                              />
                              {errors.carpet_area && (
                                <span className="text-danger">
                                  {errors.carpet_area}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="rld-single-select">
                              <select
                                id="area_unit_id"
                                name="area_unit_id"
                                className="select single-select"
                                onChange={this.onChange}
                                value={this.state.area_unit_id}
                              >
                                {units &&
                                  units.map((unit) => (
                                    <option
                                      value={unit.units_of_measures_id}
                                      key={unit.units_of_measures_id}
                                    >
                                      {unit.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 text-center mt-1">
                        <div className="row justify-content-center">
                          <div className="col-12 text-left">
                            <label>
                              Super Area <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 mb-sm-3">
                            <div className="rld-single-input mt-0">
                              <input
                                type="text"
                                name="super_area"
                                id="super_area"
                                // value={this.state.super_area}
                                value={
                                  this.state.super_area
                                    ? Number(
                                        this.state.super_area.replace(
                                          /[^0-9.-]+/g , 
                                          ""
                                        )
                                      )
                                    : ""
                                }
                                onChange={this.onChange}
                                className={classnames({
                                  "is-invalid": errors.super_area,
                                })}
                                placeholder="Please Enter Super Area"
                              />
                              {errors.super_area && (
                                <span className="text-danger">
                                  {errors.super_area}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="rld-single-select">
                              <select
                                id="area_unit_id"
                                name="area_unit_id"
                                className="select single-select"
                                onChange={this.onChange}
                                value={this.state.area_unit_id}
                              >
                                {units &&
                                  units.map((unit) => (
                                    <option
                                      value={unit.units_of_measures_id}
                                      key={unit.units_of_measures_id}
                                    >
                                      {unit.name}
                                    </option>
                                  ))}
                              </select>
                              {errors.area_unit_id && (
                                <span className="text-danger">
                                  {errors.area_unit_id}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/*Arizona Fild Start*/}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2 ||
                    parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"builder_name"}
                      onChange={this.onChange}
                      error={errors.builder_name}
                      label={"Builder Name"}
                      value={this.state.builder_name}
                      placeholder={"Please Enter Builder Name (Owner Name)"}
                      required="*"
                    />
                  )}
                  {(parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 11) && (
                    <PropertyCommonInput
                      name={"owner_name"}
                      onChange={this.onChange}
                      error={errors.owner_name}
                      label={"Owner Name"}
                      value={this.state.owner_name}
                      placeholder={"Please Enter Owner Name(Builder Name)"}
                      required="*"
                    />
                  )}
                  {(parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"title"}
                      onChange={this.onChange}
                      error={errors.title}
                      label={"Title"}
                      value={this.state.title}
                      placeholder={"Title"}
                      required="*"
                    />
                  )}

                  {/*======> Residential  Ploat / Land  Property Start <========*/}

                  {(parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11) && (
                    <div className="row mt-4 ">
                      <div className="col-md-7  col-sm-12 text-md-right mt-1 mx-auto">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-4 ">
                            <label>
                              Ploat Area<span className={"text-danger"}>*</span>
                            </label>
                          </div>
                          <div className="col-md-3">
                            <div className="rld-single-select">
                              <select
                                id="area_unit_id"
                                name="area_unit_id"
                                className="select single-select"
                                onChange={this.onChange}
                                value={this.state.area_unit_id}
                              >
                                {units &&
                                  units.map((unit) => (
                                    <option
                                      value={unit.units_of_measures_id}
                                      key={unit.units_of_measures_id}
                                    >
                                      {unit.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="rld-single-input mt-0 text-center ">
                              <input
                                type="text"
                                name="land_area"
                                id="land_area"
                                // value={this.state.land_area}
                                value={
                                  this.state.land_area
                                    ? Number(
                                        this.state.land_area.replace(
                                          /[^0-9.-]+/g,
                                          ""
                                        )
                                      )
                                    : ""
                                }
                                onChange={this.onChange}
                                className={classnames({
                                  "is-invalid": errors.land_area,
                                })}
                                placeholder="Plot area (Land area)*"
                              />
                              {errors.land_area && (
                                <span className="text-danger">
                                  {errors.land_area}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {(parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 11) && (
                    <PropertyCommonInput
                      name={"authority_approval"}
                      onChange={this.onChange}
                      error={errors.authority_approval}
                      label={"Authority Approval"}
                      value={this.state.authority_approval}
                      placeholder={"Authority Approval"}
                    />
                  )}

                  {/*Lot(sqrt)*/}
                  {/* <PropertyCommonInput
                    name={"lot"}
                    onChange={this.onChange}
                    error={errors.lot}
                    label={"Lot(sqrt)"}
                    value={this.state.lot}
                    placeholder={"Lot(sqrt)"}
                  /> */}

                  {/*Price/Per sqft*/}

                  {/* <hr />
                  <div className="row mt-4 justify-content-center">
                    <div className="col-12 text-center">
                      <h6>Interior Features</h6>
                      <small className="text-info">
                        (Provide Atrium, Ceiling Fan(s), Central Vacuum, CFL
                        Lights, etc.)
                      </small>
                    </div>
                  </div>
                  <hr /> */}

                  {/*Atrium*/}
                  {/* <PropertyCommonInput
                    name={"atrium"}
                    onChange={this.onChange}
                    error={errors.atrium}
                    label={"Atrium"}
                    value={this.state.atrium}
                    placeholder={"Atrium"}
                  /> */}

                  {/*Ceiling Fan(s)*/}
                  {/* <PropertyCommonInput
                    name={"ceiling_Fan"}
                    onChange={this.onChange}
                    error={errors.ceiling_Fan}
                    label={"Ceiling Fan(s)"}
                    value={this.state.ceiling_Fan}
                    placeholder={"Ceiling Fan(s)"}
                  /> */}

                  {/*Central Vacuum*/}
                  {/* <PropertyCommonInput
                    name={"central_vacuum"}
                    onChange={this.onChange}
                    error={errors.central_vacuum}
                    label={"Central Vacuum"}
                    value={this.state.central_vacuum}
                    placeholder={"Central Vacuum"}
                  /> */}
                  {/*CFL Lights*/}
                  {/* <PropertyCommonInput
                    name={"cfl_lights"}
                    onChange={this.onChange}
                    error={errors.cfl_lights}
                    label={"CFL Lights"}
                    value={this.state.cfl_lights}
                    placeholder={"CFL Lights"}
                  /> */}

                  {/*Counters-Laminate*/}
                  {/* <PropertyCommonInput
                    name={"counters_laminate"}
                    onChange={this.onChange}
                    error={errors.counters_laminate}
                    label={"Counters-Laminate"}
                    value={this.state.counters_laminate}
                    placeholder={"Counters-Laminate"}
                  /> */}

                  {/*Fireplace-Gas*/}
                  {/* <PropertyCommonInput
                    name={"fireplace_gas"}
                    onChange={this.onChange}
                    error={errors.fireplace_gas}
                    label={"Fireplace-Gas"}
                    value={this.state.fireplace_gas}
                    placeholder={"Fireplace-Gas"}
                  /> */}

                  {/*Kit/Din Combo*/}
                  {/* <PropertyCommonInput
                    name={"kit_din_combo"}
                    onChange={this.onChange}
                    error={errors.kit_din_combo}
                    label={"Kit/Din Combo"}
                    value={this.state.kit_din_combo}
                    placeholder={"Kit/Din Combo"}
                  /> */}

                  {/*Liv/Din Combo*/}
                  {/* <PropertyCommonInput
                    name={"liv_din_combo"}
                    onChange={this.onChange}
                    error={errors.liv_din_combo}
                    label={"Liv/Din Combo"}
                    value={this.state.liv_din_combo}
                    placeholder={"Liv/Din Combo"}
                  /> */}

                  {/*Live on One Level*/}
                  {/* <PropertyCommonInput
                    name={"live_on_one_level"}
                    onChange={this.onChange}
                    error={errors.live_on_one_level}
                    label={"Live on One Level"}
                    value={this.state.live_on_one_level}
                    placeholder={"Live on One Level"}
                  /> */}

                  {/*Master On Main*/}
                  {/* <PropertyCommonInput
                    name={"master_on_main"}
                    onChange={this.onChange}
                    error={errors.master_on_main}
                    label={"Master On Main"}
                    value={this.state.master_on_main}
                    placeholder={"Master On Main"}
                  /> */}

                  {/*Raised Ceilings 9+ft*/}
                  {/* <PropertyCommonInput
                    name={"raised_ceilings_nine_plus_ft"}
                    onChange={this.onChange}
                    error={errors.raised_ceilings_nine_plus_ft}
                    label={"Raised Ceilings 9+ft"}
                    value={this.state.raised_ceilings_nine_plus_ft}
                    placeholder={"Raised Ceilings 9+ft"}
                  /> */}

                  {/*Smoke Detector(s)*/}
                  {/* <PropertyCommonInput
                    name={"smoke_detector"}
                    onChange={this.onChange}
                    error={errors.smoke_detector}
                    label={"Smoke Detector(s)"}
                    value={this.state.smoke_detector}
                    placeholder={"Smoke Detector(s)"}
                  /> */}

                  {/*Wash/Dry Connection*/}
                  {/* <PropertyCommonInput
                    name={"wash_dry_connection"}
                    onChange={this.onChange}
                    error={errors.wash_dry_connection}
                    label={"Wash/Dry Connection"}
                    value={this.state.wash_dry_connection}
                    placeholder={"Wash/Dry Connection"}
                  /> */}

                  {/*Wash/Dry Connection*/}
                  {/* <PropertyCommonInput
                    name={"window_features"}
                    onChange={this.onChange}
                    error={errors.window_features}
                    label={"Window Features"}
                    value={this.state.window_features}
                    placeholder={"Window Features: Aluminum, Pleated Shades"}
                  /> */}

                  <hr />
                  <div className="row mt-4 justify-content-center">
                    <div className="col-12 text-center">
                      <h6>Address</h6>
                      <small className="text-info">
                        (Provide city, state etc.)
                      </small>
                    </div>
                  </div>
                  <hr />

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2 ||
                    parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonTextArea
                      name={"address"}
                      onChange={this.onChange}
                      error={errors.address}
                      label={"Address"}
                      value={this.state.address}
                      required="*"
                      placeholder={"Please Enter Address"}
                    />
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2 ||
                    parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"street"}
                      onChange={this.onChange}
                      error={errors.street}
                      label={"Street"}
                      value={this.state.street}
                      placeholder={"e.g XYZ Street"}
                      required="*"
                    />
                  )}

                  <div className="row mt-4 ">
                    <div className="col-md-7  col-sm-12 text-md-right mt-1 mx-auto">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-4 ">
                          <label>
                            Country<span className={"text-danger"}>*</span>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <div className="rld-single-input mt-0 text-center">
                            <SelectSearch
                              options={countryData.length ? countryData : []}
                              value={this.state.country_id}
                              onChange={(country_id) =>
                                this.setState(
                                  {
                                    country_id,
                                    state_id: "",
                                    city_id: "",
                                  },
                                  this.props.actionRemoveStates()
                                )
                              }
                              name="country_id"
                              id="country_id"
                              autoComplete="none"
                              placeholder="Choose your country"
                              disabled={!countryData.length}
                              search={true}
                            />
                            {errors.country_id && (
                              <span className="text-danger">
                                {errors.country_id}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-8"></div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 ">
                    <div className="col-md-7  col-sm-12 text-md-right mt-1 mx-auto">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-4 ">
                          <label>
                            State<span className={"text-danger"}>*</span>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <div className="rld-single-input mt-0 text-center ">
                            <SelectSearch
                              options={stateData.length ? stateData : []}
                              getOptions={(query) =>
                                this.props.actionGetState(
                                  `country_id=${this.state.country_id}&q=${query}`
                                )
                              }
                              value={this.state.state_id}
                              onChange={(state_id) =>
                                this.setState(
                                  {
                                    state_id,
                                    city_id: "",
                                  },
                                  this.props.actionRemoveCities()
                                )
                              }
                              name="state_id"
                              id="state_id"
                              autoComplete="none"
                              placeholder="Choose your state"
                              disabled={!this.state.country_id}
                              search={true}
                            />

                            {errors.state_id && (
                              <span className="text-danger">
                                {errors.state_id}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 ">
                    <div className="col-md-7  col-sm-12 text-md-right mt-1 mx-auto">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-4 ">
                          <label>
                            City<span className={"text-danger"}>*</span>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <div className="rld-single-input mt-0 text-center ">
                            <SelectSearch
                              options={cityData.length ? cityData : []}
                              getOptions={(query) =>
                                this.props.actionGetCity(
                                  `state_id=${this.state.state_id}&q=${query}`
                                )
                              }
                              value={this.state.city_id && this.state.city_id}
                              onChange={(city_id) => this.setState({ city_id })}
                              name="city_id"
                              id="city_id"
                              autoComplete="none"
                              placeholder="Choose your city"
                              disabled={
                                !this.state.state_id || !this.state.country_id
                              }
                              search={true}
                            />
                            {errors.city_id && (
                              <span className="text-danger">
                                {errors.city_id}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2 ||
                    parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"zip_code"}
                      id={"zip_code"}
                      onChange={this.onChange}
                      error={errors.zip_code}
                      label={"ZIP Code"}
                      // value={this.state.zip_code}
                      value={
                        this.state.zip_code
                          ? Number(
                              this.state.zip_code.replace(/[^0-9.-]+/g, "")
                            )
                          : ""
                      }
                      placeholder={"Please Enter ZIP Code"}
                      intype={"text"}
                      required="*"
                    />
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2) && (
                    <PropertyCommonInput
                      name={"direction"}
                      onChange={this.onChange}
                      error={errors.direction}
                      label={"Direction"}
                      value={this.state.direction}
                      placeholder={"Please Enter Direction"}
                    />
                  )}

                  <hr />
                  <div className="row mt-4 justify-content-center">
                    <div className="col-12 text-center">
                      <h6>Property Details</h6>
                      <small className="text-info">
                        (Provide number of units – i.e. bathrooms, bedrooms,
                        conference room, parking etc.)
                      </small>
                    </div>
                  </div>
                  <hr />

                  {/*======> Commercial  office Property Start <========*/}
                  {(parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"name_of_industrial_estate"}
                      onChange={this.onChange}
                      error={errors.name_of_industrial_estate}
                      label={"Name Of Industrial Estate"}
                      value={this.state.name_of_industrial_estate}
                      placeholder={"Name Of Industrial Estate (IDE / SEZ)"}
                    />
                  )}
                  {(parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7) && (
                    <PropertyCommonSelect
                      name={"construction_status"}
                      onChange={this.onChange}
                      error={errors.construction_status}
                      label={"Construction Status"}
                      value={this.state.construction_status}
                      data={["YES", "NO"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"floor_availability"}
                      onChange={this.onChange}
                      error={errors.floor_availability}
                      label={"Floor availability"}
                      // value={this.state.floor_availability}
                      value={
                        this.state.floor_availability
                          ? Number(
                              this.state.floor_availability.replace(
                                /[^0-9.-]+/g,
                                ""
                              )
                            )
                          : ""
                      }
                      placeholder={"e.g 14"}
                      intype={"text"}
                    />
                  )}

                  {(parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"units_on_floor"}
                      onChange={this.onChange}
                      error={errors.units_on_floor}
                      label={"Units on Floor"}
                      value={this.state.units_on_floor}
                      placeholder={"Units on Floor"}
                    />
                  )}
                  {parseInt(sub_category_id) === 4 && (
                    <PropertyCommonSelect
                      label={"Facing"}
                      name={"facing"}
                      value={this.state.facing}
                      error={errors.facing}
                      onChange={this.onChange}
                      data={["East", "West", "North", "South"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7) && (
                    <PropertyCommonSelect
                      label={"Furnished Status"}
                      name={"furnished_status"}
                      value={this.state.furnished_status}
                      error={errors.furnished_status}
                      onChange={this.onChange}
                      required="*"
                      data={["YES", "NO"]}
                    />
                  )}

                  {/*======> Commercial  office Property End <========*/}

                  {/*======> Residential  Ploat / Land  Property Start <========*/}

                  {(parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11) && (
                    <PropertyCommonSelect
                      name={"boundary_wall"}
                      onChange={this.onChange}
                      error={errors.boundary_wall}
                      label={"Boundary Wall"}
                      value={this.state.boundary_wall}
                      data={["YES", "NO"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"type_of_ownership"}
                      onChange={this.onChange}
                      error={errors.type_of_ownership}
                      label={"Type Of Ownership"}
                      value={this.state.type_of_ownership}
                      placeholder={"e.g Freehold"}
                    />
                  )}

                  {(parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"no_of_open_sides"}
                      onChange={this.onChange}
                      error={errors.no_of_open_sides}
                      label={"Number Of Open Sides"}
                      value={this.state.no_of_open_sides}
                      placeholder={"e.g 3"}
                    />
                  )}

                  {parseInt(sub_category_id) === 3 && (
                    <PropertyCommonSelect
                      name={"overlooking"}
                      onChange={this.onChange}
                      error={errors.overlooking}
                      label={"Overlooking"}
                      value={this.state.overlooking}
                      data={["Garden", "Park"]}
                    />
                  )}

                  {/*======> Residential  Ploat / Land  Property End <========*/}

                  {parseInt(sub_category_id) === 1 && (
                    <PropertyCommonSelect
                      label={"Flat Type"}
                      name={"flat_type"}
                      value={this.state.flat_type}
                      error={errors.flat_type}
                      onChange={this.onChange}
                      data={["1BHK", "2BHK", "3BHK", "4BHK", "5BHK"]}
                    />
                  )}
                  {parseInt(sub_category_id) === 2 && (
                    <PropertyCommonSelect
                      label={"House/Vila Type"}
                      name={"flat_type"}
                      value={this.state.flat_type}
                      error={errors.flat_type}
                      onChange={this.onChange}
                      data={[
                        "1BHK",
                        "2BHK",
                        "3BHK",
                        "4BHK",
                        "5BHK",
                        "6BHK",
                        "6+BHK",
                      ]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2 ||
                    parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonSelect
                      label={"Transaction Type"}
                      name={"transaction_type"}
                      value={this.state.transaction_type}
                      error={errors.transaction_type}
                      onChange={this.onChange}
                      data={["New Property", "Resell Property"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2) && (
                    <PropertyCommonInput
                      name={"home_size"}
                      onChange={this.onChange}
                      error={errors.home_size}
                      label={"Home Size (Sq Feet)"}
                      value={this.state.home_size}
                      placeholder={"Please Enter Home Size in Sq Feet"}
                      intype={"number"}
                    />
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2) && (
                    <PropertyCommonPagerSelection
                      label={"Bedrooms"}
                      onChange={this.onChangePagerValue}
                      name={"bedroom"}
                      value={this.state.bedroom}
                      data={["1", "2", "3", "4", "5", "5+"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2 ||
                    parseInt(sub_category_id) === 7) && (
                    <PropertyCommonPagerSelection
                      label={"Bathrooms"}
                      onChange={this.onChangePagerValue}
                      name={"bathrooms"}
                      value={this.state.bathrooms}
                      data={["1", "2", "3", "4", "5", "6", "6+"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonSelect
                      name={"status"}
                      onChange={this.onChange}
                      error={errors.status}
                      label={"Status"}
                      value={this.state.status}
                      data={["Ready To Move", "Work In Progress"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2) && (
                    <PropertyCommonSelect
                      label={"Furnished Status"}
                      name={"furnished_status"}
                      value={this.state.furnished_status}
                      error={errors.furnished_status}
                      onChange={this.onChange}
                      data={["YES", "NO"]}
                    />
                  )}

                  <div className="row mt-4 ">
                    <div className="col-md-7  col-sm-12 text-md-right mt-1 mx-auto">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-4 ">
                          <label>
                            Price<span className={"text-danger"}>*</span>
                          </label>
                        </div>
                        <div className="col-md-3 ">
                          <div className="rld-single-select">
                            <select
                              id="currency"
                              name="currency"
                              className="select single-select"
                              onChange={(e) =>
                                this.setState({
                                  [e.target.name]: e.target.value,
                                })
                              }
                              value={this.state.currency}
                            >
                              <option value={"INR"}>INR</option>
                              <option value={"USD"}>USD</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-5 ">
                          <div className="rld-single-input mt-md-0 text-center mt-sm-3">
                            <input
                              type="text"
                              name="price"
                              id="price"
                              value={
                                this.state.price
                                  ? Number(
                                      this.state.price.replace(/[^0-9.-]+/g, "")
                                    )
                                  : ""
                              }
                              onChange={this.onChange}
                              className={classnames({
                                "is-invalid": errors.price,
                              })}
                              placeholder="Price"
                            />
                            {errors.price && (
                              <span className="text-danger">
                                {errors.price}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2 ||
                    parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 11) && (
                    <PropertyCommonTextArea
                      name={"description"}
                      onChange={this.onChange}
                      error={errors.description}
                      label={"Description"}
                      value={this.state.description}
                      placeholder={"Please Enter description"}
                    />
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2 ||
                    parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7) && (
                    <PropertyCommonSelect
                      name={"car_parking"}
                      onChange={this.onChange}
                      error={errors.car_parking}
                      label={"Car Parking"}
                      value={this.state.car_parking}
                      data={["No", "1", "2", "3", "4", "5"]}
                    />
                  )}
                  {(parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"leed_certificate"}
                      onChange={this.onChange}
                      error={errors.leed_certificate}
                      label={"Leed Certificate"}
                      value={this.state.leed_certificate}
                      placeholder={"LEED Certification"}
                    />
                  )}

                  {parseInt(sub_category_id) === 4 && (
                    <PropertyCommonPagerSelection
                      label={"Number Of Cabins"}
                      onChange={this.onChangePagerValue}
                      name={"no_of_cabins"}
                      value={this.state.no_of_cabins}
                      required="*"
                      data={["1", "2", "3", "4", "5", "6", "6+"]}
                    />
                  )}
                  {parseInt(sub_category_id) === 4 && (
                    <PropertyCommonPagerSelection
                      label={"Number Of conference rooms"}
                      onChange={this.onChangePagerValue}
                      name={"no_of_conf_room"}
                      value={this.state.no_of_conf_room}
                      required="*"
                      data={["1", "2", "3", "4", "5", "6", "6+"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 6) && (
                    <PropertyCommonPagerSelection
                      label={"Washroom"}
                      onChange={this.onChangePagerValue}
                      name={"washroom"}
                      value={this.state.washroom}
                      data={["1", "2", "3", "4", "5", "6", "6+"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7) && (
                    <PropertyCommonSelect
                      name={"pantry"}
                      onChange={this.onChange}
                      error={errors.pantry}
                      label={"Pantry"}
                      value={this.state.pantry}
                      data={["YES", "NO"]}
                    />
                  )}

                  {parseInt(sub_category_id) === 4 && (
                    <PropertyCommonSelect
                      name={"fire_equipment"}
                      onChange={this.onChange}
                      error={errors.fire_equipment}
                      label={"Fire Fighting Equipment"}
                      value={this.state.fire_equipment}
                      data={["YES", "NO"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2 ||
                    parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"age_of_property"}
                      onChange={this.onChange}
                      error={errors.age_of_property}
                      label={"Age of property (In Year)"}
                      // value={this.state.age_of_property}
                      value={
                        this.state.age_of_property
                          ? Number(
                              this.state.age_of_property.replace(
                                /[^0-9.-]+/g,
                                ""
                              )
                            )
                          : ""
                      }
                      placeholder={"Please Enter Age of Property (In Year)"}
                      intype={"text"}
                      required={"*"}
                    />
                  )}

                  {(parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11) && (
                    <PropertyCommonSelect
                      name={"gated_colony"}
                      onChange={this.onChange}
                      error={errors.gated_colony}
                      label={"Gated Colony"}
                      value={this.state.gated_colony}
                      data={["YES", "NO"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonSelect
                      label={"Facing"}
                      name={"facing"}
                      value={this.state.facing}
                      error={errors.facing}
                      onChange={this.onChange}
                      data={["East", "West", "North", "South"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 3 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 11) && (
                    <PropertyCommonSelect
                      label={"Corner Plot"}
                      name={"corner_plot"}
                      value={this.state.corner_plot}
                      error={errors.corner_plot}
                      onChange={this.onChange}
                      data={["YES", "NO"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 1 ||
                    parseInt(sub_category_id) === 2) && (
                    <PropertyCommonInput
                      name={"rera_id"}
                      onChange={this.onChange}
                      error={errors.rera_id}
                      label={"RERA ID"}
                      value={this.state.rera_id}
                      placeholder={"Please Enter RERA ID"}
                      intype={"text"}
                    />
                  )}

                  {parseInt(sub_category_id) === 2 && (
                    <PropertyCommonInput
                      name={"flooring"}
                      onChange={this.onChange}
                      error={errors.flooring}
                      label={"Flooring"}
                      value={this.state.flooring}
                      placeholder={"Please Enter Flooring"}
                    />
                  )}

                  {(parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonSelect
                      name={"floor_allowed_for_construction"}
                      onChange={this.onChange}
                      error={errors.floor_allowed_for_construction}
                      label={"Floor allowed for Construction"}
                      value={this.state.floor_allowed_for_construction}
                      data={["YES", "NO"]}
                    />
                  )}

                  {(parseInt(sub_category_id) === 4 ||
                    parseInt(sub_category_id) === 5 ||
                    parseInt(sub_category_id) === 6 ||
                    parseInt(sub_category_id) === 7 ||
                    parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"disclaimer"}
                      onChange={this.onChange}
                      error={errors.disclaimer}
                      label={"Disclaimer"}
                      value={this.state.disclaimer}
                      placeholder={"Disclaimer"}
                    />
                  )}

                  {(parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 11 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonInput
                      name={"landmarks"}
                      onChange={this.onChange}
                      error={errors.landmarks}
                      label={"Landmarks"}
                      value={this.state.landmarks}
                      placeholder={"Landmarks"}
                    />
                  )}

                  {(parseInt(sub_category_id) === 8 ||
                    parseInt(sub_category_id) === 9 ||
                    parseInt(sub_category_id) === 12) && (
                    <PropertyCommonSelect
                      name={"pre_leased_property"}
                      onChange={this.onChange}
                      error={errors.pre_leased_property}
                      label={"Pre Leased Property"}
                      value={this.state.pre_leased_property}
                      data={["YES", "NO"]}
                    />
                  )}

                  {/*UMESH CODE START FROM HERE*/}

                  {parseInt(sub_category_id) === 5 && (
                    <PropertyCommonInput
                      name={"booking_amount"}
                      onChange={this.onChange}
                      error={errors.booking_amount}
                      label={"Booking amount"}
                      value={this.state.booking_amount}
                      placeholder={"Booking amount"}
                    />
                  )}

                  {parseInt(sub_category_id) === 5 && (
                    <PropertyCommonInput
                      name={"building_class"}
                      onChange={this.onChange}
                      error={errors.building_class}
                      label={"Building Class"}
                      value={this.state.building_class}
                      placeholder={"Building Class"}
                    />
                  )}

                  {parseInt(sub_category_id) === 5 && (
                    <PropertyCommonSelect
                      name={"store_room"}
                      onChange={this.onChange}
                      error={errors.store_room}
                      label={"Store room"}
                      value={this.state.store_room}
                      data={["YES", "NO"]}
                    />
                  )}

                  {/*UMESH CODE ENDS FROM HERE*/}

                  <div className="row mt-4 ">
                    <div className="col-md-7  col-sm-12 text-center mt-1 mx-auto">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-4 ">
                          <label>Upload Property Image</label>
                        </div>
                        <div className="col-md-8">
                          <div className="text-center">
                            <img
                              src={
                                this.state.uploadedImagePreview
                                  ? this.state.uploadedImagePreview
                                  : companyPlaceholder
                              }
                              alt="img"
                              className={"upload-property-img"}
                            />
                            {this.state.propertyImgLoader ? (
                              <div className="add-media">
                                <i
                                  className="fa fa-spinner fa-pulse"
                                  style={{
                                    color: "#fda94f",
                                    boxShadow: "none",
                                    fontSize: 25,
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                className="add-media btn btn-yellow"
                                data-toggle="modal"
                                data-target="#profilePictureUpload"
                              >
                                <i
                                  className="fa fa-paperclip mr-1"
                                  aria-hidden="true"
                                />
                                Upload Property Image
                              </div>
                            )}
                            {errors.uploadedImagePreview && (
                              <span className="text-danger">
                                <br />
                                {errors.uploadedImagePreview}
                              </span>
                            )}
                            <p className="font-weight-bold mt-3">
                              <span className="text-success">
                                *Note:- you can add multiple images of this
                                properties after listing..
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center align-items-center mt-5">
                    <div className="col-12">
                      <div className="text-center">
                        <hr />
                        <h6>Amenity</h6>
                        <small className="text-info">
                          Select Amenities which suits your property
                        </small>
                        <hr />
                        <br />
                        <p className="badge badge-success">
                          {this.state.amenities.length > 0 &&
                            `${this.state.amenities.length} Amenities Selected`}
                        </p>
                      </div>
                      <div className="amenities-container justify-content-center align-items-center">
                        {amenities &&
                          amenities.length > 0 &&
                          amenities.map((amenity, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                this.onSelectAmenity(amenity.amenity_id)
                              }
                              className={classnames("amenity", {
                                "active-amenity": this.state.amenities.filter(
                                  (p) =>
                                    parseInt(p) === parseInt(amenity.amenity_id)
                                ).length,
                              })}
                            >
                              <img src={amenity.image} alt={amenity.name} />
                              <span>{amenity.name}</span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 text-center">
                      {addPropertyLoader ? (
                        <button className="btn btn-yellow" type="button">
                          <img src={btnLoader} alt="login-loader" width={15} />
                        </button>
                      ) : (
                        <button className="btn btn-yellow" type="submit">
                          Save Property
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fragment>
        <Footer />
        <Fragment>
          <div
            className="modal fade"
            id="profilePictureUpload"
            role="dialog"
            data-backdrop="static"
            data-keyboard="false"
            aria-labelledby="profilePictureUploadTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Change Property Image
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <div className="row">
                    <div className="col-12 text-center">
                      {src ? (
                        <ReactCrop
                          src={src}
                          crop={crop}
                          ruleOfThirds
                          onImageLoaded={this.onImageLoaded}
                          onComplete={this.onCropComplete}
                          onChange={this.onCropChange}
                          className={"company-profile-preview-image"}
                        />
                      ) : (
                        <img
                          width={350}
                          src={
                            this.state.uploadedImagePreview
                              ? this.state.uploadedImagePreview
                              : companyPlaceholder
                          }
                          alt="Property Image"
                        />
                      )}
                    </div>
                  </div>
                  <hr />
                  <input
                    id={"property-img-selector"}
                    type="file"
                    accept="image/*"
                    onChange={this.onSelectFile}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    id={"close-profilePictureUpload"}
                    type="button"
                    className="btn btn-custom-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-yellow"
                    onClick={this.handlePropertyImgChange}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    categories: state.property.categories,
    subCategories: state.property.subCategories,
    getPropertySubCategoryLoader: state.property.getPropertySubCategoryLoader,
    units: state.property.units,
    amenities: state.property.amenities,
    getAmenitiesLoader: state.property.getAmenitiesLoader,
    addPropertyLoader: state.property.addPropertyLoader,
    country: state.property.country,
    states: state.property.state,
    city: state.property.city,
    getPropertyDetails: state.property.getPropertyDetails,
  };
};

export default connect(mapStateToProps, {
  actionGetCategories,
  actionGetSubCategories,
  actionGetUnits,
  actionGetAmenities,
  uploadPropertyImage,
  actionAddProperty,
  actionGetCountry,
  actionGetState,
  actionGetCity,
  actionRemoveStates,
  actionRemoveCities,
  actionGetPropertyDetails,
})(AddProperty);
