import React, {Component, Fragment} from 'react';
import connect from "react-redux/es/connect/connect";
import {
    actionAddFavourite,
    actionGetFavoriteProperty,
    actionGetPropertyByFilter,
    actionRemoveFavourite
} from "../../actions/propertyAction";
import PropertyListCard from "../property-listing/property-list-card";
import PropertyListLoaderCard from "../property-listing/property-list-loader-card";
import DataNotFound from "../section-components/DataNotFound";
import {actionGetAgent} from "../../actions/agentAction";
import {actionGetChatList, actionLiveSharePropertyUrl, actionSharePropertyUrl} from "../../actions/chatAction";
import btnLoader from "../../assets/img/button-loader.svg";
import BroadcastUserListLoaderCard from "../common/broadcast-user-list-loader-card";
import BroadcastModelChatUser from "../common/broadcast-model-chat-user";
import broadcastIcon from "../../assets/img/broadcast_icon.png";
import jwt_decode from "jwt-decode";

class FavoritePropertyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            chatPerson: '',
            chatFilter: '',
            broadcastSearchFilter: '',
            broadcast_members: [],
            broadcast_members_index: [],
            propertyId: '',
        }
    }
    componentDidMount(){



        window.addEventListener('scroll', () => {
            let scrollHeight = document.documentElement.scrollHeight;
            let scrollTop = document.documentElement.scrollTop;
            let clientHeight = document.documentElement.clientHeight;

            if ((scrollTop + clientHeight) > (scrollHeight - 300) && this.props.propertyData.length !== parseInt(this.props.propertyCount)) {
                this.setState({
                    offset: this.state.offset + 1,
                }, () => this.props.user && this.props.actionGetPropertyByFilter(`?loggedInUserId=${this.props.user.user_id}&isFavorite=${true}`, `&offset=${parseInt(this.state.offset)}`));
            }
        });
        this.setState({offset: 0});
        this.props.user && this.props.actionGetPropertyByFilter(`?loggedInUserId=${this.props.user.user_id}&isFavorite=${true}`, `&offset=${this.state.offset}`);
    }


    componentDidUpdate(prevProps, prevState) {
        (prevProps.user !== this.props.user) && this.props.actionGetPropertyByFilter(`?loggedInUserId=${this.props.user.user_id}&isFavorite=${true}`, `&offset=${parseInt(this.state.offset)}`);
        if (prevProps.user.user_id !== this.props.user.user_id) {
            this.props.actionGetChatList(this.props.user.user_id);
        }
        prevProps.chatAgent !== this.props.chatAgent && this.setState({chatPerson: this.props.chatAgent})


    }
    onChangeBroadcastFilter = (e) => this.setState({[e.target.name]: e.target.value});

    handleSelectBroadcastPerson = (personIndex) => {
        this.setState({broadcast_members_index: this.state.broadcast_members_index.find(m => parseInt(m) === parseInt(personIndex)) ? [...this.state.broadcast_members_index.filter(m => parseInt(personIndex) !== parseInt(m))] : [...this.state.broadcast_members_index, personIndex]});

    };

    onShareProperty = (e) => {
        e.preventDefault();
        // this.setState({broadcast_members:this.state.broadcast_members_index.map(val=>val-1)});

        const selectedUsers = this.state.broadcast_members_index && this.state.broadcast_members_index.map(userIndex => this.props.chatPersonList.find((person, i) => parseInt(userIndex-1) === i));
        // this.setState({broadcast_members: selectedUsers.map(val => val.user_id)});
        // this.props.actionSharePropertyUrl(`https://globalindiarealestate.com/property-detail/${this.state.propertyId}`);
        // if (this.state.broadcast_members) {
        //     this.ws.send(JSON.stringify({
        //         // action: this.state.chatPerson.chat_type === 'Agent' ? 'sendMessage' : 'sendBroadcastMessage',
        //         action: this.state.chatPerson.chat_type === 'Agent' ? 'sharedMessage' : 'shareBroadcastMessage',
        //         payload: {
        //             sender_id: this.props.user.user_id,
        //             receiver_id: this.state.broadcast_members,
        //             message: 'Share test property',
        //             property_id: this.state.propertyId,
        //             attachment_name: "",
        //             file_ext: "",
        //             mime_type: "",
        //             ip_address: ""
        //         },
        //         token: ''
        //     }));
        //
        // }



        this.setState({broadcast_members: selectedUsers.map(val => [val.user_id])});
        this.props.actionSharePropertyUrl(`https://globalindiarealestate.com/property-detail/${this.state.propertyId}`);

        setTimeout(() => {
            if (localStorage.jwtToken) {
                const decoded = jwt_decode(localStorage.jwtToken);
                const request = {
                    sender_id: parseInt(decoded.uid),
                    payload: selectedUsers.map(user => ({
                        user_id: user.user_id,
                        type: user.chat_type,
                        message: this.props.sharePropertyUrl,
                    }))
                };
                this.props.actionLiveSharePropertyUrl(request);
            }

        }, 600);


        this.setState({broadcast_members_index:[],
            broadcast_members:''})


        this.setState({broadcast_members_index:[],
            broadcast_members:''})

    };

    handleSelectPropertyCard = (propertyId) => {
        console.log(propertyId);
        this.setState({propertyId})
    };

    handleFavouriteSelect = (property_id, isWishlist) => {
        console.log(isWishlist);
        const user_id = this.props.user && this.props.user.user_id;
        if (this.props.isAuthenticated){

            if (isWishlist) {
                this.props.actionRemoveFavourite(user_id, property_id);
                this.setState({isWishlist: false})

            } else {
                this.props.actionAddFavourite(property_id, user_id);
                this.setState({isWishlist: true})
            }

        }
        else {
            window.location.href='/register';
        }




    };


    // myFunction=()=>{
    //     return setTimeout(()=>{
    //       return this.props.user.user_id
    //   });
    // };



    render() {
        const {favouritePropertyLoader, propertyData, getPropertyLoader, chatPersonList} = this.props;
        const {suggestedLocations, propertyCount, createBroadcastLoader} = this.props;
        let publicUrl = process.env.PUBLIC_URL + '/';

        const {chatPerson} = this.state;




        let filteredBroadcastPersonList = !this.state.broadcastSearchFilter ? chatPersonList : chatPersonList.filter(a => {
            return (a.first_name && a.first_name.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1) || (a.last_name && a.last_name.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1) || (a.email && a.email.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1);
        });

        const serachlStle = {
            position: 'absolute',
            right: '35px',
            top: '14px',

        };
        const serachiStle = {
            width: '100%',
            // height:'37px',
            lineHeight: '36px',
            padding: '1px 20px',
            fontSize: '15px',
            border: '1px solid #d4d4d4'

        };


        return (
            <Fragment>
                <div className="favorite-property-list-section">
                <div className="property-area pd-top-50 mt-2">
                    <div className="container">
                        <div className="row mt-5">
                            {getPropertyLoader && <div className="row">
                                <PropertyListLoaderCard/>
                                <PropertyListLoaderCard/>
                                <PropertyListLoaderCard/>
                                <PropertyListLoaderCard/>
                                <PropertyListLoaderCard/>
                                <PropertyListLoaderCard/>
                            </div>}

                            {(propertyData && propertyData.length > 0) && propertyData.map((item, i) =>
                                (item.isFavorite &&
                                <PropertyListCard

                                    imagealt={item.title}
                                    image={item.image}
                                    agentId={item.agent_id}
                                    auth={this.props.isAuthenticated}
                                    sub_category_name={item.sub_category_name}
                                    logo={item.agent_profile_pic}
                                    agentName={`${item.agent_first_name} ${item.agent_last_name}`}
                                    title={item.title}
                                    carpet_area={item.carpet_area}
                                    super_area={item.super_area}
                                    address={item.address}
                                    url={`/property-detail/${item.property_id}`}
                                    land_area={item.land_area}
                                    newerprice={item.price}
                                    beds={item.bedroom}
                                    bath={item.bathrooms}
                                    wash={item.washroom}
                                    area={item.super_area ? item.super_area : item.carpet_area}
                                    area_unit_name={item.area_unit_name ? item.area_unit_name : item.area_unit_name}
                                    key={i}
                                    index={i}
                                    currency={item.currency}
                                    img={publicUrl + item.features}
                                    propertyId={item.property_id && item.property_id}
                                    handleSelectPropertyCard={this.handleSelectPropertyCard}
                                    handleFavouriteSelect={this.handleFavouriteSelect}
                                    isWishlist={item.isFavorite}
                                    userAuth={localStorage.jwtToken?jwt_decode(localStorage.jwtToken).uid:null}

                                />))}


                            {(!getPropertyLoader && propertyData.length < 0) &&
                            <div className="text-center col-md-12"><DataNotFound
                                message={`Sorry! No Property Found.`}/></div>}


                        </div>
                    </div>
                </div>
                    <Fragment>
                        {/* Create Broadcast Modal */}

                        <div className="modal fade share-property-modal" id="createBroadcastModal" tabIndex={-1}
                             role="dialog"
                             aria-labelledby="createBroadcastModalLabel" aria-hidden="true" style={{marginTop: "2em"}}>
                            <div className="modal-dialog modal-dialog-centered" role="document">

                                <div className="modal-content" style={{zIndex: '99999999999'}}>

                                    <div className="modal-header ">
                                        <h5 className="modal-title w-100 text-center" id="createBroadcastModalLabel ">Share
                                            this property..</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true h3">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body pt-0">
                                        <Fragment>
                                            <div id="sidepanel" className="p-2">

                                                <div id="search" className="mb-2">
                                                    <label htmlFor="" style={serachlStle}><i className="fa fa-search"
                                                                                             aria-hidden="true"/></label>
                                                    <input type="text" style={serachiStle} name="broadcastSearchFilter"
                                                           onChange={this.onChangeBroadcastFilter}
                                                           value={this.state.broadcastSearchFilter}
                                                           placeholder="Search Friend"/>
                                                </div>


                                                {this.state.broadcast_members_index.length > 0 &&
                                                <div
                                                    className="d-flex align-items-center justify-content-between pl-2 pr-2">
                                                    {this.state.broadcast_members_index.length > 0 &&
                                                    <div>{this.state.broadcast_members_index.length} Person
                                                        Selected.</div>}
                                                    {createBroadcastLoader ?
                                                        <div className="broadcast-next-btn"><img src={btnLoader}
                                                                                                 alt="contact-loader"
                                                                                                 width={15}/>
                                                        </div>
                                                        :
                                                        <div className="btn btn-yellow h4" id="clg" data-dismiss="modal"
                                                             onClick={this.onShareProperty}><i
                                                            className="fa fa-share-alt mr-2"/>
                                                            Share
                                                        </div>
                                                        //
                                                        // <button className="btn btn-yellow" onClick={this.handlePropertyImgChange}>Save
                                                        // changes
                                                        // </button>
                                                    }
                                                </div>}

                                                <hr/>
                                                <div id="contacts" className="broadcast-model-contact">
                                                    <ul>

                                                        {(!filteredBroadcastPersonList.length) &&
                                                        <div className="">
                                                            <BroadcastUserListLoaderCard/>
                                                            <BroadcastUserListLoaderCard/>
                                                            <BroadcastUserListLoaderCard/>
                                                            <BroadcastUserListLoaderCard/>
                                                            <BroadcastUserListLoaderCard/>

                                                        </div>
                                                        }
                                                        {(filteredBroadcastPersonList && filteredBroadcastPersonList.length > 0) && filteredBroadcastPersonList.map((person, index) =>
                                                            <BroadcastModelChatUser
                                                                key={index}
                                                                handleSelectChatPerson={this.handleSelectBroadcastPerson}
                                                                name={`${person.first_name ? person.first_name : ""} ${person.last_name ? person.last_name : ""}`}

                                                                profile_pic={person.chat_type === "Agent" ? person.profile_pic : broadcastIcon}
                                                                lastMessage={person.last_message}
                                                                chatPerson={chatPerson}
                                                                isActive={this.state.broadcast_members_index.find(m => parseInt(index + 1) === parseInt(m))}
                                                                user_id={parseInt(index + 1)}/>)}


                                                    </ul>
                                                </div>
                                            </div>

                                        </Fragment>
                                    </div>
                                    <div className="modal-footer">

                                    </div>

                                </div>
                            </div>
                        </div>
                    </Fragment>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        agent: state.agent,
        categories: state.property.categories,
        subCategories: state.property.subCategories,
        getPropertyLoader: state.property.getPropertyLoader,
        favouritePropertyLoader: state.property.favouritePropertyLoader,
        units: state.property.units,
        propertyData: state.property.propertyData,
        propertyCount: state.property.propertyCount,
        suggestedLocations: state.auth.suggestedLocations,
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        chatPersonList: state.chat.chatPersonList,
        createBroadcastLoader: state.chat.createBroadcastLoader,
        sharePropertyUrl: state.chat.sharePropertyUrl,
    };
};

export default connect(mapStateToProps, {
    actionGetFavoriteProperty,
    actionGetPropertyByFilter,
    actionGetAgent,
    actionGetChatList,
    actionAddFavourite,
    actionRemoveFavourite,
    actionSharePropertyUrl,
    actionLiveSharePropertyUrl
})(FavoritePropertyList)

