import React from 'react';
import dataNotFound from '../../assets/img/data-not-found.svg';

const DataNotFound = ({message,img}) => {
    return (
        <div className='data-not-found-container'>
            <img src={img?img:dataNotFound} alt="Data Not Found"/>
            <h4>{message}</h4>
        </div>
    );
};

export default DataNotFound;