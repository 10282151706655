import {toast} from "react-toastify";

// TYPES: info, success, warn, error, dark
const showToast = (message, type = 'success') => {
    const config = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    if (type === 'info')
        toast.info(message, config);
    else if (type === 'success')
        toast.success(message, config);
    else if (type === 'warn')
        toast.warning(message, config);
    else if (type === 'error')
        toast.error(message, config);
    else if (type === 'dark')
        toast.dark(message, config);
    else
        toast(message, config);
};
export default showToast;