import React, { Component,Fragment } from 'react';
import sectiondata from '../../data/sections.json';
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";


class Ads extends Component {
    constructor(props) {
        super(props);

    }


    render() {


        let data = sectiondata.ads;

        const inlineStyle = {
            backgroundImage: 'url(/assets/img/bg/1.png)'
        };

    return (
        !this.props.isAuthenticated && <Fragment>
            <div className="call-to-action-area pd-top-70">
                <div className="container">
                    <div className="call-to-action" style={ inlineStyle }>
                        <div className="cta-content">
                            <h3 className="title">{ data.title }</h3>
                            <Link className="btn btn-white" to={ data.url }>{ data.btntxt }</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );


        }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,

});

export default connect(mapStateToProps)(Ads)
