import React from 'react';
import {Link} from "react-router-dom";
import defaultPartner from '../../assets/img/bg/3.png';

const PartnerCard = (props) => {

    return (
        <div className="client-slider item bg-white p-3 border-radius-10 mr-4 row justify-content-center align-items-center">
            <div className="thumb text-center">
                <img src={props.item.image} alt="client"/>
            </div>
        </div>
    );
};

export default PartnerCard;