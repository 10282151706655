import React, { Component } from "react";
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import classnames from "classnames";
import { connect } from "react-redux";
import { actionContactForm } from "../../actions/authAction";
import btnLoader from "../../assets/img/button-loader.svg";
import { CONTACT_US } from "../../actions/types";

class Contact extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
      agree: false,
      errors: {},
    };
  }

  onChange = (e) =>
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (
        this.state.name.length > 0 ||
        this.state.phone.length > 0 ||
        this.state.subject.length > 0 ||
        this.state.message.length > 0
      ) {
        this.setState({ errors: {} });
      }
    });
  onChangeEmail = (e) =>
    this.setState({ [e.target.name]: e.target.value.trim() }, () => {
      if (this.state.email.length > 0) {
        this.setState({ errors: {} });
      }
    });

  handleContactFormValidation = () => {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    const strchek = /^[A-Z a-z]+$/;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if (!fields.name.trim()) {
      formIsValid = false;
      errors.name = "Name must be Required!";
    } else if (!fields.name.trim().match(strchek)) {
      formIsValid = false;
      errors.name = "Name in must be Required!";
    }
    if (!fields.email) {
      formIsValid = false;
      errors.email = "Email must be Required!";
    } else if (!validEmailRegex.test(fields.email)) {
      formIsValid = false;
      errors.email = "Email must be  be  Valid!";
    }

    if (!fields.subject) {
      formIsValid = false;
      errors.subject = "Subject must be Required!";
    }
    if (!fields.message) {
      formIsValid = false;
      errors.message = "Massage must be Required!";
    }
    if (!fields.agree) {
      formIsValid = false;
      errors.agree =
        "Please agree to our receive email from Global Arizona Prime Properties Real Estate.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  onContactSubmit = (e) => {
    e.preventDefault();
    if (this.handleContactFormValidation()) {
      const { name, email, subject, message } = this.state;
      const contactFormRequest = {
        name,
        subject,

        email,
        message,
      };

      this.props.actionContactForm(contactFormRequest);
      this.setState({
        name: "",
        email: "",
        subject: "",
        message: "",
        errors: {},
      });
    }
  };

  render() {
    const { errors } = this.state;
    const { contactFromLoading } = this.props.auth;
    return (
      <div>
        <Navbar scenario={CONTACT_US} />
        <PageHeader headertitle="Contact" />
        <div className="contact-area pd-top-100 pd-bottom-65">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="contact-page-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3324.42450256965!2d-111.89067358440731!3d33.56832585052229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b7533dcc18a4f%3A0x8ac9e805fe084a0e!2s8989%20E%20V%C3%ADa%20Linda%20%23117A%2C%20Scottsdale%2C%20AZ%2085258%2C%20USA!5e0!3m2!1sen!2sin!4v1612329377859!5m2!1sen!2sin"
                    width="100%"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <form
                  className="contact-form-wrap contact-form-bg"
                  autoComplete="off"
                  noValidate={true}
                  onSubmit={this.onContactSubmit}
                >
                  <h4>Contact Now</h4>
                  <div className="rld-single-input">
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      onChange={this.onChange}
                      style={{ fontSize: "15px !important" }}
                      value={this.state.name}
                      id="rdl-mobbb"
                      className={classnames({
                        "is-invalid": errors.name,
                      })}
                    />
                    {errors.name && (
                      <span className="text-danger">{errors.name}</span>
                    )}
                  </div>
                  <div className="rld-single-input">
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      id="rdl-mobbb"
                      onChange={this.onChangeEmail}
                      value={this.state.email}
                      className={classnames({
                        "is-invalid": errors.email,
                      })}
                    />
                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                  </div>

                  <div className="d-flex justify-content-between flex-wrap ">
                    <label>
                      <input
                        type="checkbox"
                        className="mr-1"
                        id="agree"
                        value={this.state.agree}
                        onChange={(e) =>
                          this.setState({ agree: e.target.checked })
                        }
                      />
                      I agree to receive email from Global Arizona Prime
                      Properties Real Estate.
                    </label>
                  </div>
                  {errors.agree && (
                    <span className="text-danger">{errors.agree}</span>
                  )}

                  <div className="rld-single-input">
                    <input
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      id="rdl-mobbb"
                      value={this.state.subject}
                      onChange={this.onChange}
                      className={classnames({
                        "is-invalid": errors.subject,
                      })}
                    />
                    {errors.subject && (
                      <span className="text-danger">{errors.subject}</span>
                    )}
                  </div>
                  <div className="rld-single-input">
                    <textarea
                      rows={10}
                      placeholder="Message"
                      // defaultValue={""}
                      name="message"
                      value={this.state.message}
                      onChange={this.onChange}
                      className={classnames({
                        "is-invalid": errors.message,
                      })}
                    />
                    {errors.message && (
                      <span className="text-danger">{errors.message}</span>
                    )}
                  </div>
                  <div className="btn-wrap text-center">
                    {/*<button className="btn btn-yellow" type="submit">Submit</button>?*/}
                    {contactFromLoading ? (
                      <button className="btn btn-yellow" type="button">
                        <img src={btnLoader} alt="contact-loader" width={15} />
                      </button>
                    ) : (
                      <button className="btn btn-yellow" type="submit">
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="row pd-top-92">
              <div className="col-xl-4 col-md-6 col-sm-12">
                <div className="single-contact-info">
                  <p className="font-weight-bold">
                    <i className="fa fa-phone" />
                    Connect with us:
                  </p>
                  Toll-free:-
                  <a
                    href="tel:+1 1800-891-8535"
                    style={{ cursor: "pointer", fontSize: "16px" }}
                  >
                    {" "}
                    1800-891-8535
                  </a>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-sm-12">
                <div className="single-contact-info">
                  <p className="font-weight-bold">
                    <i className="fa fa-envelope" />
                    Have any Question?
                  </p>
                  <a href="mailto:Jawahar@DodaniGroup.com">
                    Jawahar@DodaniGroup.com
                  </a>{" "}
                  <br />
                  <a href="mailto:Support@GlobalIndiaRealEstate.com">
                    Support@GlobalIndiaRealEstate.com
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12">
                <div className="single-contact-info">
                  <p className="font-weight-bold">
                    <i className="fa fa-map-marker" />
                    Address
                  </p>
                  <p>
                    {" "}
                    8989 East Via Linda, <br />
                    #117A Scottsdale 85250 Arizona{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { actionContactForm })(Contact);
