import axios from 'axios';
import * as actionTypes from '../actions/types';
import {BASE_URL} from "../web-config";
import showToast from "../utils/showToast";


export const actionGetChatList = (user_id) => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_CHAT_LIST_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-chat-list?user_id=${user_id}`);
        dispatch({type: actionTypes.GET_CHAT_LIST_LOADER, payload: false});
        parseInt(result.data.status) === 200 && dispatch({
            type: actionTypes.GET_CHAT_LIST, payload: result.data.data
        });
        //showToast(result.data.message, parseInt(result.data.status) === 200 ? 'success' : 'error');
    } catch (error) {
        dispatch({type: actionTypes.GET_CHAT_LIST_LOADER, payload: false});
        //showToast(error.message, 'error');
    }
};

export const actionGetChatAgent = (user_id, type) => async (dispatch, getState) => {

    console.log()
    dispatch({type: actionTypes.GET_CHAT_AGENT_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-chat-agent?user_id=${user_id}&type=${type}`);
        dispatch({type: actionTypes.GET_CHAT_AGENT_LOADER, payload: false});
        parseInt(result.data.status) === 200 && dispatch({
            type: actionTypes.GET_CHAT_AGENT, payload: result.data.data
        });
        parseInt(result.data.status) === 200 && dispatch(actionMessageHistory({
            sender_id: type === 'Agent' ? getState().auth.user.user_id : user_id,
            receiver_id: user_id,
            type
        }))
    } catch (error) {
        dispatch({type: actionTypes.GET_CHAT_AGENT_LOADER, payload: false});
    }
};

export const actionMessageHistory = (request) => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_MESSAGE_HISTORY_LOADER, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/message-history`, JSON.stringify(request));
        dispatch({type: actionTypes.GET_MESSAGE_HISTORY_LOADER, payload: false});
        parseInt(result.data.status) === 200 && dispatch({
            type: actionTypes.GET_MESSAGE_HISTORY, payload: result.data.data
        });
        //showToast(result.data.message, parseInt(result.data.status) === 200 ? 'success' : 'error');
    } catch (error) {
        dispatch({type: actionTypes.GET_MESSAGE_HISTORY_LOADER, payload: false});
        //showToast(error.message, 'error');
    }
};

export const actionAppendMessage = (data, scrollToBottom) => async (dispatch, getState) => {
    dispatch({type: actionTypes.APPEND_MESSAGE, payload: data});
    scrollToBottom && scrollToBottom();
};


//CREATE BROADCAST

export const actionCreateBroadcast = (broadcastData) => async (dispatch) => {
    dispatch({type: actionTypes.CREATE_BROADCAST_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/create-update-broadcast`, JSON.stringify(broadcastData));
        dispatch({type: actionTypes.CREATE_BROADCAST_LOADING, payload: false});
        showToast(result.data.message, parseInt(result.data.status) === 200 ? 'success' : 'error');
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.CREATE_BROADCAST_SUCCESS, payload: result.data.data});
        }
        else
            dispatch({type: actionTypes.CREATE_BROADCAST_FAILED, payload: result.data.data});
    } catch (error) {
        showToast(error.message, 'error');
    }
};

//UPDATE BROADCAST
export const actionUpdateBroadcast = (broadcastData) => async (dispatch) => {
    console.log(broadcastData)
    dispatch({type: actionTypes.UPDATE_BROADCAST_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/create-update-broadcast`, JSON.stringify(broadcastData));
        dispatch({type: actionTypes.UPDATE_BROADCAST_LOADING, payload: false});
        showToast(result.data.message, parseInt(result.data.status) === 200 ? 'success' : 'error');
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.UPDATE_BROADCAST_SUCCESS, payload: result.data.data});
        }
        else
            dispatch({type: actionTypes.UPDATE_BROADCAST_FAILED, payload: result.data.data});
    } catch (error) {
        showToast(error.message, 'error');
    }
};

//DELETE BROADCAST
export const actionDeleteBroadcast = (broadcastId) => async (dispatch, getState) => {

    dispatch({type: actionTypes.DELETE_BROADCAST_LOADING, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/delete-broadcast?broadcast_id=${broadcastId}`);


        if (parseInt(result.status) === 200) {
            dispatch({type: actionTypes.DELETE_BROADCAST_LOADING, payload: false});
            dispatch({type: actionTypes.DELETE_BROADCAST_SUCCESS, payload: parseInt(broadcastId)});
            showToast(result.message, 'success');
        } else {
            showToast(result.message, 'Failed');
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};


//SHARE PROPERTY URL
export const actionSharePropertyUrl = (propertyUrl) => async (dispatch, getState) => {

    dispatch({type: actionTypes.SHARE_PROPERTY_URL_LOADING, payload: true});
    try {

        dispatch({type: actionTypes.SHARE_PROPERTY_URL, payload: propertyUrl});
        dispatch({type: actionTypes.SHARE_PROPERTY_URL_LOADING, payload: false});

    }
    catch (error) {
        showToast(error.message, 'error');
    }
};


//LIVE SHARE PROPERTY URL
export const actionLiveSharePropertyUrl = (propertyData) => async (dispatch) => {

    dispatch({type: actionTypes.LIVE_SHARE_PROPERTY_URL_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/share-property`, JSON.stringify(propertyData));
        dispatch({type: actionTypes.LIVE_SHARE_PROPERTY_URL_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.LIVE_SHARE_PROPERTY_URL, payload: []});
            showToast(result.data.message, 'success');
        }

    } catch (error) {
        showToast(error.message, 'error');
    }
};




