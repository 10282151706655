import React, {Component, Fragment} from 'react';
import TestimonialsListingCard from "./testimonials-listing-card";
import connect from "react-redux/es/connect/connect";
import {
    actionAddTestimonial,
    actionDeleteTestimonials,
    actionGetTestimonials, actionUpdateTestimonial,
    uploadTestimonialImage
} from "../../actions/agentAction";
import jwt_decode from "jwt-decode";
import ReactCrop from "react-image-crop";
import defaultAvatar from "../../assets/img/nophoto.png";
import classnames from "classnames";
import TestimonialsListingCardLoder from "./testimonials-listing-card_loder";
import DataNotFound from "../section-components/DataNotFound";


class TestimonialsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agent_id: '',
            title: '',
            description: '',
            testimonial_id:'',
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1,

            },
            testimonialImgLoader: false,
            uploadedImagePreview: '',
            errors: {}
        }

    }

    componentDidMount() {
        if (localStorage.jwtToken) {
            const decoded = jwt_decode(localStorage.jwtToken);
            this.props.actionGetTestimonials(decoded.uid)
        }
    }

    componentDidUpdate(prevProps, prevState) {


    }

    /*  Image Crop */
    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({src: reader.result})
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };


    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {

        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
                this.setState({blob}) //added by SO huy nguyen
            }, 'image/jpeg');
        });
    }

    handleTestimonialImg = async (e) => {


        const config = {responseType: 'blob'};

        var file = new File([this.state.croppedImageUrl], "testimonial_image.png", {
            type: "image/png",
            lastModified: new Date()
        });
        let formData = new FormData();

        formData.append('testimonial_image', this.state.blob);

        this.setState({testimonialImgLoader: true},);
        try {
            await this.props.uploadTestimonialImage(formData, this.setPropertyImagePreview);
            this.setState({
                testimonialImgLoader: false,
                src: null,
                blob: ''
            }, () => document.getElementById('property-img-selector').value = '');
        }
        catch (error) {
            this.setState({testimonialImgLoader: false});
        }

    };
    setPropertyImagePreview = uploadedImagePreview => this.setState({uploadedImagePreview});

    onChange = e => this.setState({[e.target.name]: e.target.value});


    actionUpdateTestimonial =(testimonial_id)=>{
        console.log(testimonial_id);
        const updata =this.props.testimonialsData && this.props.testimonialsData .find(data=>data.testimonial_id===testimonial_id);
        console.log(updata);
        this.setState({
            agent_id:updata.agent_id,
            title:updata.title,
            description:updata.description,
            testimonial_id:updata.testimonial_id,
            uploadedImagePreview:updata.image


        });
        // document.getElementById('addBtn').click();



    };

    actionDeleteTestimonial = (testimonial_id, agent_id) => {


        console.log(`testimonial_id=${testimonial_id} ## agent_id=${agent_id}`)
        this.props.actionDeleteTestimonials(testimonial_id);


    };


    onTestimonialSubmit = () => {

        if (this.handleValidation()) {
            const agent_id = jwt_decode(localStorage.jwtToken).uid;
            const image = this.state.uploadedImagePreview;
            const {title, description} = this.state;
            const testimonialData = {
                agent_id,
                title,
                description,
                image: image.split('/')[4]
            };

            this.props.actionAddTestimonial(testimonialData);
            document.getElementById("btn-main").click();
            this.setState({
                agent_id: '',
                title: '',
                description: '',
                uploadedImagePreview: ''

            })
        }

    };

    onUpdateTestimonialSubmit=()=>{

        if (this.handleValidation()) {
            // const agent_id = jwt_decode(localStorage.jwtToken).uid;
            const image = this.state.uploadedImagePreview;
            const {testimonial_id,agent_id,title, description} = this.state;
            const testimonialData = {
                testimonial_id,
                agent_id,
                title,
                description,
                image: image.split('/')[4]
            };
console.log(testimonialData);
            this.props.actionUpdateTestimonial(testimonialData);
            document.getElementById("btn-main").click();
            this.setState({
                testimonial_id:'',
                agent_id: '',
                title: '',
                description: '',
                uploadedImagePreview: ''

            })
        }

    };


    handleValidation = () => {

        let fields = this.state;
        let errors = {};
        let formIsValid = true;

        if (!fields.title.trim()) {
            formIsValid = false;
            errors.title = "Please Enter Title...!";
        }
        if (!fields.description.trim()) {
            formIsValid = false;
            errors.description = "Please Enter Description...!";
        }
        this.setState({errors: errors}, () => {
            const ID = Object.keys(errors)[0];
            document.getElementById(ID) && document.getElementById(ID).scrollIntoView();
        });
        return formIsValid;

    };

    render() {

        const {testimonialsData, TestimonialsLoader} = this.props;
        const {errors, src, crop, uploadedImagePreview, blob} = this.state;
        console.log(blob);
        console.log(uploadedImagePreview);
        return (
            <Fragment>
                <div className="testimonials-section">
                    <div className="container mt-5">
                        <div className="propety-img-section">
                            <div className="text-center my-5">

                                <a data-toggle="modal" href="#myModal" className="btn btn-yellow" id="addBtn" onClick={()=>{
                                    this.setState({
                                        agent_id: '',
                                        title: '',
                                        description: '',
                                        uploadedImagePreview: ''
                                    });
                                }}>Add Testimonial</a>
                            </div>

                            <div className="row mt-5">

                                {(testimonialsData && testimonialsData.length > 0) && testimonialsData.map((item, i) =>
                                    <TestimonialsListingCard
                                        key={i}
                                        agentId={item.agent_id}
                                        img={item.image}
                                        title={item.title}
                                        description={item.description}
                                        id={item.testimonial_id}
                                        actionDeleteTestimonial={this.actionDeleteTestimonial}
                                        actionUpdateTestimonial={this.actionUpdateTestimonial}
                                    />
                                )}


                                {(!TestimonialsLoader && testimonialsData.length < 1) &&
                                <div className="text-center col-md-12"><DataNotFound
                                    message={`Sorry! No Data Found.`}/></div>}

                                {(TestimonialsLoader) && <div className={'row mt-5'}>
                                    <TestimonialsListingCardLoder/>
                                    <TestimonialsListingCardLoder/>
                                    <TestimonialsListingCardLoder/>
                                    <TestimonialsListingCardLoder/>
                                    <TestimonialsListingCardLoder/>
                                    <TestimonialsListingCardLoder/>
                                </div>

                                }

                            </div>

                        </div>
                    </div>
                    <Fragment>

                        <div className="modal  mt-5" id="myModal" data-backdrop="static" data-keyboard="false"
                             style={{zIndex: '999999'}}>
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">{this.state.agent_id ?'Update Testimonial':'Add Testimonial'}
                                            </h4>
                                        <button type="button" className="close" data-dismiss="modal"
                                                aria-hidden="true">×
                                        </button>
                                    </div>
                                    <div className="container"></div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="rld-single-input my-2">
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        value={this.state.title}
                                                        onChange={this.onChange}
                                                        className={classnames({
                                                            'is-invalid': errors.title
                                                        })}
                                                        placeholder="Title"/>
                                                    {errors.title &&
                                                    <span className='text-danger'>{errors.title}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="rld-single-input my-2  ">
                            <textarea
                                rows={3}
                                name="description"
                                id="description"
                                value={this.state.description}
                                onChange={this.onChange}
                                className={classnames({
                                    'is-invalid': errors.description
                                })}
                                placeholder="Description"/>
                                                    {errors.description &&
                                                    <span className='text-danger'>{errors.description}</span>}
                                                </div>
                                            </div>

                                            <div className="col-12 ">
                                                <div className="mx-auto upload-testimonial-img-section"><img
                                                    src={this.state.uploadedImagePreview ? this.state.uploadedImagePreview : defaultAvatar}
                                                    alt="img" className={'upload-property-img'}/>
                                                    {this.state.testimonialImgLoader ?
                                                        <div className="add-media">
                                                            <i className="fa fa-spinner fa-pulse"
                                                               style={{
                                                                   color: '#fda94f',
                                                                   boxShadow: 'none',
                                                                   fontSize: 25
                                                               }}/>
                                                        </div> :
                                                        <a data-toggle="modal" href="#myModal2"
                                                           className="add-media btn btn-yellow"> <i
                                                            className="fa fa-paperclip mr-1" aria-hidden="true"/>Upload
                                                            Property Image</a>
                                                    }
                                                    {errors.uploadedImagePreview &&
                                                    <span
                                                        className='text-danger'><br/>{errors.uploadedImagePreview}</span>}
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <a href="#" data-dismiss="modal" className="btn" id="btn-main">Close</a>
                                        <button className="btn btn-yellow" onClick={this.state.agent_id? this.onUpdateTestimonialSubmit :this.onTestimonialSubmit}>Save
                                            changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal" id="myModal2" data-backdrop="static"
                             style={{zIndex: '99999999', margin: '50px'}}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Upload Testimonial Image</h4>
                                        <button type="button" className="close" data-dismiss="modal"
                                                aria-hidden="true">×
                                        </button>
                                    </div>
                                    <div className="container"></div>
                                    <div className="modal-body text-center">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                {src ? (
                                                    <ReactCrop
                                                        src={src}
                                                        crop={crop}
                                                        ruleOfThirds
                                                        onImageLoaded={this.onImageLoaded}
                                                        onComplete={this.onCropComplete}
                                                        onChange={this.onCropChange}
                                                        className={'company-profile-preview-image'}
                                                    />
                                                ) : <img width={350}
                                                         src={this.state.uploadedImagePreview ? this.state.uploadedImagePreview : defaultAvatar}
                                                         alt="Property Image"/>}
                                            </div>
                                        </div>
                                        <hr/>
                                        <input id={'property-img-selector'} type="file" accept="image/*"
                                               onChange={this.onSelectFile}/>

                                    </div>
                                    <div className="modal-footer">
                                        <a href="#" data-dismiss="modal" className="btn" id="sec-btn">Close</a>

                                        <button className="btn btn-yellow" onClick={this.handleTestimonialImg}
                                                data-dismiss="modal">Save
                                            changes
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </Fragment>
                </div>


            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        testimonialsData: state.agent.testimonialsData,
        TestimonialsLoader: state.agent.TestimonialsLoader,

    };

};

export default connect(mapStateToProps, {
    actionGetTestimonials,
    uploadTestimonialImage,
    actionAddTestimonial,
    actionDeleteTestimonials,
    actionUpdateTestimonial
})(TestimonialsList)
