import React from 'react';
import defaultAvatar from "../../assets/img/nophoto.png";
import Linkify from 'react-linkify';

const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
    </a>
);
const ChatMessage = (props) => {
    return (
        <li className={props.isSend ? 'sent' : 'replies'}>
            <img src={props.profile_pic ? props.profile_pic : defaultAvatar} alt=""/>
            <p><Linkify componentDecorator={componentDecorator}>{props.message}</Linkify></p>
        </li>
    );
};
ChatMessage.defaultProps = {
    isSend: true
};
export default ChatMessage;