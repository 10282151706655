import React from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import ForgotPasswordCard from "./forgot-password";

const ForgotPassword = () => {
    return (
        <div>
            <Navbar/>
            <PageHeader headertitle={"Forgot password"}/>
            <ForgotPasswordCard />
            <Footer/>
        </div>
    );
};

export default ForgotPassword;