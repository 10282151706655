import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {Dropdown} from 'react-bootstrap';
import {Item} from 'react-bootstrap';
import {Menu} from 'react-bootstrap';


import swal from "sweetalert";

const TestimonialsListingCard = ({key, img, title, description, id, agentId, actionDeleteTestimonial, actionUpdateTestimonial}) => {


    const styl={
        // border: 1px solid #333333;
    // width: '200px',
    height: '90px',
    overflowY: 'auto'
    };
    return (
        <Fragment key={key}>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="single-feature">
                    <div className="card rounded">

                        <Dropdown>
                            <Dropdown.Toggle className='delete-property' variant="light" id="dropdown-button-drop-left">
                                <i className="fa fa-ellipsis-v"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={'m-0'} style={{transform: 'translate(84.1111px, 41.8889px)'}}>
                                <Dropdown.Item><a href="#myModal" data-toggle="modal"
                                                  onClick={() => actionUpdateTestimonial(id)}><i
                                    className="fa fa-pencil mr-1"/>Update</a></Dropdown.Item>
                                <Dropdown.Item><Link to={"#exampleModalCenter"} data-toggle="modal"
                                                     data-target="#exampleModalCenter" className='' onClick={() => {
                                    swal({
                                        title: 'Are you sure you want to delete this Testimonial?',
                                        text: "Once deleted, you will not be able to recover this Testimonial!",
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                    })
                                        .then((willDelete) => {
                                            if (willDelete) {
                                                swal("Poof! Testimonial has been deleted!", {
                                                    icon: "success",

                                                });
                                                actionDeleteTestimonial(id, agentId)
                                            } else {
                                                swal("Your Testimonial is safe!");
                                            }
                                        });
                                    // window.confirm('Are you sure you want to delete this Property?')&&)

                                }
                                }><i className="fa fa-trash-o mr-1"/>Delete</Link></Dropdown.Item>
                            </Dropdown.Menu>

                        </Dropdown>
                        <div className="card-profile">
                            <img className="card-img-top" src={`${img}`} alt="Card image cap"/>
                        </div>
                        <div className="card-body">
                            <h4 className="card-title">{title}</h4>
                            <p className="card-text" style={styl}>{description}</p>
                        </div>
                        <div className="card-footer">
                            {/*<small className="text-muted">Last updated 3 mins ago</small>*/}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default TestimonialsListingCard;