import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import connect from "react-redux/es/connect/connect";
import {actionGetTeamMembers} from "../../actions/authAction";
import Spinner from "./Spinner";
import HomePageAgentListLoaderCard from "../agent-list/home-page-agent-list-loader-card";
import TeamLoaderCard from "./team-loader-card";

class Team extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.actionGetTeamMembers();
    }
    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'
        let data = this.props.teams;
        const {teams_loader}= this.props;


        return <div className="team-area bg-gray mg-top-70 pd-top-90 pd-bottom-70">
            <div className="container">
                <div className="section-title text-center">
                    <h2 className="title"> Team</h2>
                </div>
                <div className="row">

                    {teams_loader && <div className="row">
                        <TeamLoaderCard />
                        <TeamLoaderCard/>
                        <TeamLoaderCard/>
                        <TeamLoaderCard/>

                    </div>}

                    {/*{teams_loader && <div className="text-center col-md-12"><Spinner width={120}/></div>}*/}
                    {data.map((item, i) =>
                        <div key={i} className="col-lg-3 col-sm-6">
                            <div className="single-team">
                                <div className="thumb" >
                                    {/*width={'255px'} height={'277px'}*/}
                                    <img src={item.image}  alt="team"/>
                                </div>
                                <div className="team-details">
                                    <h4>{item.name}</h4>
                                    <span>{item.designation}</span> <br/>
                                    <span>{item.description}</span>
                                    <ul>

                                        <li><a href={item.facebook_link} target="_blank"><i className={"fa fa-facebook"}/></a></li>
                                        <li><a href={item.instagram_link} target="_blank"><i className={"fa fa-instagram"}/></a></li>
                                        <li><a href={item.twitter_link} target="_blank"><i className={"fa fa-twitter"}/></a></li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>

    }
}

const mapStateToProps = state => {
    return {
        teams: state.auth.teams,
        teams_loader: state.auth.teams_loader,
    };
};
export default connect(mapStateToProps, {
    actionGetTeamMembers,
})(Team)
