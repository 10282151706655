import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import { Link } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {
  actionGetPropertyByFilter,
  actionGetUnits,
} from "../../actions/propertyAction";

class PropertiesByCities extends Component {
  render() {
    const { propertyData, getPropertyLoader } = this.props;

    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.propertiesbycities;

    const inlineStyle = {
      backgroundImage: "url(" + publicUrl + "/assets/img/bg/2.png)",
    };

    console.log(data.items.map((i) => i.class));
    console.log(data.items.map((i) => i.url));
    return (
      <div className="city-intro-area pd-bottom-70" style={inlineStyle}>
        {/* city area start */}
        <div className="city-area pd-top-92">
          <div className="container">
            <div className="section-title text-center">
              <h2 className="title">{data.title}</h2>
            </div>
            <div className="city-filter-area row">
              <div className="city-sizer col-1" />

              {data.items.map((item, i) => (
                <div key={i} className={"rld-city-item " + item.class}>
                  <div className={"single-city " + item.sectionclass}>
                    <div className="sc-overlay" />
                    <div className="thumb">
                      <img src={publicUrl + item.image} alt={imagealt} />
                    </div>
                    <div className="details">
                      <h5 className="title">
                        <Link
                          to={`/property-listing?location=${encodeURI(
                            item.city
                          )}`}
                        >
                          {item.city}
                        </Link>
                      </h5>
                      <p style={{ opacity: 0 }}>{item.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-right">
        {/* <button type="button" class="btn btn-link" style={{color:'black'}}>View More</button> */}
        <Link to="/cities" style={{fontSize: '20px'}}>View More Cities</Link>
            </div>

          </div>
        </div>
        {/* city area end */}
        {/* intro area start */}
        <div className="intro-area pd-top-70">
          <div className="container">
            <div className="row">
              {data.intro.map((item, i) => (
                <div key={i} className="col-md col-sm-6-6">
                  <a
                    href={item.url}
                    target="_blank”"
                    className={"single-intro-media media " + item.class}
                  >
                    <div className="media-left">
                      <img src={publicUrl + item.icon} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <h4>{item.title}</h4>
                      <p>{item.content}</p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* intro area start */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getPropertyLoader: state.property.getPropertyLoader,
    units: state.property.units,
    propertyData: state.property.propertyData,
  };
};

export default connect(mapStateToProps, {
  actionGetUnits,
  actionGetPropertyByFilter,
})(PropertiesByCities);
