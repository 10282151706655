import React, {Component} from 'react';
import AgentCard from "../agent-list/AgentCard";
import {actionGetAgents} from '../../actions/agentAction'
import connect from "react-redux/es/connect/connect";
import classnames from "classnames";
import DataNotFound from "./DataNotFound";
import InfiniteScroll from "react-infinite-scroll-component";

import Spinner from "./Spinner";
import AgentListLoaderCard from "../agent-list/agent-list-loader-card";

class AgentList extends Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        this.state = {
            q: '',
            offset: 0,
            errors: {},


        }
    }

    onChange = (e) => this.setState({[e.target.name]: e.target.value}, () => {
        if (this.state.q.length < 1) {
            this.setState({offset: 0});
            this.props.actionGetAgents(this.state.q, `offset=${this.state.offset}`);
        }

    });

    handleSearchValidation = () => {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        if (!fields.q) {
            formIsValid = false;
            errors.q = "Please type something to search related data!";
        }

        this.setState({errors: errors});
        return formIsValid;
    };

    handleSearchSubmit = (e) => {
        console.log('handleSearchSubmit called');
        e.preventDefault();
        if (this.handleSearchValidation()) {
            this.setState({offset: 0});
            this.props.actionGetAgents(this.state.q, `offset=${this.state.offset}`);

        }

    };


    componentDidMount() {
        window.addEventListener('scroll', () => {
            let scrollHeight = document.documentElement.scrollHeight;
            let scrollTop = document.documentElement.scrollTop;
            let clientHeight = document.documentElement.clientHeight;

            // if ((scrollTop + clientHeight) > (scrollHeight - 300) && !this.props.agent.agentLoader && this.props.agent.agents.length !== parseInt(this.props.agent.agentsCount)) {
            //     this.setState({offset: this.state.offset + 1}, () => this.props.actionGetAgents(this.state.q, `offset=${this.state.offset}`));
            // }
        });
        this.setState({offset: 0});
        this.props.actionGetAgents(this.state.q, `offset=${this.state.offset}`);

    }

    fetchMoreData = () => this.setState({offset: this.state.offset + 1}, () => this.props.actionGetAgents(this.state.q, `offset=${this.state.offset}`));

    render() {
        const {agents} = this.props.agent;
        const {agentsCount} = this.props.agent;
        const {agentLoader} = this.props;
        const {errors} = this.state;

        console.log(agentLoader);
        const renderAgentList = (agents && agents.length > 0) && agents.map((item, i) => <AgentCard
            key={i}
            index={i}
            userId={item.user_id}
            address={item.street_address}
            imagealt={`${item.first_name} ${item.last_name}`}
            mobile={item.mobile}
            email={item.email}
            name={`${item.first_name} ${item.last_name}`}
            profilePic={item.profile_pic}
            user_relation={item.user_relation}
            user_relation_object={item.user_relation_object}
            url={`/agent-profile/${item.user_id}`}/>);

        return <div className="user-list-area pd-top-100 pd-bottom-70">
            <div className="container">
                {/*<form className="form-inline col-xl-8 col-lg-8 col-md-10 col-sm-12 mt-0">*/}
                {/*<div className="form-group mx-sm-3">*/}
                {/*<input type="text" className="form-control" id="q" name={'q'}  value={this.state.q} onChange={this.onChange} placeholder="Search By  Agent Name or Agent Email"    />*/}
                {/*</div>*/}

                {/*<button type="submit" className="btn btn-yellow"> Search </button>*/}
                {/*</form>*/}

                <form autoComplete="off" onSubmit={this.handleSearchSubmit}>
                    <div className="form-row pd-bottom-70">
                        <div className="col-8 com-sm-8">
                            <div className="widget-sidebar-item-wrap rld-single-input left-icon ">
                                <input type="text" className="form-control rld-single-input left-icon" id="q" name={'q'}
                                       value={this.state.q} onChange={this.onChange}
                                       placeholder="Search By  Agent Name or Agent Email"/>
                            </div>
                        </div>
                        <div className="col-4 responsive-btn1">
                            <button type="submit" className="btn btn-yellow"> Search</button>
                        </div>
                        <div className="col-4 responsive-btn" style={{display:'none'}}>
                            <button type="submit" className="btn btn-yellow"><i className="fa fa-search"
                                                                                aria-hidden="true"/></button>
                        </div>

                    </div>
                </form>

                {/* <div className="text-right mb-2">
                    <h6 className="filter-title mt-3 mb-lg-0">{agentsCount?agentsCount:0} Agents</h6>
                </div> */}
                {/*<div className="row justify-content-center">*/}
                {/*<div className="col-xl-8 col-lg-8 col-md-10 col-sm-12 mt-0">*/}
                {/*<form autoComplete="off"*/}
                {/*onSubmit={this.handleSearchSubmit}>*/}
                {/*<div className="widget-sidebar-item-wrap rld-single-input left-icon pd-bottom-70 form-control">*/}
                {/*<input type="text"*/}
                {/*name={'q'}*/}
                {/*placeholder="Search By  Agent Name or Agent Email"*/}
                {/*value={this.state.q}*/}
                {/*onChange={this.onChange}*/}
                {/*id="rdl-mobb"*/}
                {/*className={classnames('form-control br-tl-3 br-bl-3', {*/}
                {/*'empty-input-error': errors.q*/}
                {/*})}*/}
                {/*/>*/}

                {/*<button type="submit" className="btn btn-yellow"> Search </button>*/}

                {/*</div>*/}

                {/*</form>*/}

                {/*</div>*/}
                {/*<div className="btn-wrap text-center">*/}

                {/*</div>*/}
                {/*<div className="col-md-3 col-sm-4 text-right">*/}
                {/*<h6 className="filter-title mt-3 mb-lg-0">{agentsCount?agentsCount:0} Agents</h6>*/}
                {/*</div>*/}
                {/*</div>*/}

                {/*{(agentLoader && !agents) && <div className="row">*/}
                {/*<div className="text-center col-md-12"><Spinner width={150}/></div>*/}
                {/*</div>}*/}
                <div className="row">
                    {renderAgentList}
                </div>

                {/*{(agentLoader) && <div className="row">*/}
                    {/*<div className="text-center col-md-12"><Spinner width={150}/></div>*/}
                {/*</div>}*/}
                {(agentLoader) && <div className="row">
                    <AgentListLoaderCard/>
                    <AgentListLoaderCard/>
                    <AgentListLoaderCard/>
                    <AgentListLoaderCard/>
                    <AgentListLoaderCard/>
                    <AgentListLoaderCard/>
                </div>}


                {(!agentLoader && agents.length < 1) &&
                <div className="text-center col-md-12"><DataNotFound
                    message={`Sorry! No Property Found.`}/></div>}


                {/*{(agentLoader && this.state.q ) && <div className="row">*/}
                {/*<div className="text-center col-md-12"><Spinner width={150}/></div>*/}
                {/*</div>}*/}
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        agent: state.agent,
        agentLoader: state.agent.agentLoader,
    };
};

export default connect(mapStateToProps, {actionGetAgents})(AgentList);

// export default UserList