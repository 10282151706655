import React from 'react';
import {Link} from "react-router-dom";
import classnames from "classnames";
import {AGENT_FRIEND_LIST, AGENT_PRIVATE_CHAT, AGENT_PROFILE, AGENT_PROPERTY_LIST} from "../../../actions/types";
import connect from "react-redux/es/connect/connect";

const AgentProfileDropdownMenu = (props) => {
    const {user_relation} =props.agent.agentData;

    return (
        <div className="agent-profile-menu pt-2 pb-4">
            <div className="main-search-area pt-5 pb-1">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="banner-search-wrap">
                            <ul className="nav nav-tabs rld-banner-tab p-3"
                                >
                                <li className="nav-item ">
                                    <Link
                                        className={classnames('nav-link', {
                                            'active': props.scenario === AGENT_PROFILE
                                        })}
                                        to={`/agent-profile/${props.match.params.userId}`}><i
                                        className="fa fa-user-circle"/> Profile</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link
                                        className={classnames('nav-link', {
                                            'active': props.scenario === AGENT_FRIEND_LIST
                                        })}
                                        to={`/agent-friend-list/${props.match.params.userId}`}>
                                        <i className="fa fa-users"/> Friend</Link>
                                </li>*/}
                                <li className="nav-item ">
                                    <Link className={classnames('nav-link', {
                                        'active': props.scenario === AGENT_PROPERTY_LIST
                                    })} to={`/agent-property-list/${props.match.params.userId}`}> <i
                                        className="fa fa-building"/> Property</Link>
                                </li>


                                {/*/!*Remove Validation for chat*!/*/}
                                {/*{(user_relation === 'NoRelation' || user_relation === 'REQUEST')   &&*/}
                                {/*<li className="nav-item" style={{cursor:'no-drop'}} title={'Add this person to your friend list then start chatting..'}>*/}
                                    {/*<a className={classnames('nav-link', {*/}
                                        {/*'active': props.scenario === AGENT_PRIVATE_CHAT*/}
                                    {/*})} ><i*/}
                                        {/*className="fa fa-comments"/> Chat</a>*/}
                                {/*</li>*/}
                                {/*}*/}

                                {/*{user_relation === 'FRIEND' &&*/}
                                {/*<li className="nav-item ">*/}
                                    {/*<Link className={classnames('nav-link', {*/}
                                        {/*'active': props.scenario === AGENT_PRIVATE_CHAT*/}
                                    {/*})} to={`/private-chat/${props.match.params.userId}`}><i*/}
                                        {/*className="fa fa-comments"/> Chat</Link>*/}
                                {/*</li>*/}
                                {/*}*/}

                                {props.loggedin === props.match.params.userId ?

                                    <li className="nav-item" style={{cursor:'no-drop'}} title={'Add this person to your friend list then start chatting..'}>
                                        <a className={classnames('nav-link', {
                                            'active': props.scenario === AGENT_PRIVATE_CHAT
                                        })} ><i
                                            className="fa fa-comments"/> Chat</a>
                                    </li>

                                    :
                                    <li className="nav-item ">
                                        <Link className={classnames('nav-link', {
                                            'active': props.scenario === AGENT_PRIVATE_CHAT
                                        })} to={`/private-chat/${props.match.params.userId}`}><i
                                            className="fa fa-comments"/> Chat</Link>
                                    </li>

                                }




                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        agent: state.agent,
        isAuthenticated: state.auth.isAuthenticated,
    }
};

export default connect(mapStateToProps, {})(AgentProfileDropdownMenu);
