import React from 'react';
import classnames from "classnames";
import {Link} from "react-router-dom";

const PropertyCommonPagerSelection = (props) => {
    return (
        <div className="row mt-4 ">
            <div className="col-md-7 col-sm-12  text-md-right mt-1 mx-auto">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-4 "><label>{props.label}<span className="text-danger">{props.required}</span></label></div>
                    <div className="col-md-8 col-sm-12 ">
                        <nav aria-label="Page navigation text-center">
                            <ul className="pagination  pager-selection">
                                {props.data.map((d, index) => <li key={index}
                                                                  className={classnames('page-item', {
                                                                      'active': props.value === d
                                                                  })}
                                ><span
                                    className={"page-link"}
                                    onClick={() => props.onChange(props.name, d)}>{d}</span></li>)}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyCommonPagerSelection;