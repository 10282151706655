import React from 'react';
import ContentLoader from "react-content-loader";

const TestimonialsListingCardLoder = () => {
    return (
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div className="single-feature">
                <ContentLoader
                    speed={2}
                    width={300}
                    height={415}
                    viewBox="0 0 476 615"
                    backgroundColor="#c2c2c2"
                    foregroundColor="#ecebeb"
                    style={{textAlign:'center'}}

                >
                    <path d="M 453.5 389.6 C 396 342.3 333.9 311 321.3 304.8 c -1.4 -0.7 -2.3 -2.1 -2.3 -3.7 v -89.6 c 11.3 -7.5 18.7 -20.3 18.7 -34.9 V 83.7 C 337.7 37.5 300.2 0 254 0 h -20 c -46.2 0 -83.7 37.5 -83.7 83.7 v 92.9 c 0 14.5 7.4 27.3 18.7 34.9 V 301 c 0 1.6 -0.9 3 -2.3 3.7 c -12.6 6.2 -74.7 37.5 -132.2 84.8 c -10.4 8.5 -16.4 21.3 -16.4 34.8 V 488 h 196.2 l 19.4 -88.8 c -39.2 -54.6 2.9 -57.3 10.3 -57.3 c 7.3 0.1 49.4 2.7 10.3 57.3 l 19.4 88.8 h 196.2 v -63.7 c 0 -13.4 -6 -26.2 -16.4 -34.7 z" />
                    <rect x="24" y="536" rx="0" ry="0" width="221" height="9" />
                    <rect x="25" y="554" rx="0" ry="0" width="174" height="7" />
                    <rect x="284" y="594" rx="0" ry="0" width="21" height="21" />
                    <rect x="322" y="595" rx="0" ry="0" width="21" height="21" />
                    <rect x="362" y="595" rx="0" ry="0" width="21" height="21" />
                    <rect x="402" y="595" rx="0" ry="0" width="21" height="21" />
                </ContentLoader>
            </div>
        </div>
    );
};

export default TestimonialsListingCardLoder;