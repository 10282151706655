import React, { Component, Fragment } from "react";
import ChatMessage from "../common/ChatMessage";
import ChatUser from "../common/ChatUser";
import defaultAvatar from "../../assets/img/nophoto.png";
import connect from "react-redux/es/connect/connect";
import { actionGetAgent } from "../../actions/agentAction";
import {
    actionGetChatList,
    actionMessageHistory,
    actionAppendMessage,
    actionCreateBroadcast, actionGetChatAgent, actionUpdateBroadcast, actionDeleteBroadcast, actionSharePropertyUrl
} from "../../actions/chatAction";
import chatImg from "../../assets/img/chat-icon.svg";
import makeAgentImg from "../../assets/img/add-friend.svg";
import broadcastIcon from '../../assets/img/broadcast_icon.png'
import $ from 'jquery';
import jwt_decode from "jwt-decode";
import { WS_URL } from "../../web-config";
import classnames from "classnames";
import BroadCastSelectedPerson from "../common/BroadCastSelectedPerson";
import BroadcastModelChatUser from "../common/broadcast-model-chat-user";
import btnLoader from "../../assets/img/button-loader.svg";
import Spinner from "./Spinner";
import BroadcastUserListLoaderCard from "../common/broadcast-user-list-loader-card";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import { Dropdown, Menu, Toggle, Item } from "react-bootstrap";
// import {Item} from 'react-bootstrap';
// import {Menu} from 'react-bootstrap';

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOnline: false,
            message: '',
            chatPerson: '',
            chatFilter: '',
            broadcastSearchFilter: '',
            broadcast_name: '',
            broadcast_members: [],
            filteredBroadcastPersonListLoader: false,
            errors: {}
        }
    }

    ws = new WebSocket(WS_URL);

    componentDidMount() {




        this.setState({ filteredBroadcastPersonListLoader: true });
        if (this.props.user.user_id) {
            this.props.actionGetChatList(this.props.user.user_id);
        }

        this.props.match.params.userId && this.handleSelectChatPerson(this.props.match.params.userId, 'Agent');

        this.ws.onopen = () => {
            // on connecting, do nothing but log it to the console
            this.setState({ isOnline: true });
            if (localStorage.jwtToken) {
                const decoded = jwt_decode(localStorage.jwtToken);
                this.ws.send(JSON.stringify({ 'action': 'setName', 'name': parseInt(decoded.uid) }));

                setTimeout(() => {
                    if (this.props.sharePropertyUrl) {
                        console.log({ urll: this.props.sharePropertyUrl });
                        if (this.props.sharePropertyUrl) {

                            this.ws.send(JSON.stringify({
                                action: this.state.chatPerson.chat_type === 'Agent' ? 'sendMessage' : 'sendBroadcastMessage',
                                payload: {
                                    sender_id: this.props.user.user_id,
                                    receiver_id: this.state.chatPerson.chat_type === 'Agent' ? this.state.chatPerson.user_id : this.state.chatPerson.broadcast_members,
                                    message: this.props.sharePropertyUrl,
                                    attachment_name: "",
                                    file_ext: "",
                                    mime_type: "",
                                    ip_address: "",
                                    broadcast_id: this.state.chatPerson.chat_type === 'Agent' ? null : this.state.chatPerson.user_id,
                                },
                                token: ''
                            }));
                            this.props.actionSharePropertyUrl('');
                            this.scrollToBottom();
                        }
                    }
                }, 600);
            }

        };

        this.ws.onmessage = evt => {
            // on receiving a message, add it to the list of messages
            const message = JSON.parse(evt.data);
            console.log('Response:' + message.type);
            if (message.type === 'receiver_message_received') {
                this.props.actionAppendMessage(message.data, this.scrollToBottom);
            } else if (message.type === 'new_message_received') {
                console.log({ Message: message.data });
                (parseInt(message.data.sender_id) === parseInt(this.state.chatPerson.user_id) && this.state.chatPerson.chat_type === 'Agent') && this.props.actionAppendMessage(message.data, this.scrollToBottom);
            }
        };

        this.ws.onclose = () => {
            console.log('disconnected');
            this.setState({ isOnline: false });
            // automatically try to reconnect on connection loss
            /*this.setState({
                ws: new WebSocket(URL),
            })*/
        }
        this.setState({ filteredBroadcastPersonListLoader: false });







    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.user_id !== this.props.user.user_id) {
            if (localStorage.jwtToken) {
                const decoded = jwt_decode(localStorage.jwtToken);
                this.props.actionGetChatList(decoded.uid);
                this.props.actionGetChatAgent(decoded.uid, 'Agent');


            }




        }
        prevProps.chatAgent !== this.props.chatAgent && this.setState({ chatPerson: this.props.chatAgent });
    }


    onChange = (e) => this.setState({ [e.target.name]: e.target.value });
    onChangechatFilter = (e) => this.setState({ [e.target.name]: e.target.value });

    scrollToBottom = () => $(".messages").animate({ scrollTop: $(document).height() }, "fast");

    onSendMessageSubmit = (e) => {
        e.preventDefault();
        if (this.state.message.trim()) {
            this.ws.send(JSON.stringify({
                action: this.state.chatPerson.chat_type === 'Agent' ? 'sendMessage' : 'sendBroadcastMessage',
                payload: {
                    sender_id: this.props.user.user_id,
                    receiver_id: this.state.chatPerson.chat_type === 'Agent' ? this.state.chatPerson.user_id : this.state.chatPerson.broadcast_members,
                    message: this.state.message,
                    attachment_name: "",
                    file_ext: "",
                    mime_type: "",
                    ip_address: "",
                    broadcast_id: this.state.chatPerson.chat_type === 'Agent' ? null : this.state.chatPerson.user_id,
                },
                token: ''
            }));

            this.resetInput();
            this.scrollToBottom();
        }
    };

    resetInput = () => this.setState({ message: '' });

    /*handleSelectChatPerson = (personId, type) => this.props.chatPersonList.filter(p => parseInt(p.user_id) === parseInt(personId) && p.chat_type === type).length > 0 && this.setState({chatPerson: this.props.chatPersonList.find(p => parseInt(p.user_id) === parseInt(personId) && p.chat_type === type)}, () => {
        const request = {
            sender_id: this.props.user.user_id,
            receiver_id: personId,

        };
        this.props.actionMessageHistory(request);
    });*/

    /*handleSelectChatPerson = (personId, type) => this.props.chatPersonList.filter(p => parseInt(p.user_id) === parseInt(personId) && p.chat_type === type).length > 0 && this.setState({chatPerson: this.props.chatPersonList.find(p => parseInt(p.user_id) === parseInt(personId) && p.chat_type === type)}, () => {
        const request = {
            sender_id: this.props.user.user_id,
            receiver_id: personId,

        };
        this.props.actionMessageHistory(request);
    });*/

    handleSelectChatPerson = (personId, type) => {
        this.props.actionGetChatAgent(personId, type);

    };

    handleSelectBroadcastPerson = (personId) => this.setState({ broadcast_members: this.state.broadcast_members.find(m => parseInt(m) === parseInt(personId)) ? [...this.state.broadcast_members.filter(m => parseInt(m) !== parseInt(personId))] : [...this.state.broadcast_members, personId] });

    broadcastValidation = () => {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        if (!fields.broadcast_name.trim()) {

            formIsValid = false;
            // document.getElementById('openBroadcast')&&document.getElementById('openBroadcast').click();
            // alert("Please Enter Broadcast Name")

            errors.name = "Broadcast Name must be Required!";
        }
        if (!fields.broadcast_members.length > 0) {
            formIsValid = false;
            // document.getElementById('openBroadcast')&&document.getElementById('openBroadcast').click();
            // alert("Please select broadcast members...!")
            errors.name = "Broadcast Name must be Required!";
        }

        this.setState({ errors: errors }, () => {
            const ID = Object.keys(errors)[0];
            console.log({ ID });
            document.getElementById(ID) && document.getElementById(ID).scrollIntoView();
        });
        return formIsValid;

    };

    onCreateBroadcastSubmit = () => {

        if (this.broadcastValidation()) {
            const { broadcast_name, broadcast_members } = this.state;
            const created_by = this.props.user.user_id;
            console.log(created_by);
            const createBroadcast = {
                broadcast_name,
                created_by,
                image: '',
                broadcast_members,
            };
            console.log(createBroadcast);

            this.setState({
                broadcast_name: '',
                created_by: '',
                broadcast_members: []
            });

            this.props.actionCreateBroadcast(createBroadcast);
            document.getElementById('closeBtn') && document.getElementById('closeBtn').click();
        }
        else {
            document.getElementById('openBroadcast').click();
        }


    };
    onUpdateBroadcastSubmit = () => {
        // document.getElementById('openBroadcast')&&document.getElementById('openBroadcast').click();
        console.log('update');
        const broadcast_id = parseInt(this.props.chatAgent.user_id);
        if (this.broadcastValidation()) {

            const { broadcast_name, broadcast_members } = this.state;
            const created_by = this.props.user.user_id;
            console.log(created_by);
            const createBroadcast = {
                broadcast_id,
                broadcast_name,
                created_by,
                image: '',
                broadcast_members,
            };
            console.log(createBroadcast);
            this.props.actionUpdateBroadcast(createBroadcast);
            this.setState({
                broadcast_name: '',
                created_by: '',
                broadcast_members: []
            });
            document.getElementById('close') && document.getElementById('close').click();
            console.log(broadcast_id)
        }

        else {



            // settings_broadcast

            // this.setState({
            //     broadcast_name: '',
            //     created_by: '',
            //     broadcast_members: []
            // })
        }

    };


    render() {
        const { chatPersonList, messageHistory, user, createBroadcastLoader, deleteBroadcastLoader } = this.props;
        const { chatPerson } = this.state;
        let filteredChatPersonList = !this.state.chatFilter ? chatPersonList : chatPersonList.filter(a => {
            return a.first_name && a.first_name.toLowerCase().indexOf(this.state.chatFilter.toLowerCase()) > -1 || a.last_name && a.last_name.toLowerCase().indexOf(this.state.chatFilter.toLowerCase()) > -1 || a.email && a.email.toLowerCase().indexOf(this.state.chatFilter.toLowerCase()) > -1;
        });

        let filteredBroadcastPersonList = !this.state.broadcastSearchFilter ? chatPersonList : chatPersonList.filter(a => {
            return (a.first_name && a.first_name.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1) || (a.last_name && a.last_name.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1) || (a.email && a.email.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1);
        });

        function openNav() {
            if (window.matchMedia('(max-width: 765px)')) {
                document.getElementById("mySidenav").style.width = "341px";
            }
            if (window.matchMedia('(max-width: 399px)')) {
                document.getElementById("mySidenav").style.width = "282px";
            }


        }

        function closeNav() {
            document.getElementById("mySidenav").style.width = "0";

        }


        const serachlStle = {
            position: 'absolute',
            right: '35px',
            top: '76px',

        };
        const serachiStle = {
            width: '100%',
            // height:'37px',
            lineHeight: '36px',
            padding: '1px 20px',
            fontSize: '15px',
            border: '1px solid #d4d4d4'

        };
        const sty = {
            position: "absolute",
            top: "17px",
            left: "57px",
            maxWidth: '242px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        };
        const broadcast_id = this.props.chatAgent.user_id;
        const { errors } = this.state;
        // console.log(chatPerson);
        // console.log(this.props.chatAgent);
        // console.log(chatPerson);
        // console.log(this.props.chatAgent.memberDetails);
        // console.log(filteredBroadcastPersonList);
        // console.log(this.state.broadcast_members)
        // console.log(this.state.broadcast_members.find(m => parseInt(filteredBroadcastPersonList.map(val=>val.user_id)) === parseInt(m)));


        return (
            <div className="private_chat mt-1">
                <div id="frame" className={classnames({ 'w-50': !this.props.showSidebar })}>
                    {this.props.showSidebar && <Fragment>
                        <div id="sidepanel">
                            <div id="profile">
                                <div className="wrap">
                                    <div className="logged-in-online">
                                        <img
                                            id="profile-img"
                                            className={this.state.isOnline ? 'online' : 'offline'}
                                            src={this.props.user.profile_pic ? this.props.user.profile_pic : defaultAvatar}
                                            alt={this.props.user.first_name} />
                                        <p>{`${this.props.user.first_name} ${this.props.user.last_name}`} ({this.state.isOnline ? 'Online' : 'Offline'})</p>
                                        {/*<i className="fa fa-plus-circle expand-button " aria-hidden="true" data-toggle="modal" data-target="#createBroadcastModal"*/}
                                        {/*title={'Add New Broadcast'} style={{fontSize:'28px'}}/>*/}
                                    </div>
                                </div>
                            </div>
                            <div id="search">
                                <label htmlFor=""><i className="fa fa-search" aria-hidden="true" /></label>

                                <input type="text" name="chatFilter" onChange={this.onChangechatFilter}
                                    value={this.state.chatFilter}
                                    placeholder="Search or start new chat" />
                            </div>
                            <div id="contacts">
                                <ul>

                                    <div className="contact">
                                        <div className="wrap">
                                            {this.state.filteredBroadcastPersonListLoader &&
                                                <div>
                                                    <BroadcastUserListLoaderCard color={'#2c3e5036'} />
                                                    <BroadcastUserListLoaderCard color={'#2c3e5036'} />
                                                    <BroadcastUserListLoaderCard color={'#2c3e5036'} />
                                                    <BroadcastUserListLoaderCard color={'#2c3e5036'} />
                                                    <BroadcastUserListLoaderCard color={'#2c3e5036'} />
                                                </div>

                                            }
                                            {(filteredChatPersonList && filteredChatPersonList.length > 0) && filteredChatPersonList.map((person, index) =>
                                                <ChatUser
                                                    key={index}
                                                    handleSelectChatPerson={this.handleSelectChatPerson}
                                                    name={`${person.first_name ? person.first_name : ''} ${person.last_name ? person.last_name : ''}`}
                                                    profile_pic={person.profile_pic}
                                                    lastMessage={person.last_message}
                                                    chatPerson={chatPerson}
                                                    type={person.chat_type}
                                                    user_id={person.user_id} />)}
                                        </div>
                                    </div>

                                </ul>
                            </div>
                            <div id="bottom-bar" className="text-center">
                                {/*<button id="addcontact"><i className="fa fa-user-plus fa-fw" aria-hidden="true"*/}
                                {/*title={'Add Contact'}/>*/}
                                {/*<span>Add contact</span>*/}
                                {/*</button>*/}
                                <button id="openBroadcast" data-toggle="modal" data-target="#createBroadcastModal"
                                    data-backdrop="static" data-keyboard="false" className="w-100" onClick={() => {
                                        this.setState({
                                            message: '',
                                            chatPerson: '',
                                            chatFilter: '',
                                            broadcastSearchFilter: '',
                                            broadcast_name: '',
                                            broadcast_members: [],
                                        })
                                    }}><i
                                        className="fa fa-plus-circle expand-button-responsive" aria-hidden="true"
                                        title={'Add New Broadcast'} />
                                    <span>Add New Broadcast</span></button>
                            </div>

                        </div>
                        <div id="mySidenav" className="sidenav" style={{ display: 'none' }}>
                            <div id="sidepanel">

                                <div id="profile">
                                    <a className="closebtn" onClick={closeNav}>&times;</a>
                                    <div className="logged-in-online">
                                        <img
                                            id="profile-img"
                                            className={this.state.isOnline ? 'online' : 'offline'}
                                            src={this.props.user.profile_pic ? this.props.user.profile_pic : defaultAvatar}
                                            alt={this.props.user.first_name} />
                                        <p>{`${this.props.user.first_name} ${this.props.user.last_name}`} ({this.state.isOnline ? 'Online' : 'Offline'})</p>
                                        <i className="fa fa-chevron-down expand-button" aria-hidden="true" />
                                    </div>
                                </div>
                                <div id="search">
                                    <label htmlFor=""><i className="fa fa-search" aria-hidden="true" /></label>
                                    <input type="text" name="chatFilter" onChange={this.onChange}
                                        value={this.state.chatFilter}
                                        placeholder="Search or start new chat" />
                                </div>
                                <div id="contacts">
                                    <ul>
                                        {(filteredChatPersonList && filteredChatPersonList.length > 0) && filteredChatPersonList.map((person, index) =>
                                            <ChatUser
                                                key={index}
                                                handleSelectChatPerson={this.handleSelectChatPerson}
                                                name={`${person.first_name} ${person.last_name}`}
                                                profile_pic={person.profile_pic}
                                                lastMessage={person.last_message}
                                                chatPerson={chatPerson}
                                                type={person.chat_type}
                                                user_id={person.user_id} />)}
                                    </ul>
                                </div>
                                <div id="bottom-bar" className="text-center">
                                    {/*<button id="addcontact"><i className="fa fa-user-plus fa-fw" aria-hidden="true"/>*/}
                                    {/*<span>Add contact</span>*/}
                                    {/*</button>*/}
                                    <button id="settings"><i className="fa fa-cog fa-fw" aria-hidden="true" />
                                        <span>Settings</span></button>
                                </div>

                            </div>

                        </div>
                    </Fragment>}
                    <div id="mySidenav" className="sidenav" style={{ display: 'none' }}>
                        <div id="sidepanel">

                            <div id="profile">
                                <a className="closebtn" onClick={closeNav}>&times;</a>
                                <div className="logged-in-online">
                                    <img
                                        id="profile-img"
                                        className={this.state.isOnline ? 'online' : 'offline'}
                                        src={this.props.user.profile_pic ? this.props.user.profile_pic : defaultAvatar}
                                        alt={this.props.user.first_name} />
                                    <p>{`${this.props.user.first_name} ${this.props.user.last_name}`} ({this.state.isOnline ? 'Online' : 'Offline'})</p>
                                    <i className="fa fa-chevron-down expand-button" aria-hidden="true" />
                                </div>
                            </div>
                            <div id="search">
                                <label htmlFor=""><i className="fa fa-search" aria-hidden="true" /></label>
                                <input type="text" name="chatFilter" onChange={this.onChange}
                                    value={this.state.chatFilter}
                                    placeholder="Search or start new chat" />
                            </div>
                            <div id="contacts">
                                <ul>
                                    {(filteredChatPersonList && filteredChatPersonList.length > 0) && filteredChatPersonList.map((person, index) =>
                                        <ChatUser
                                            key={index}
                                            handleSelectChatPerson={this.handleSelectChatPerson}
                                            name={`${person.first_name} ${person.last_name}`}
                                            profile_pic={person.profile_pic}
                                            lastMessage={person.last_message}
                                            chatPerson={chatPerson}
                                            type={person.chat_type}
                                            user_id={person.user_id} />)}
                                </ul>
                            </div>
                            <div id="bottom-bar" className="text-center">
                                {/*<button id="addcontact"><i className="fa fa-user-plus fa-fw" aria-hidden="true"/> <span>Add contact</span>*/}
                                {/*</button>*/}
                                <button id="settings"><i className="fa fa-cog fa-fw" aria-hidden="true" />
                                    <span>Settings</span></button>
                            </div>

                        </div>

                    </div>


                    <div className={classnames('content', { 'w-100': !this.props.showSidebar })}>
                        {chatPerson && <Fragment>
                            <div className="contact-profile">
                                {chatPerson.chat_type === "Agent" &&
                                    <img src={chatPerson.profile_pic ? chatPerson.profile_pic : defaultAvatar} alt="" />}
                                {chatPerson.chat_type === "Broadcast" &&
                                    <img src={broadcastIcon} alt="Broadcast" style={{ background: '#e0e0e0' }} />}


                                {/*<img src={chatPerson.profile_pic ? chatPerson.profile_pic : defaultAvatar} alt=""/>*/}
                                <p>{`${chatPerson.first_name ? chatPerson.first_name : ''} ${chatPerson.last_name ? chatPerson.last_name : ''}`}</p>

                                {(chatPerson.chat_type === "Broadcast" && chatPerson.memberDetails) &&
                                    <small style={sty}
                                        className="d-inline-block text-truncate">{chatPerson.memberDetails.map(val =>
                                            <span>{` ${val.name},`}</span>)}</small>
                                }


                                {chatPerson.chat_type === "Broadcast" &&

                                    <Dropdown>
                                        <Dropdown.Toggle className='delete-property' variant="light"
                                            id="dropdown-button-drop-left">
                                            <i className="fa fa-ellipsis-v" />

                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className={'p-1'}
                                            style={{ transform: 'translate(84.1111px, 41.8889px)' }}>
                                            <Dropdown.Item className='' style={{ lineHeight: '30px' }}><Link
                                                to={`#createBroadcastModal`} data-toggle="modal"
                                                data-target="#createBroadcastModal"
                                                data-backdrop="static" data-keyboard="false" className={''}
                                                onClick={() => this.setState({ broadcast_name: `${chatPerson.first_name ? chatPerson.first_name : ''} ${chatPerson.last_name ? chatPerson.last_name : ''}` }, () => this.setState({ broadcast_members: chatPerson.memberDetails.map(val => val.user_id) }), () => chatPerson.memberDetails.map(val => this.handleSelectBroadcastPerson(val.user_id)))}><i
                                                    className="fa fa-pencil mr-1  " />Update</Link></Dropdown.Item>
                                            {/*<Dropdown.Item ><Link to={`/property-images`}><i className="fa fa-picture-o mr-1"/>Upload</Link></Dropdown.Item>*/}
                                            <Dropdown.Item className={''} style={{ lineHeight: '30px' }}><Link
                                                to={"#exampleModalCenter"} className={''} data-toggle="modal"
                                                data-target="#exampleModalCenter" onClick={() => {
                                                    swal({
                                                        title: 'Are you sure you want to delete this Broadcast?',
                                                        text: "Once deleted, you will not be able to recover this Broadcast!",
                                                        icon: "warning",
                                                        buttons: true,
                                                        dangerMode: true,
                                                    })
                                                        .then((willDelete) => {
                                                            if (willDelete && !deleteBroadcastLoader) {
                                                                swal("Poof! Your Broadcast  has been deleted!", {
                                                                    icon: "success",

                                                                });
                                                                this.props.actionDeleteBroadcast(chatPerson.user_id && chatPerson.user_id)
                                                                this.setState({ chatPerson: '' })
                                                            } else {
                                                                swal("Your Broadcast is safe!");
                                                            }
                                                        });
                                                    // window.confirm('Are you sure you want to delete this Property?')&&)

                                                }
                                                }><i className="fa fa-trash-o mr-1 p-0" />Delete</Link></Dropdown.Item>
                                        </Dropdown.Menu>

                                    </Dropdown>

                                }
                                {/*<div className="social-media">
                                    <i className="fa fa-facebook" aria-hidden="true"/>
                                    <i className="fa fa-twitter" aria-hidden="true"/>
                                    <i className="fa fa-instagram" aria-hidden="true"/>
                                </div>*/}
                                <span onClick={openNav} className={'opne-chat-btn'}
                                    style={{ display: 'none' }}>&#9776;</span>
                                {/*<i className="fa fa-plus-circle expand-button-responsive" aria-hidden="true" data-toggle="modal" data-target="#createBroadcastModal"*/}
                                {/*title={'Add New Broadcast'} style={{fontSize:'28px'}}/>*/}
                            </div>
                        </Fragment>}

                        {chatPerson && <div className="messages">
                            <ul>
                                {(messageHistory && messageHistory.length > 0) ? messageHistory.map((msg, i) =>
                                    <ChatMessage key={i} message={msg.message}
                                        profile_pic={msg.profile_pic}
                                        isSend={parseInt(msg.sender_id) !== parseInt(user.user_id)} />) : ""}
                                {/*<ChatMessage/>*/}
                                {/*<ChatMessage isSend={false}/>*/}
                            </ul>
                        </div>}
                        {(!chatPerson && this.props.showSidebar) &&

                            <div className="messages row align-items-center justify-content-center">
                                <div className="col-12 text-center">
                                    <div className='data-not-found-container'>
                                        <img src={chatImg} alt="Please Select Person to talk" />
                                        <h4>Select Agent to chat with</h4>
                                    </div>
                                </div>
                            </div>}
                        {(!chatPerson && !this.props.showSidebar) &&
                            <div className="messages row align-items-center justify-content-center">
                                <div className="col-12 text-center">
                                    <div className='data-not-found-container'>
                                        <img src={makeAgentImg} alt="Please Select Person to talk" />
                                        <h4>Add this person to your friend list then start chatting</h4>
                                    </div>
                                </div>
                            </div>}

                        {chatPerson && <div className="message-input">
                            <div className="wrap">
                                {/*<i className="fa fa-paperclip attachment" aria-hidden="true"/>*/}
                                <form autoComplete="off" noValidate={true}
                                    className="d-flex justify-content-between align-items-center pr-2 pl-2"
                                    onSubmit={this.onSendMessageSubmit}>
                                    <input value={this.state.message} onChange={this.onChange} type="text"
                                        name="message" placeholder="Write your message..." />
                                    <button className="submit">
                                        <i className="fa fa-paper-plane" aria-hidden="true" />
                                    </button>
                                </form>

                            </div>
                        </div>}
                    </div>
                </div>

                <Fragment>
                    {/* Create Broadcast Modal */}
                    <div className="modal fade" id="createBroadcastModal" tabIndex={-1} role="dialog"
                        aria-labelledby="createBroadcastModalLabel" aria-hidden="true" style={{ marginTop: "2em" }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">

                            <div className="modal-content" style={{ zIndex: '9999999999999999 !important' }}>
                                <form autoComplete="off" noValidate={true} onSubmit={this.onCreateBroadcastSubmit}>
                                    <div className="modal-header">
                                        <h5 className="modal-title w-100 text-center"
                                            id="createBroadcastModalLabel">{this.state.broadcast_name ? `Update Broadcast` : `Create Broadcast`}</h5>
                                        <button type="button" id="closeBtn" className="close" data-dismiss="modal"
                                            aria-label="Close">
                                            <span aria-hidden="true h3">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body pt-0">
                                        <Fragment>
                                            <div id="sidepanel" className="p-2">
                                                {/*<div className="d-flex align-items-center mb-2">*/}

                                                {/*<h5 className="ml-2 text-white">Add broadcast members</h5>*/}
                                                {/*</div>*/}
                                                <div className="rld-single-input mb-2">
                                                    <input type="text" name="broadcast_name" onChange={this.onChange}
                                                        value={this.state.broadcast_name}
                                                        placeholder={"Enter Broadcast Name"} className={classnames({
                                                            'is-invalid': errors.broadcast_name
                                                        })} />
                                                    {errors.broadcast_name &&
                                                        <span className='text-danger'>{errors.broadcast_name}</span>}
                                                </div>
                                                <div id="search" className="mb-2">
                                                    <label htmlFor="" style={serachlStle}><i className="fa fa-search"
                                                        aria-hidden="true" /></label>
                                                    <input type="text" style={serachiStle} name="broadcastSearchFilter"
                                                        onChange={this.onChange}
                                                        value={this.state.broadcastSearchFilter}
                                                        placeholder="Search Member" />
                                                </div>

                                                {this.state.broadcast_members.length > 0 &&
                                                    <div
                                                        className="d-flex align-items-center justify-content-between pl-2 pr-2">
                                                        {this.state.broadcast_members.length > 0 &&
                                                            <div>{this.state.broadcast_members.length} Person
                                                                Selected.</div>}
                                                        {createBroadcastLoader ?
                                                            <div className="broadcast-next-btn"><img src={btnLoader}
                                                                alt="contact-loader"
                                                                width={15} />
                                                            </div>
                                                            :


                                                            broadcast_id ?

                                                                <div className="broadcast-next-btn" id="clg" type="button"
                                                                    onClick={this.onUpdateBroadcastSubmit}><i
                                                                        className="fa fa-arrow-right" />

                                                                </div>
                                                                :
                                                                <div className="broadcast-next-btn" id="clg" type="button"
                                                                    onClick={this.onCreateBroadcastSubmit}><i
                                                                        className="fa fa-arrow-right" />

                                                                </div>
                                                        }
                                                    </div>}

                                                {/*<div className="selected-person-container d-flex flex-column">
                                {this.state.broadcast_members.length > 0 && this.state.broadcast_members.map(m =>
                                    <BroadCastSelectedPerson
                                        person={this.props.chatPersonList.find(p => parseInt(p.user_id) === parseInt(m))}/>)}
                            </div>*/}


                                                <hr />
                                                <div id="contacts" className="broadcast-model-contact">
                                                    <ul>
                                                        {/*{!filteredBroadcastPersonList.length >0&&*/}
                                                        {/*<div className="text-center mt-5"><Spinner width={100}/></div>*/}
                                                        {/*}*/}

                                                        {(!filteredBroadcastPersonList.length) &&
                                                            <div className="">
                                                                <BroadcastUserListLoaderCard />
                                                                <BroadcastUserListLoaderCard />
                                                                <BroadcastUserListLoaderCard />
                                                                <BroadcastUserListLoaderCard />
                                                            </div>
                                                        }

                                                        {(filteredBroadcastPersonList && filteredBroadcastPersonList.length > 0) && filteredBroadcastPersonList.map((person, index) =>
                                                            person.chat_type === "Agent" &&
                                                            <BroadcastModelChatUser
                                                                key={index}
                                                                handleSelectChatPerson={this.handleSelectBroadcastPerson}
                                                                name={`${person.first_name} ${person.last_name}`}
                                                                profile_pic={person.profile_pic}
                                                                lastMessage={person.last_message}
                                                                chatPerson={chatPerson}
                                                                isActive={this.state.broadcast_members.find(m => parseInt(person.user_id) === parseInt(m))}
                                                                user_id={person.user_id} />)}

                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="mySidenav" className="sidenav" style={{ display: 'none' }}>
                                                <div id="sidepanel">

                                                    <div id="profile">
                                                        <a className="closebtn" onClick={closeNav}>&times;</a>
                                                        <div className="logged-in-online">
                                                            <img
                                                                id="profile-img"
                                                                className={this.state.isOnline ? 'online' : 'offline'}
                                                                src={this.props.user.profile_pic ? this.props.user.profile_pic : defaultAvatar}
                                                                alt={this.props.user.first_name} />
                                                            <p>{`${this.props.user.first_name} ${this.props.user.last_name}`} ({this.state.isOnline ? 'Online' : 'Offline'})</p>
                                                            <i className="fa fa-chevron-down expand-button"
                                                                aria-hidden="true" />
                                                        </div>
                                                    </div>
                                                    <div id="search">
                                                        <label htmlFor=""><i className="fa fa-search"
                                                            aria-hidden="true" /></label>
                                                        <input type="text" name="chatFilter"
                                                            onChange={this.onChangechatFilter}
                                                            value={this.state.chatFilter}
                                                            placeholder="Search or start new chat" />
                                                    </div>
                                                    <div id="contacts">
                                                        <ul>
                                                            {(filteredChatPersonList && filteredChatPersonList.length > 0) && filteredChatPersonList.map((person, index) =>
                                                                <ChatUser
                                                                    key={index}
                                                                    handleSelectChatPerson={this.handleSelectChatPerson}
                                                                    name={`${person.first_name} ${person.last_name}`}
                                                                    profile_pic={person.profile_pic}
                                                                    lastMessage={person.last_message}
                                                                    chatPerson={chatPerson}
                                                                    user_id={person.user_id} />)}
                                                        </ul>
                                                    </div>
                                                    <div id="bottom-bar" className="text-center">
                                                        {/*<button id="addcontact">*/}
                                                        {/*<i className="fa fa-user-plus fa-fw" aria-hidden="true"/>*/}
                                                        {/*<span>Add contact</span>*/}
                                                        {/*</button>*/}
                                                        <button id="settings"><i className="fa fa-cog fa-fw"
                                                            aria-hidden="true" />
                                                            <span>Settings</span></button>
                                                    </div>

                                                </div>

                                            </div>
                                        </Fragment>
                                    </div>
                                    <div className="modal-footer">
                                        {/*<button type="button" className="btn btn-secondary" data-dismiss="modal">Close*/}
                                        {/*</button>*/}
                                        {/*<button type="button" className="btn btn-yellow" id="clg" data-dismiss="modal" onClick={this.onCreateBroadcastSubmit}>Create*/}
                                        {/*</button>*/}
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                </Fragment>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        agent: state.agent,
        user: state.auth.user,
        chatPersonList: state.chat.chatPersonList,
        messageHistory: state.chat.messageHistory,
        getChatAgentLoader: state.chat.getChatAgentLoader,
        getChatListLoader: state.chat.getChatListLoader,
        deleteBroadcastLoader: state.chat.deleteBroadcastLoader,
        chatAgent: state.chat.chatAgent,
        createBroadcastLoader: state.chat.createBroadcastLoader,
        sharePropertyUrl: state.chat.sharePropertyUrl,
        sharePropertyUrlLoader: state.chat.sharePropertyUrlLoader,
    };
};
export default connect(mapStateToProps, {
    actionGetAgent,
    actionGetChatList,
    actionMessageHistory,
    actionAppendMessage,
    actionCreateBroadcast,
    actionUpdateBroadcast,
    actionDeleteBroadcast,
    actionGetChatAgent,
    actionSharePropertyUrl
})(Chat);