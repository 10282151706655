import React from 'react';
import classnames from "classnames";

const PropertyCommonTextArea = (props) => {
    return (
        <div className="row mt-4 ">
            <div className="col-md-7 col-sm-12 text-md-right mt-1 mx-auto">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-4 "><label>{props.label} <span className={'text-danger'}>{props.required}</span></label></div>
                    <div className="col-md-8">
                        <div className="rld-single-input mt-0 text-center ">
                            <textarea
                                rows={3}
                                   name={props.name}
                                   id={props.name}
                                   value={props.value}
                                   onChange={props.onChange}
                                   className={classnames({
                                       'is-invalid': props.error
                                   })}
                                   placeholder={props.placeholder}/>
                            {props.error && <span className='text-danger'>{props.error}</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyCommonTextArea;