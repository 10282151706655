import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import iconOne from "../../assets/img/icons/1.png";
import connect from "react-redux/es/connect/connect";
import {
    actionSendFriendRequest,
    actionRejectRequest,
    actionAcceptFriendRequest,
    actionUnFriend
} from "../../actions/agentAction";
import {useHistory} from "react-router";


const HomePageAgentCard = (props) => {
    const history = useHistory();

    let reqBtn = '';

    if (props.item.user_relation === 'REQUEST') {
        // Should Display Cancel Button
        if (parseInt(props.item.user_relation_object.user_id) === parseInt(props.user.user_id)) {
            reqBtn = <Link
                title={parseInt(props.item.user_relation_object.user_id) === parseInt(props.user.user_id) ? 'Cancel Request' : 'Reject Friend Request'}
                to="/" onClick={(e) => {
                e.preventDefault();
                props.isAuthenticated ? props.actionRejectRequest({
                    user_id: props.user.user_id,
                    related_user_id: props.item.user_id,
                    itemId: props.item.user_id
                }) : history.push('/register');
            }}><i className='fa fa-close'/></Link>;
        } else {
            reqBtn = <Fragment>
                <Link
                    style={{right: '60px'}}
                    title={'Accept Friend Request'}
                    to="/" onClick={(e) => {
                    e.preventDefault();
                    props.isAuthenticated ? props.actionAcceptFriendRequest({
                        user_id: props.user.user_id,
                        related_user_id: props.item.user_id,
                        itemId: props.item.user_id
                    }) : history.push('/register');
                }}><i className='fa fa-check text-dark'/></Link>
                <Link
                    title={parseInt(props.item.user_relation_object.user_id) === parseInt(props.user.user_id) ? 'Cancel Request' : 'Reject Friend Request'}
                    to="/" onClick={(e) => {
                    e.preventDefault();
                    props.isAuthenticated ? props.actionRejectRequest({
                        user_id: props.user.user_id,
                        related_user_id: props.item.user_id,
                        itemId: props.item.user_id
                    }) : history.push('/register');
                }}><i className='fa fa-close text-dark'/></Link>
            </Fragment>;
        }
    } else if (props.item.user_relation === 'NoRelation') {
        reqBtn = <Link to="/" onClick={(e) => {
            e.preventDefault();
            props.isAuthenticated ? props.actionSendFriendRequest(props.user.user_id, props.item.user_id) : history.push('/register');
        }}><i className='fa fa-user-plus'/></Link>;
    }
console.log(props.item)
    return (
        <div key={props.item.user_id} className="col-lg-3 col-sm-6">
            <div className="single-explore">
                <div className="thumb bg-white">
                    <div className='best-agent-profile-avatar'>
                        <img className={'home-agent-profile-pic '} src={props.item.profile_pic} alt="explore"/>

                    </div>

                    {parseInt(props.item.user_id) !== parseInt(props.user.user_id) && reqBtn}
                </div>
                <div className="details readeal-top">
                    <h4><Link
                        to={`/agent-profile/${props.item.user_id &&  props.item.user_id}`}>{`${props.item.first_name} ${props.item.last_name}`}</Link>
                    </h4>
                    <ul className="list">
                        <li className="d-flex align-items-center"><img src={iconOne} alt="icona"/>Listed Properties: {props.item.listed_properties}</li>
                        <li><i className="fa fa-user"/> Friends: {props.item.friend_count}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        agent: state.agent,
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated,
    };
};
export default connect(mapStateToProps, {
    actionSendFriendRequest,
    actionUnFriend,
    actionAcceptFriendRequest,
    actionRejectRequest
})(HomePageAgentCard);