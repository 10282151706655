import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {actionGetSubCategories} from "../../actions/propertyAction";
import Slider from "react-slick";
import PrevArrow from "../common/PrevArrow";
import NextArrow from "../common/NextArrow";


class SubCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: [],
            isPageLoaded: false
        }
    }

    componentDidMount() {
        this.props.actionGetSubCategories('');

    }

    render() {
        const {subCategories} = this.props;
        const settings = {
            dots: false,
            slidesToScroll: 1,
            speed: 400,
            loop: true,
            slidesToShow: 4,
            autoplay: false,
            prevArrow: <PrevArrow/>,
            nextArrow: <NextArrow/>,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]

        };
        return <div className="service-area sub-category-container h1-service-slider-area pt-lg-5 pt-3">
            <div className="container">
                <Slider {...settings}>
                    {subCategories.map((item, i) =>
                        <div key={i} className="item">
                            <div className="single-service text-center">
                                <div className="thumb double-img">
                                    <img src={item.image} alt="icons"/>
                                </div>
                                <div className="details readeal-top mt-4">
                                    <h4><Link
                                        to={`/property-listing?location=&subCategory_id=${item.sub_category_id}`}>{item.name}</Link>
                                    </h4>
                                    <Link className="readmore-btn" to={`/property-listing?location=&subCategory_id=${item.sub_category_id}`}>Explore<i
                                        className="la la-arrow-right"/></Link>
                                </div>
                            </div>
                        </div>
                    )}
                </Slider>
            </div>
        </div>


    }
}

const mapStateToProps = state => {
    return {
        subCategories: state.property.subCategories,
    };
};
export default connect(mapStateToProps, {
    actionGetSubCategories,
})(SubCategory)