import React, {Component} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import AgentPropertyList from "../section-components/agent-property";
import Footer from "../global-components/footer";
import {MY_PROPERTY} from "../../actions/types";

class MyProperty extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Navbar scenario={MY_PROPERTY}/>
                <PageHeader headertitle="My Properties"/>
                <AgentPropertyList showTabs={false} match={this.props.match}/>
                <Footer/>
            </div>
        );
    }
}

export default MyProperty;