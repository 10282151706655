import axios from 'axios';
import jwt_decode from 'jwt-decode';

const setAuthToken = token => {
    if (token) {
        localStorage.setItem('jwtToken', token);
        const decoded = jwt_decode(localStorage.jwtToken);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.defaults.headers.common['user_id'] = parseInt(decoded.uid);
    } else {
        delete axios.defaults.headers.common['Authorization'];
        delete axios.defaults.headers.common['user_id'];
        localStorage.removeItem('Authorization');
    }
};

export default setAuthToken;