import React, { Component, Fragment } from "react";
import n21 from "../../assets/img/nophoto.png";
import i1 from "../../assets/img/icons/1.png";
import placeHolder from "../../assets/img/Arizona prime logo.jpg";
import builderavtar from "../../assets/img/icons/5.png";
import PropertyListDetailsCard from "../property-listing/property_list_details_card";
import connect from "react-redux/es/connect/connect";
import {
  actionGetPropertyDetails,
  actionGetPropertyImages,
} from "../../actions/propertyAction";
import { IMAGE_BASE_URL } from "../../web-config";
import PrevArrow from "../common/PrevArrow";
import NextArrow from "../common/NextArrow";
import Slider from "react-slick/lib";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import serversideIssue from "../../assets/img/undraw_bug_fixing_oc7a.svg";
import PropertyDetailsLoaderCard from "../property-details/property-details-loader-card";
import jwt_decode from "jwt-decode";
import { actionSharePropertyUrl } from "../../actions/chatAction";

class PropertyDetailsSection extends Component {
  componentDidMount() {
    this.props.actionGetPropertyDetails(`property_id=${this.props.propertyId}`);
    /*this.props.actionGetPropertyImages(this.props.propertyId);*/

    const script = document.createElement("script");
    script.src = "../../assets/js/vendore.js";
    script.async = true;
    document.body.appendChild(script);
  }

  onContactAgent = (propertyId) => {
    console.log("sshare url ...", propertyId);
    this.props.actionSharePropertyUrl(window.location.href);
  };

  render() {
    const data = this.props.getPropertyDetails;
    const {
      title,
      address,
      country,
      state,
      city,
      list_date,
      description,
      disclaimer,
      bathrooms,
      bedrooms,
      super_area,
      carpet_area,
      land_area,
      booking_amount,
      car_parking,
      status,
      transaction_type,
      rera_id,
      furnished_status,
      flooring,
      age_of_property,
      floor_availability,
      units_on_floor,
      no_of_cabins,
      no_of_conf_room,
      pantry,
      fire_equipment,
      leed_certificate,
      authority_approval,
      boundary_wall,
      type_of_ownership,
      overlooking,
      no_of_open_sides,
      corner_plot,
      floor_allowed_for_construction,
      landmarks,
      facing,
      gated_colony,
      name_of_industrial_estate,
      amenities,
      area_unit_name,
      flat_type,
      builder_name,
      owner_name,
      home_size,
      image,
      agent_id,
      property_link,
      cooling,
      heating,
      year_built,
      lot,
      atrium,
      ceiling_Fan,
      central_vacuum,
      cfl_lights,
      counters_laminate,
      fireplace_gas,
      kit_din_combo,
      liv_din_combo,
      live_on_one_level,
      master_on_main,
      raised_ceilings_nine_plus_ft,
      smoke_detector,
      wash_dry_connection,
      window_features,
      dishwasher,
      disposal,
      microwave,
      range_electric,
      range_oven,
      refrigerator,
      deck_open,
      dog_run,
      driveway_circular,
      fence_backyard,
      landscaping_front,
      native_species,
      patio_covered,
      screens_sun_screens,
      sprinkler_drip,
      storm_gutters,
      xeriscaping,
      roads,
      carport_spaces,
      parking_features,
      parking_total,
      other_equipment,
      calculated_total_monthly_association_fees,
      annual_tax_amount,
      source_listing_status,
      county,
      directions,
      tax_year,
      source_property_type,
      exclusions,
      source_neighborhood,
      parcel_number,
      subdivision,
      zoning,
      lot_number,
      source_system_name,
      total_square_feet_living,
      construction_materials,
      property_age,
      roof,
      levels_or_stories,
      house_style,
      electric_information,
      sewer_information,
      utilities_for_property,
      services_included,
      tax_assessed_value,
      air_conditioning,
      bike_storage,
      business_center,
      clubhouse,
      doorman,
      elevator,
      fitness_center,
      grill,
      high_speed_internet_access,
      lounge,
      maid_service,
      maintenance_on_site,
      on_site_retail,
      package_service,
      picnic_area,
      pool,
      property_manager_on_site,
      range_property,
      roof_terrace,
      sundeck,
      washer_dryer,
      wheelchair_accessible,
      land_category,
      potential_use,
      land_features,
      mls_id,
      property_land_id,
      irrigation,
      land_improvement,
      building,
      utility_service,
      percent_leased,
      building_height,
      building_size,
      building_far,
      land_acres,
      land_assessment,
      total_assessment,
      banking,
      bus_line,
      signage,
      sale_conditions,
      lot_size,
      rentable_building_area,
      no_stories,
      parking_ratio,
      clear_ceiling_height,
      doors_loading,
      opportunity_zone,
      zoning_code,
      space,
      space_size,
      space_use,
      condition,
      available,
      sale_condition,
      property_subtype,
      loop_net_rating,
      tenancy,
      driveway_concrete,
      porch_covered,
      view,
      structure_type,
    } = data;
    const settings = {
      loop: true,
      autoplay: false,
      dots: false,
      slidesToScroll: 1,
      speed: 400,
      slidesToShow: 1,
      items: 1,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    };
    const { getPropertyDetailsLoader } = this.props;
    const user_id = this.props.user && this.props.user.user_id;

    const decoded = localStorage.jwtToken
      ? jwt_decode(localStorage.jwtToken)
      : "";
    return (
      <div
        className="property-details-area bg-gray "
        style={{ BackgroundColor: "#F6F7FB" }}
      >
        {/*{getPropertyDetailsLoader && <div className="row">*/}
        {/*<div className="text-center col-md-12 mt-5"><Spinner width={150}/></div>*/}
        {/*</div>}*/}
        {getPropertyDetailsLoader && (
          <div className="row">
            <div className="text-center col-12 my-5">
              <PropertyDetailsLoaderCard />
            </div>
          </div>
        )}

        {data && !data.length > 0 && (
          <Fragment>
            <div className="pd-top-80">
              <div className="container">
                <div className="row ">
                  <div className="col-xl-9 col-lg-8">
                    <Slider
                      {...settings}
                      className={"property-details-slider-vytech"}
                    >
                      {data.property_images &&
                        data.property_images.length < 1 && (
                          <div className="item">
                            <div className="thumb">
                              <img
                                className="h-75 w-100"
                                src={
                                  `${IMAGE_BASE_URL}uploads/${image}`
                                    ? image
                                    : placeHolder
                                }
                                alt={"propertyImage Default"}
                              />
                            </div>
                          </div>
                        )}
                      {data.property_images &&
                        data.property_images.length > 0 &&
                        data.property_images.map((val, i) => (
                          <div className="item" key={i}>
                            <div className="thumb">
                              <img
                                className="h-75 w-100"
                                src={`${IMAGE_BASE_URL}uploads/${val.image}`}
                                alt={"Proprty Multiple Images"}
                              />
                            </div>
                          </div>
                        ))}
                    </Slider>
                    <div className="property-details-slider-info d-none d-lg-block">
                      <h3 className="m-0 p-1">
                        <span>
                          {data.currency === "INR" && (
                            <i className={"fa fa-inr"} />
                          )}
                          {data.currency === "USD" && (
                            <i className={"fa fa-dollar"} />
                          )}
                          {data.price ? data.price : "0"}
                        </span>{" "}
                        <br /> <strong>{title} </strong>{" "}
                        <span className="text-primary">
                          {flat_type}{" "}
                          {carpet_area && `${carpet_area} ${area_unit_name}`}{" "}
                          {super_area && `${super_area} ${area_unit_name}`}{" "}
                          {land_area && `${land_area}${area_unit_name}`}
                        </span>
                      </h3>
                      <br />
                      <div className="media">
                        <div className="media-left ">
                          <img src={i1} alt={""} width={"15px"} />
                        </div>
                        <div className="media-body ml-2">
                          <h5 style={{ fontSize: "1em" }}>
                            {address} {country && `, ${country}`}{" "}
                            {state && `, ${state}`} {city && `, ${city}`}{" "}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="property-details-slider-info d-lg-none">
                      <h3 className="m-0 p-1">
                        <span>
                          {data.currency === "INR" && (
                            <i className={"fa fa-inr"} />
                          )}
                          {data.currency === "USD" && (
                            <i className={"fa fa-dollar"} />
                          )}
                          {data.price ? data.price : "0"}
                        </span>{" "}
                        <br /> <strong>{title} </strong>{" "}
                        <span className="text-primary">
                          {" "}
                          {flat_type}{" "}
                          {carpet_area && `${carpet_area} ${area_unit_name}`}{" "}
                          {super_area && `${super_area} ${area_unit_name}`}{" "}
                          {land_area && `${land_area}${area_unit_name}`}
                        </span>
                      </h3>
                      <br />
                      <div className="media">
                        <div className="media-left ">
                          <img src={i1} alt={""} width={"15px"} />
                        </div>
                        <div className="media-body ml-2">
                          <h5 style={{ fontSize: "1em" }}>
                            {address} {country && `, ${country}`}{" "}
                            {state && `, ${state}`} {city && `, ${city}`}{" "}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4">
                    <div className="widget widget-owner-info mt-lg-0 mt-5">
                      <div className="owner-info text-center">
                        <div className="thumb">
                          <img
                            src={
                              data.agent_profile_pic
                                ? data.agent_profile_pic
                                : n21
                            }
                            alt={""}
                            height={"72px"}
                            width={"72px"}
                          />
                        </div>
                        <div className="details">
                          <h6>
                            {data.agent_first_name ||
                              (data.agent_last_name &&
                                `${data.agent_first_name}  ${data.agent_last_name}`)}
                          </h6>
                          <span className="designation font-weight-bold h4">
                            <i
                              className="fa fa-user-secret"
                              style={{ color: "#fda94f", fontSize: "20px" }}
                            />{" "}
                            Agent
                          </span>
                          {/*<p className="reviews"><i className="fa fa-star"/><span>4.8</span> 70 Review</p>*/}
                        </div>
                      </div>
                      <div className="contact text-center">
                        {parseInt(decoded.uid) !== parseInt(agent_id) && (
                          <Link
                            className="btn btn-yellow"
                            to={
                              this.props.isAuthenticated
                                ? `/private-chat/${data.agent_id}`
                                : "/register"
                            }
                            onClick={this.onContactAgent}
                          >
                            Contact Agent
                          </Link>
                        )}
                      </div>
                      <div className="contact-info">
                        <h6 className="mb-3">Contact Info</h6>
                        <div className="media">
                          <div className="media-left">
                            <img src={i1} alt={""} />
                          </div>
                          <div className="media-body">
                            <p>Address</p>
                            <span>
                              {data.agent_city}{" "}
                              {data.agent_state && `, ${data.agent_state}`}{" "}
                              {data.agent_country && `, ${data.agent_country}`}
                            </span>
                          </div>
                        </div>
                        <div className="media">
                          <div className="media-left">
                            <i className="fa fa-phone" />
                          </div>
                          <div className="media-body">
                            <p>Phone</p>
                            <span>
                              {data.agent_phone
                                ? data.agent_phone
                                : "+00 111 222 333"}
                            </span>
                          </div>
                        </div>
                        <div className="media mb-0">
                          <div className="media-left">
                            <i className="fa fa-envelope" />
                          </div>
                          <div className="media-body">
                            <p>Email</p>
                            <span>
                              {data.agent_email
                                ? data.agent_email
                                : "info@example.com"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row pd-top-60">
                <div className="col-lg-9">
                  {description && (
                    <div className="property-news-single-card style-two border-bottom-yellow">
                      <h4>Description</h4>
                      <p className="h6">{description}</p>
                    </div>
                  )}
                  {builder_name && (
                    <div className="property-news-single-card style-two border-bottom-yellow">
                      <h4>Builder Name</h4>
                      <div className="d-flex ">
                        <img
                          src={builderavtar}
                          alt="Builder Avtar "
                          className="mr-1"
                        />
                        <p className="my-auto h6 font-weight-bold ml-1">
                          {builder_name}
                        </p>
                      </div>
                    </div>
                  )}
                  {owner_name && (
                    <div className="property-news-single-card style-two border-bottom-yellow">
                      <h4>Owner Name</h4>
                      <div className="d-flex ">
                        <img
                          src={builderavtar}
                          alt="Builder Avtar"
                          className="mr-1"
                        />
                        <p className="my-auto h5 font-weight-bold ml-1">
                          {owner_name}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="property-info mb-5">
                    <div className="row">
                      {list_date && (
                        <PropertyListDetailsCard
                          title={"List Date"}
                          icon={"fa fa-calendar"}
                          data={list_date ? list_date : "00-00-0000"}
                        />
                      )}

                      {bedrooms && (
                        <PropertyListDetailsCard
                          title={"Bedrooms"}
                          icon={"fa fa-bed"}
                          data={bedrooms ? bedrooms : "0"}
                        />
                      )}

                      {bathrooms && (
                        <PropertyListDetailsCard
                          title={"Bathrooms"}
                          icon={"fa fa-bath"}
                          data={bathrooms ? bathrooms : ""}
                        />
                      )}

                      {super_area && (
                        <PropertyListDetailsCard
                          title={"Super Area"}
                          icon={"fa fa-codepen"}
                          data={
                            `${super_area} ${area_unit_name}`
                              ? `${super_area} ${area_unit_name}`
                              : `0 sq. ft.`
                          }
                        />
                      )}

                      {carpet_area && (
                        <PropertyListDetailsCard
                          title={"Carpet Area"}
                          icon={"fa fa-codepen"}
                          data={
                            `${carpet_area} ${area_unit_name && area_unit_name}`
                              ? `${carpet_area} ${
                                  area_unit_name && area_unit_name
                                }`
                              : `0 sq. ft.`
                          }
                        />
                      )}

                      {land_area && (
                        <PropertyListDetailsCard
                          title={"Area"}
                          icon={"fa fa-codepen"}
                          data={
                            `${land_area}`
                              ? `${land_area} ${area_unit_name}`
                              : "0 sq. ft."
                          }
                        />
                      )}
                      {home_size && (
                        <PropertyListDetailsCard
                          title={"Home Size"}
                          icon={"fa fa-arrows-alt"}
                          data={
                            `${home_size}`
                              ? `${home_size} (Sq Feet)`
                              : "0 sq. ft."
                          }
                        />
                      )}

                      {car_parking && (
                        <PropertyListDetailsCard
                          title={"Parking"}
                          icon={"fa fa-car"}
                          data={car_parking ? car_parking : "0"}
                        />
                      )}
                      {booking_amount && (
                        <PropertyListDetailsCard
                          title={"Booking Amount"}
                          icon={"fa fa-moneyaddadd"}
                          data={booking_amount ? booking_amount : "0"}
                        />
                      )}

                      {status && (
                        <PropertyListDetailsCard
                          title={"Status"}
                          icon={"fa fa-ban"}
                          data={status ? status : ""}
                        />
                      )}

                      {transaction_type && (
                        <PropertyListDetailsCard
                          title={"Transaction Type"}
                          icon={"fa fa-recycle"}
                          data={transaction_type ? transaction_type : ""}
                        />
                      )}

                      {rera_id && (
                        <PropertyListDetailsCard
                          title={"RERA ID"}
                          icon={"fa fa-home"}
                          data={rera_id ? rera_id : ""}
                        />
                      )}

                      {furnished_status && (
                        <PropertyListDetailsCard
                          title={"Furnished Status"}
                          icon={"fa fa-inbox"}
                          data={furnished_status ? furnished_status : ""}
                        />
                      )}

                      {flooring && (
                        <PropertyListDetailsCard
                          title={"Flooring"}
                          icon={"fa fa-clone"}
                          data={flooring ? flooring : ""}
                        />
                      )}

                      {age_of_property && (
                        <PropertyListDetailsCard
                          title={"Age of Property"}
                          icon={"fa fa-calendar-check-o"}
                          data={age_of_property ? age_of_property : ""}
                        />
                      )}

                      {floor_availability && (
                        <PropertyListDetailsCard
                          title={"Floor availability"}
                          icon={"fa fa-building"}
                          data={floor_availability ? floor_availability : ""}
                        />
                      )}

                      {units_on_floor && (
                        <PropertyListDetailsCard
                          title={"Units on Floor"}
                          icon={"fa fa-building-o"}
                          data={units_on_floor ? units_on_floor : ""}
                        />
                      )}

                      {no_of_cabins && (
                        <PropertyListDetailsCard
                          title={"Number Of Cabins"}
                          icon={"fa fa-square-o"}
                          data={no_of_cabins ? no_of_cabins : ""}
                        />
                      )}

                      {no_of_conf_room && (
                        <PropertyListDetailsCard
                          title={"Number Of Conference rooms"}
                          icon={"fa fa-bullhorn"}
                          data={no_of_conf_room ? no_of_conf_room : ""}
                        />
                      )}

                      {pantry && (
                        <PropertyListDetailsCard
                          title={"Pantry"}
                          icon={"fa fa-hdd-o"}
                          data={pantry ? pantry : ""}
                        />
                      )}

                      {fire_equipment && (
                        <PropertyListDetailsCard
                          title={"Fire Fighting Equipment"}
                          icon={"fa fa-fire-extinguisher"}
                          data={fire_equipment ? fire_equipment : ""}
                        />
                      )}

                      {leed_certificate && (
                        <PropertyListDetailsCard
                          title={"Leed Certificate"}
                          icon={"fa fa-list-alt"}
                          data={leed_certificate ? leed_certificate : ""}
                        />
                      )}

                      {authority_approval && (
                        <PropertyListDetailsCard
                          title={"Authority Approval"}
                          icon={"fa fa-check-square"}
                          data={authority_approval ? authority_approval : ""}
                        />
                      )}
                      {boundary_wall && (
                        <PropertyListDetailsCard
                          title={"Boundary wall"}
                          icon={"fa fa-external-link"}
                          data={boundary_wall ? boundary_wall : ""}
                        />
                      )}

                      {type_of_ownership && (
                        <PropertyListDetailsCard
                          title={"Type of Ownership"}
                          icon={"fa fa-users"}
                          data={type_of_ownership ? type_of_ownership : ""}
                        />
                      )}

                      {overlooking && (
                        <PropertyListDetailsCard
                          title={"Overlooking"}
                          icon={"fa fa-snowflake-o"}
                          data={overlooking ? overlooking : ""}
                        />
                      )}

                      {no_of_open_sides && (
                        <PropertyListDetailsCard
                          title={"Number Of Open Sides"}
                          icon={"fa fa-window-restore"}
                          data={no_of_open_sides ? no_of_open_sides : ""}
                        />
                      )}

                      {corner_plot && (
                        <PropertyListDetailsCard
                          title={"Corner Plot"}
                          icon={"fa fa-columns"}
                          data={corner_plot ? corner_plot : ""}
                        />
                      )}
                      {floor_allowed_for_construction && (
                        <PropertyListDetailsCard
                          title={"Floor allowed for Construction"}
                          icon={"fa fa-cogs"}
                          data={
                            floor_allowed_for_construction
                              ? floor_allowed_for_construction
                              : ""
                          }
                        />
                      )}

                      {landmarks && (
                        <PropertyListDetailsCard
                          title={"Landmark"}
                          icon={"fa fa-location-arrow"}
                          data={landmarks ? landmarks : ""}
                        />
                      )}

                      {facing && (
                        <PropertyListDetailsCard
                          title={"Facing"}
                          icon={"fa fa-compass"}
                          data={facing ? facing : ""}
                        />
                      )}

                      {gated_colony && (
                        <PropertyListDetailsCard
                          title={"Gated Colony"}
                          icon={"fa fa-hashtag"}
                          data={gated_colony ? gated_colony : ""}
                        />
                      )}

                      {name_of_industrial_estate && (
                        <PropertyListDetailsCard
                          title={"Name Of Industrial Estate"}
                          icon={"fa-arrow-right"}
                          data={
                            name_of_industrial_estate
                              ? name_of_industrial_estate
                              : ""
                          }
                        />
                      )}

                      {/*New fields Arizona*/}

                      {cooling && (
                        <PropertyListDetailsCard
                          title={"Cooling"}
                          icon={"fa-arrow-right"}
                          data={cooling ? cooling : ""}
                        />
                      )}

                      {heating && (
                        <PropertyListDetailsCard
                          title={"Heating"}
                          icon={"fa-arrow-right"}
                          data={heating ? heating : ""}
                        />
                      )}

                      {year_built && (
                        <PropertyListDetailsCard
                          title={"Year built"}
                          icon={"fa-arrow-right"}
                          data={year_built ? year_built : ""}
                        />
                      )}

                      {lot && (
                        <PropertyListDetailsCard
                          title={"Lot(sqrt)"}
                          icon={"fa-arrow-right"}
                          data={lot ? lot : ""}
                        />
                      )}

                      {atrium && (
                        <PropertyListDetailsCard
                          title={"Atrium"}
                          icon={"fa-arrow-right"}
                          data={atrium ? atrium : ""}
                        />
                      )}

                      {ceiling_Fan && (
                        <PropertyListDetailsCard
                          title={"Ceiling Fan(s)"}
                          icon={"fa-arrow-right"}
                          data={ceiling_Fan ? ceiling_Fan : ""}
                        />
                      )}

                      {central_vacuum && (
                        <PropertyListDetailsCard
                          title={"Central Vacuum"}
                          icon={"fa-arrow-right"}
                          data={central_vacuum ? central_vacuum : ""}
                        />
                      )}

                      {cfl_lights && (
                        <PropertyListDetailsCard
                          title={"CFL Lights"}
                          icon={"fa-arrow-right"}
                          data={cfl_lights ? cfl_lights : ""}
                        />
                      )}

                      {counters_laminate && (
                        <PropertyListDetailsCard
                          title={"Counters-Laminate"}
                          icon={"fa-arrow-right"}
                          data={counters_laminate ? counters_laminate : ""}
                        />
                      )}

                      {fireplace_gas && (
                        <PropertyListDetailsCard
                          title={"Fireplace-Gas"}
                          icon={"fa-arrow-right"}
                          data={fireplace_gas ? fireplace_gas : ""}
                        />
                      )}

                      {kit_din_combo && (
                        <PropertyListDetailsCard
                          title={"Kit/Din Combo"}
                          icon={"fa-arrow-right"}
                          data={kit_din_combo ? kit_din_combo : ""}
                        />
                      )}

                      {liv_din_combo && (
                        <PropertyListDetailsCard
                          title={"Liv/Din Combo"}
                          icon={"fa-arrow-right"}
                          data={liv_din_combo ? liv_din_combo : ""}
                        />
                      )}

                      {live_on_one_level && (
                        <PropertyListDetailsCard
                          title={"Live on One Level"}
                          icon={"fa-arrow-right"}
                          data={live_on_one_level ? live_on_one_level : ""}
                        />
                      )}

                      {master_on_main && (
                        <PropertyListDetailsCard
                          title={"Master On Main"}
                          icon={"fa-arrow-right"}
                          data={master_on_main ? master_on_main : ""}
                        />
                      )}

                      {raised_ceilings_nine_plus_ft && (
                        <PropertyListDetailsCard
                          title={"Raised Ceilings 9+ft"}
                          icon={"fa-arrow-right"}
                          data={
                            raised_ceilings_nine_plus_ft
                              ? raised_ceilings_nine_plus_ft
                              : ""
                          }
                        />
                      )}

                      {smoke_detector && (
                        <PropertyListDetailsCard
                          title={"Smoke Detector(s)"}
                          icon={"fa-arrow-right"}
                          data={smoke_detector ? smoke_detector : ""}
                        />
                      )}

                      {wash_dry_connection && (
                        <PropertyListDetailsCard
                          title={"Wash/Dry Connection"}
                          icon={"fa-arrow-right"}
                          data={wash_dry_connection ? wash_dry_connection : ""}
                        />
                      )}

                      {window_features && (
                        <PropertyListDetailsCard
                          title={"Window Features"}
                          icon={"fa-arrow-right"}
                          data={window_features ? window_features : ""}
                        />
                      )}

                      {dishwasher && (
                        <PropertyListDetailsCard
                          title={"Dishwasher"}
                          icon={"fa-arrow-right"}
                          data={dishwasher ? dishwasher : ""}
                        />
                      )}

                      {disposal && (
                        <PropertyListDetailsCard
                          title={"Disposal"}
                          icon={"fa-arrow-right"}
                          data={disposal ? disposal : ""}
                        />
                      )}

                      {microwave && (
                        <PropertyListDetailsCard
                          title={"Microwave"}
                          icon={"fa-arrow-right"}
                          data={microwave ? microwave : ""}
                        />
                      )}

                      {range_electric && (
                        <PropertyListDetailsCard
                          title={"Range - Electric"}
                          icon={"fa-arrow-right"}
                          data={range_electric ? range_electric : ""}
                        />
                      )}

                      {range_oven && (
                        <PropertyListDetailsCard
                          title={"Range/Oven"}
                          icon={"fa-arrow-right"}
                          data={range_oven ? range_oven : ""}
                        />
                      )}

                      {refrigerator && (
                        <PropertyListDetailsCard
                          title={"Refrigerator"}
                          icon={"fa-arrow-right"}
                          data={refrigerator ? refrigerator : ""}
                        />
                      )}

                      {deck_open && (
                        <PropertyListDetailsCard
                          title={"Deck-Open"}
                          icon={"fa-arrow-right"}
                          data={deck_open ? deck_open : ""}
                        />
                      )}

                      {dog_run && (
                        <PropertyListDetailsCard
                          title={"Dog Run"}
                          icon={"fa-arrow-right"}
                          data={dog_run ? dog_run : ""}
                        />
                      )}

                      {driveway_circular && (
                        <PropertyListDetailsCard
                          title={"Driveway-Circular"}
                          icon={"fa-arrow-right"}
                          data={driveway_circular ? driveway_circular : ""}
                        />
                      )}

                      {driveway_concrete && (
                        <PropertyListDetailsCard
                          title={"Driveway-Concrete"}
                          icon={"fa-arrow-right"}
                          data={driveway_concrete ? driveway_concrete : ""}
                        />
                      )}

                      {fence_backyard && (
                        <PropertyListDetailsCard
                          title={"Fence - Backyard"}
                          icon={"fa-arrow-right"}
                          data={fence_backyard ? fence_backyard : ""}
                        />
                      )}

                      {landscaping_front && (
                        <PropertyListDetailsCard
                          title={"Landscaping-Front"}
                          icon={"fa-arrow-right"}
                          data={landscaping_front ? landscaping_front : ""}
                        />
                      )}

                      {native_species && (
                        <PropertyListDetailsCard
                          title={"Native Species"}
                          icon={"fa-arrow-right"}
                          data={native_species ? native_species : ""}
                        />
                      )}

                      {patio_covered && (
                        <PropertyListDetailsCard
                          title={"Patio - Covered"}
                          icon={"fa-arrow-right"}
                          data={patio_covered ? patio_covered : ""}
                        />
                      )}

                      {porch_covered && (
                        <PropertyListDetailsCard
                          title={"Porch - Covered"}
                          icon={"fa-arrow-right"}
                          data={porch_covered ? porch_covered : ""}
                        />
                      )}

                      {screens_sun_screens && (
                        <PropertyListDetailsCard
                          title={"Screens/Sun Screens"}
                          icon={"fa-arrow-right"}
                          data={screens_sun_screens ? screens_sun_screens : ""}
                        />
                      )}

                      {sprinkler_drip && (
                        <PropertyListDetailsCard
                          title={"Sprinkler/Drip"}
                          icon={"fa-arrow-right"}
                          data={sprinkler_drip ? sprinkler_drip : ""}
                        />
                      )}

                      {storm_gutters && (
                        <PropertyListDetailsCard
                          title={"Storm Gutters"}
                          icon={"fa-arrow-right"}
                          data={storm_gutters ? storm_gutters : ""}
                        />
                      )}

                      {xeriscaping && (
                        <PropertyListDetailsCard
                          title={"Xeriscaping"}
                          icon={"fa-arrow-right"}
                          data={xeriscaping ? xeriscaping : ""}
                        />
                      )}

                      {roads && (
                        <PropertyListDetailsCard
                          title={"Roads"}
                          icon={"fa-arrow-right"}
                          data={roads ? roads : ""}
                        />
                      )}

                      {carport_spaces && (
                        <PropertyListDetailsCard
                          title={"Carport Spaces"}
                          icon={"fa-arrow-right"}
                          data={carport_spaces ? carport_spaces : ""}
                        />
                      )}

                      {parking_features && (
                        <PropertyListDetailsCard
                          title={"Parking Features"}
                          icon={"fa-arrow-right"}
                          data={parking_features ? parking_features : ""}
                        />
                      )}

                      {parking_total && (
                        <PropertyListDetailsCard
                          title={"Parking Total"}
                          icon={"fa-arrow-right"}
                          data={parking_total ? parking_total : ""}
                        />
                      )}

                      {view && (
                        <PropertyListDetailsCard
                          title={"View"}
                          icon={"fa-arrow-right"}
                          data={view ? view : ""}
                        />
                      )}

                      {other_equipment && (
                        <PropertyListDetailsCard
                          title={"Other Equipment"}
                          icon={"fa-arrow-right"}
                          data={other_equipment ? other_equipment : ""}
                        />
                      )}

                      {calculated_total_monthly_association_fees && (
                        <PropertyListDetailsCard
                          title={"Calculated Total Monthly Association Fees"}
                          icon={"fa-arrow-right"}
                          data={
                            calculated_total_monthly_association_fees
                              ? calculated_total_monthly_association_fees
                              : ""
                          }
                        />
                      )}

                      {annual_tax_amount && (
                        <PropertyListDetailsCard
                          title={"Annual Tax Amount"}
                          icon={"fa-arrow-right"}
                          data={annual_tax_amount ? annual_tax_amount : ""}
                        />
                      )}

                      {source_listing_status && (
                        <PropertyListDetailsCard
                          title={"Source Listing Status"}
                          icon={"fa-arrow-right"}
                          data={
                            source_listing_status ? source_listing_status : ""
                          }
                        />
                      )}

                      {county && (
                        <PropertyListDetailsCard
                          title={"County"}
                          icon={"fa-arrow-right"}
                          data={county ? county : ""}
                        />
                      )}
                      {directions && (
                        <PropertyListDetailsCard
                          title={"Directions"}
                          icon={"fa-arrow-right"}
                          data={directions ? directions : ""}
                        />
                      )}

                      {tax_year && (
                        <PropertyListDetailsCard
                          title={"Tax Year"}
                          icon={"fa-arrow-right"}
                          data={tax_year ? tax_year : ""}
                        />
                      )}

                      {source_property_type && (
                        <PropertyListDetailsCard
                          title={"Source Property Type"}
                          icon={"fa-arrow-right"}
                          data={
                            source_property_type ? source_property_type : ""
                          }
                        />
                      )}

                      {exclusions && (
                        <PropertyListDetailsCard
                          title={"Exclusions"}
                          icon={"fa-arrow-right"}
                          data={exclusions ? exclusions : ""}
                        />
                      )}

                      {source_neighborhood && (
                        <PropertyListDetailsCard
                          title={"Source Neighborhood"}
                          icon={"fa-arrow-right"}
                          data={source_neighborhood ? source_neighborhood : ""}
                        />
                      )}

                      {parcel_number && (
                        <PropertyListDetailsCard
                          title={"Parcel Number"}
                          icon={"fa-arrow-right"}
                          data={parcel_number ? parcel_number : ""}
                        />
                      )}

                      {subdivision && (
                        <PropertyListDetailsCard
                          title={"Subdivision"}
                          icon={"fa-arrow-right"}
                          data={subdivision ? subdivision : ""}
                        />
                      )}

                      {zoning && (
                        <PropertyListDetailsCard
                          title={"Zoning"}
                          icon={"fa-arrow-right"}
                          data={zoning ? zoning : ""}
                        />
                      )}

                      {lot_number && (
                        <PropertyListDetailsCard
                          title={"Lot Number"}
                          icon={"fa-arrow-right"}
                          data={lot_number ? lot_number : ""}
                        />
                      )}

                      {source_system_name && (
                        <PropertyListDetailsCard
                          title={"Source System Name"}
                          icon={"fa-arrow-right"}
                          data={source_system_name ? source_system_name : ""}
                        />
                      )}

                      {total_square_feet_living && (
                        <PropertyListDetailsCard
                          title={"Total Square Feet Living"}
                          icon={"fa-arrow-right"}
                          data={
                            total_square_feet_living
                              ? total_square_feet_living
                              : ""
                          }
                        />
                      )}

                      {year_built && (
                        <PropertyListDetailsCard
                          title={"Year Built"}
                          icon={"fa-arrow-right"}
                          data={year_built ? year_built : ""}
                        />
                      )}

                      {construction_materials && (
                        <PropertyListDetailsCard
                          title={"Construction Materials"}
                          icon={"fa-arrow-right"}
                          data={
                            construction_materials ? construction_materials : ""
                          }
                        />
                      )}

                      {property_age && (
                        <PropertyListDetailsCard
                          title={"Property Age"}
                          icon={"fa-arrow-right"}
                          data={property_age ? property_age : ""}
                        />
                      )}

                      {roof && (
                        <PropertyListDetailsCard
                          title={"Roof"}
                          icon={"fa-arrow-right"}
                          data={roof ? roof : ""}
                        />
                      )}

                      {levels_or_stories && (
                        <PropertyListDetailsCard
                          title={"Levels or Stories"}
                          icon={"fa-arrow-right"}
                          data={levels_or_stories ? levels_or_stories : ""}
                        />
                      )}

                      {structure_type && (
                        <PropertyListDetailsCard
                          title={"Structure Type"}
                          icon={"fa-arrow-right"}
                          data={structure_type ? structure_type : ""}
                        />
                      )}

                      {house_style && (
                        <PropertyListDetailsCard
                          title={"House Style"}
                          icon={"fa-arrow-right"}
                          data={house_style ? house_style : ""}
                        />
                      )}

                      {electric_information && (
                        <PropertyListDetailsCard
                          title={"Electric information"}
                          icon={"fa-arrow-right"}
                          data={
                            electric_information ? electric_information : ""
                          }
                        />
                      )}

                      {sewer_information && (
                        <PropertyListDetailsCard
                          title={"Sewer information"}
                          icon={"fa-arrow-right"}
                          data={sewer_information ? sewer_information : ""}
                        />
                      )}

                      {utilities_for_property && (
                        <PropertyListDetailsCard
                          title={"Utilities for property"}
                          icon={"fa-arrow-right"}
                          data={
                            utilities_for_property ? utilities_for_property : ""
                          }
                        />
                      )}

                      {services_included && (
                        <PropertyListDetailsCard
                          title={"Services included"}
                          icon={"fa-arrow-right"}
                          data={services_included ? services_included : ""}
                        />
                      )}

                      {tax_assessed_value && (
                        <PropertyListDetailsCard
                          title={"Tax assessed value"}
                          icon={"fa-arrow-right"}
                          data={tax_assessed_value ? tax_assessed_value : ""}
                        />
                      )}

                      {annual_tax_amount && (
                        <PropertyListDetailsCard
                          title={"Annual tax amount"}
                          icon={"fa-arrow-right"}
                          data={annual_tax_amount ? annual_tax_amount : ""}
                        />
                      )}

                      {air_conditioning && (
                        <PropertyListDetailsCard
                          title={"Air Conditioning"}
                          icon={"fa-arrow-right"}
                          data={air_conditioning ? air_conditioning : ""}
                        />
                      )}

                      {bike_storage && (
                        <PropertyListDetailsCard
                          title={"Bike Storage"}
                          icon={"fa-arrow-right"}
                          data={bike_storage ? bike_storage : ""}
                        />
                      )}

                      {business_center && (
                        <PropertyListDetailsCard
                          title={"Business Center"}
                          icon={"fa-arrow-right"}
                          data={business_center ? business_center : ""}
                        />
                      )}

                      {clubhouse && (
                        <PropertyListDetailsCard
                          title={"Clubhouse"}
                          icon={"fa-arrow-right"}
                          data={clubhouse ? clubhouse : ""}
                        />
                      )}

                      {dishwasher && (
                        <PropertyListDetailsCard
                          title={"Dishwasher"}
                          icon={"fa-arrow-right"}
                          data={dishwasher ? dishwasher : ""}
                        />
                      )}

                      {disposal && (
                        <PropertyListDetailsCard
                          title={"Disposal"}
                          icon={"fa-arrow-right"}
                          data={disposal ? disposal : ""}
                        />
                      )}

                      {doorman && (
                        <PropertyListDetailsCard
                          title={"Doorman"}
                          icon={"fa-arrow-right"}
                          data={doorman ? doorman : ""}
                        />
                      )}

                      {elevator && (
                        <PropertyListDetailsCard
                          title={"Elevator"}
                          icon={"fa-arrow-right"}
                          data={elevator ? elevator : ""}
                        />
                      )}

                      {fitness_center && (
                        <PropertyListDetailsCard
                          title={"Fitness Center"}
                          icon={"fa-arrow-right"}
                          data={fitness_center ? fitness_center : ""}
                        />
                      )}

                      {grill && (
                        <PropertyListDetailsCard
                          title={"Grill"}
                          icon={"fa-arrow-right"}
                          data={grill ? grill : ""}
                        />
                      )}

                      {heating && (
                        <PropertyListDetailsCard
                          title={"Heating"}
                          icon={"fa-arrow-right"}
                          data={heating ? heating : ""}
                        />
                      )}

                      {high_speed_internet_access && (
                        <PropertyListDetailsCard
                          title={"High Speed Internet Access"}
                          icon={"fa-arrow-right"}
                          data={
                            high_speed_internet_access
                              ? high_speed_internet_access
                              : ""
                          }
                        />
                      )}

                      {lounge && (
                        <PropertyListDetailsCard
                          title={"Lounge"}
                          icon={"fa-arrow-right"}
                          data={lounge ? lounge : ""}
                        />
                      )}

                      {maid_service && (
                        <PropertyListDetailsCard
                          title={"Maid Service"}
                          icon={"fa-arrow-right"}
                          data={maid_service ? maid_service : ""}
                        />
                      )}

                      {maintenance_on_site && (
                        <PropertyListDetailsCard
                          title={"Maintenance on site"}
                          icon={"fa-arrow-right"}
                          data={maintenance_on_site ? maintenance_on_site : ""}
                        />
                      )}

                      {microwave && (
                        <PropertyListDetailsCard
                          title={"Microwave"}
                          icon={"fa-arrow-right"}
                          data={microwave ? microwave : ""}
                        />
                      )}

                      {on_site_retail && (
                        <PropertyListDetailsCard
                          title={"On-Site Retail"}
                          icon={"fa-arrow-right"}
                          data={on_site_retail ? on_site_retail : ""}
                        />
                      )}

                      {package_service && (
                        <PropertyListDetailsCard
                          title={"Package Service"}
                          icon={"fa-arrow-right"}
                          data={package_service ? package_service : ""}
                        />
                      )}

                      {picnic_area && (
                        <PropertyListDetailsCard
                          title={"Picnic Area"}
                          icon={"fa-arrow-right"}
                          data={picnic_area ? picnic_area : ""}
                        />
                      )}

                      {pool && (
                        <PropertyListDetailsCard
                          title={"Pool"}
                          icon={"fa-arrow-right"}
                          data={pool ? pool : ""}
                        />
                      )}

                      {property_manager_on_site && (
                        <PropertyListDetailsCard
                          title={"Property Manager on Site"}
                          icon={"fa-arrow-right"}
                          data={
                            property_manager_on_site
                              ? property_manager_on_site
                              : ""
                          }
                        />
                      )}

                      {range_property && (
                        <PropertyListDetailsCard
                          title={"Range"}
                          icon={"fa-arrow-right"}
                          data={range_property ? range_property : ""}
                        />
                      )}

                      {roof_terrace && (
                        <PropertyListDetailsCard
                          title={"Roof Terrace"}
                          icon={"fa-arrow-right"}
                          data={roof_terrace ? roof_terrace : ""}
                        />
                      )}

                      {sundeck && (
                        <PropertyListDetailsCard
                          title={"Sundeck"}
                          icon={"fa-arrow-right"}
                          data={sundeck ? sundeck : ""}
                        />
                      )}

                      {washer_dryer && (
                        <PropertyListDetailsCard
                          title={"Washer/Dryer"}
                          icon={"fa-arrow-right"}
                          data={washer_dryer ? washer_dryer : ""}
                        />
                      )}

                      {wheelchair_accessible && (
                        <PropertyListDetailsCard
                          title={"Wheelchair Accessible"}
                          icon={"fa-arrow-right"}
                          data={
                            wheelchair_accessible ? wheelchair_accessible : ""
                          }
                        />
                      )}

                      {land_category && (
                        <PropertyListDetailsCard
                          title={"Land Category"}
                          icon={"fa-arrow-right"}
                          data={land_category ? land_category : ""}
                        />
                      )}

                      {potential_use && (
                        <PropertyListDetailsCard
                          title={"Potential Use"}
                          icon={"fa-arrow-right"}
                          data={potential_use ? potential_use : ""}
                        />
                      )}

                      {land_features && (
                        <PropertyListDetailsCard
                          title={"Land Features"}
                          icon={"fa-arrow-right"}
                          data={land_features ? land_features : ""}
                        />
                      )}

                      {mls_id && (
                        <PropertyListDetailsCard
                          title={"MLS ID"}
                          icon={"fa-arrow-right"}
                          data={mls_id ? mls_id : ""}
                        />
                      )}

                      {property_land_id && (
                        <PropertyListDetailsCard
                          title={"Property Land ID"}
                          icon={"fa-arrow-right"}
                          data={property_land_id ? property_land_id : ""}
                        />
                      )}

                      {irrigation && (
                        <PropertyListDetailsCard
                          title={"Irrigation"}
                          icon={"fa-arrow-right"}
                          data={irrigation ? irrigation : ""}
                        />
                      )}

                      {land_improvement && (
                        <PropertyListDetailsCard
                          title={"Land Improvements"}
                          icon={"fa-arrow-right"}
                          data={land_improvement ? land_improvement : ""}
                        />
                      )}

                      {building && (
                        <PropertyListDetailsCard
                          title={"Building"}
                          icon={"fa-arrow-right"}
                          data={building ? building : ""}
                        />
                      )}

                      {utility_service && (
                        <PropertyListDetailsCard
                          title={"Utility Services"}
                          icon={"fa-arrow-right"}
                          data={utility_service ? utility_service : ""}
                        />
                      )}

                      {percent_leased && (
                        <PropertyListDetailsCard
                          title={"Percent Leased"}
                          icon={"fa-arrow-right"}
                          data={percent_leased ? percent_leased : ""}
                        />
                      )}

                      {building_height && (
                        <PropertyListDetailsCard
                          title={"Percent Leased"}
                          icon={"fa-arrow-right"}
                          data={building_height ? building_height : ""}
                        />
                      )}

                      {building_size && (
                        <PropertyListDetailsCard
                          title={"Building Size"}
                          icon={"fa-arrow-right"}
                          data={building_size ? building_size : ""}
                        />
                      )}

                      {building_far && (
                        <PropertyListDetailsCard
                          title={"Building Far"}
                          icon={"fa-arrow-right"}
                          data={building_far ? building_far : ""}
                        />
                      )}

                      {land_acres && (
                        <PropertyListDetailsCard
                          title={"Land Acres"}
                          icon={"fa-arrow-right"}
                          data={land_acres ? land_acres : ""}
                        />
                      )}

                      {land_assessment && (
                        <PropertyListDetailsCard
                          title={"Land Assessment"}
                          icon={"fa-arrow-right"}
                          data={land_assessment ? land_assessment : ""}
                        />
                      )}

                      {total_assessment && (
                        <PropertyListDetailsCard
                          title={"Total Assessment"}
                          icon={"fa-arrow-right"}
                          data={total_assessment ? total_assessment : ""}
                        />
                      )}

                      {banking && (
                        <PropertyListDetailsCard
                          title={"Banking"}
                          icon={"fa-arrow-right"}
                          data={banking ? banking : ""}
                        />
                      )}

                      {bus_line && (
                        <PropertyListDetailsCard
                          title={"Bus Line"}
                          icon={"fa-arrow-right"}
                          data={bus_line ? bus_line : ""}
                        />
                      )}

                      {signage && (
                        <PropertyListDetailsCard
                          title={"Signage"}
                          icon={"fa-arrow-right"}
                          data={signage ? signage : ""}
                        />
                      )}

                      {sale_conditions && (
                        <PropertyListDetailsCard
                          title={"Sale Conditions"}
                          icon={"fa-arrow-right"}
                          data={sale_conditions ? sale_conditions : ""}
                        />
                      )}

                      {lot_size && (
                        <PropertyListDetailsCard
                          title={"LOT Size"}
                          icon={"fa-arrow-right"}
                          data={lot_size ? lot_size : ""}
                        />
                      )}

                      {rentable_building_area && (
                        <PropertyListDetailsCard
                          title={"Rentable Building Area"}
                          icon={"fa-arrow-right"}
                          data={
                            rentable_building_area ? rentable_building_area : ""
                          }
                        />
                      )}

                      {no_stories && (
                        <PropertyListDetailsCard
                          title={"No Stories"}
                          icon={"fa-arrow-right"}
                          data={no_stories ? no_stories : ""}
                        />
                      )}

                      {parking_ratio && (
                        <PropertyListDetailsCard
                          title={"Parking Ratio"}
                          icon={"fa-arrow-right"}
                          data={parking_ratio ? parking_ratio : ""}
                        />
                      )}

                      {clear_ceiling_height && (
                        <PropertyListDetailsCard
                          title={"Clear Ceiling Height"}
                          icon={"fa-arrow-right"}
                          data={
                            clear_ceiling_height ? clear_ceiling_height : ""
                          }
                        />
                      )}

                      {doors_loading && (
                        <PropertyListDetailsCard
                          title={"Doors Loading"}
                          icon={"fa-arrow-right"}
                          data={doors_loading ? doors_loading : ""}
                        />
                      )}

                      {opportunity_zone && (
                        <PropertyListDetailsCard
                          title={"Opportunity Zone"}
                          icon={"fa-arrow-right"}
                          data={opportunity_zone ? opportunity_zone : ""}
                        />
                      )}

                      {zoning_code && (
                        <PropertyListDetailsCard
                          title={"Zoning Code"}
                          icon={"fa-arrow-right"}
                          data={zoning_code ? zoning_code : ""}
                        />
                      )}

                      {space && (
                        <PropertyListDetailsCard
                          title={"Space"}
                          icon={"fa-arrow-right"}
                          data={space ? space : ""}
                        />
                      )}

                      {space_size && (
                        <PropertyListDetailsCard
                          title={"Space Size"}
                          icon={"fa-arrow-right"}
                          data={space_size ? space_size : ""}
                        />
                      )}

                      {space_use && (
                        <PropertyListDetailsCard
                          title={"Space Use"}
                          icon={"fa-arrow-right"}
                          data={space_use ? space_use : ""}
                        />
                      )}

                      {condition && (
                        <PropertyListDetailsCard
                          title={"Condition"}
                          icon={"fa-arrow-right"}
                          data={condition ? condition : ""}
                        />
                      )}

                      {available && (
                        <PropertyListDetailsCard
                          title={"Available"}
                          icon={"fa-arrow-right"}
                          data={available ? available : ""}
                        />
                      )}

                      {sale_condition && (
                        <PropertyListDetailsCard
                          title={"Sale Condition"}
                          icon={"fa-arrow-right"}
                          data={sale_condition ? sale_condition : ""}
                        />
                      )}

                      {property_subtype && (
                        <PropertyListDetailsCard
                          title={"Property Subtype"}
                          icon={"fa-arrow-right"}
                          data={property_subtype ? property_subtype : ""}
                        />
                      )}

                      {loop_net_rating && (
                        <PropertyListDetailsCard
                          title={"Loop Net Rating"}
                          icon={"fa-arrow-right"}
                          data={loop_net_rating ? loop_net_rating : ""}
                        />
                      )}

                      {tenancy && (
                        <PropertyListDetailsCard
                          title={"Tenancy"}
                          icon={"fa-arrow-right"}
                          data={tenancy ? tenancy : ""}
                        />
                      )}
                    </div>
                  </div>

                  {disclaimer && (
                    <div className="property-news-single-card style-two border-bottom-yellow">
                      <h4>Disclaimer</h4>
                      <p className="h6">{disclaimer}</p>
                    </div>
                  )}

                  <div className="property-news-single-card ">
                    {amenities.length > 0 && <h4>Amenities</h4>}
                    <div className="">
                      <ul className="rld-list-style mb-3 mb-sm-0  row">
                        {amenities &&
                          amenities.length > 0 &&
                          amenities.map((val, i) => (
                            <li
                              key={i}
                              className={
                                "col-md-6 col-lg-6 col-xl-6 col-sm-12   show-ami pl-sm-2 p-md-0"
                              }
                            >
                              <img src={val.image} />
                              {val.name}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  {property_link && (
                    <div className="property-news-single-card style-two border-bottom-yellow">
                      <h4>Link</h4>
                      <p className="h5">
                        <a href={property_link} target="_blank">
                          Visit the Property Website{" "}
                          <i
                            className="fa fa-external-link"
                            aria-hidden="true"
                          />
                        </a>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        )}

        {/*{(!getPropertyDetailsLoader && data.length >0) &&*/}
        {/*<div className=" mx-auto mt-3">*/}
        {/*<img src={serversideIssue} width={'200px'} alt=""/>*/}
        {/*<h5 style={{color: '#fda94f'}}>Sorry... Images not found. <br/>Click Here! to add new images*/}

        {/*</h5>*/}
        {/*</div>*/}
        {/*}*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    getPropertyImagesLoader: state.property.getPropertyImagesLoader,
    getPropertyImages: state.property.getPropertyImages,
    getPropertyDetails: state.property.getPropertyDetails,
    getPropertyDetailsLoader: state.property.getPropertyDetailsLoader,
    sharePropertyUrlLoader: state.chat.sharePropertyUrlLoader,
    sharePropertyUrl: state.chat.sharePropertyUrl,
  };
};
export default connect(mapStateToProps, {
  actionGetPropertyDetails,
  actionGetPropertyImages,
  actionSharePropertyUrl,
})(PropertyDetailsSection);
