import React, { Component, Fragment } from "react";
import connect from "react-redux/es/connect/connect";
import { actionSendVerificationCode } from "../../actions/authAction";
import classnames from "classnames";
import PhoneInput from "react-phone-number-input";
import btnLoader from "../../assets/img/button-loader.svg";

class ChangeMobileNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      errors: {},
    };
    document.title = "Change Mobile Number";
  }

  handleSendOTPValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    // Mobile Name
    if (!fields.mobile) {
      formIsValid = false;
      errors.mobile = "Mobile Number Required!";
    }

    // Ends

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChangeMobNOSend = (e) => {
    e.preventDefault();
    if (this.handleSendOTPValidation()) {
      const { user_id } = this.props.auth.user;
      const changeMobileRequest = {
        user_id: user_id,
        mobile: this.state.mobile,
      };
      const { history } = this.props;
      this.props.actionSendVerificationCode(changeMobileRequest, history);
    }
  };

  render() {
    const { errors } = this.state;
    let registerLoading = false; // TODO: remove this
    return (
      <div className="col-xl-4 col-lg-5 col-md-6 register-container">
        <form
          className="contact-form-wrap contact-form-bg"
          autoComplete="off"
          noValidate={true}
          onSubmit={this.handleChangeMobNOSend}
        >
          <h4>Change Mobile Number</h4>
          <div id="user">
            <div className="rld-single-input">
              <PhoneInput
                name="mobile"
                id="mobile"
                placeholder="e.g. 919979999855"
                autoComplete="off"
                value={this.state.mobile}
                onChange={(value) => this.setState({ mobile: value })}
                className={classnames({
                  "is-invalid": errors.mobile,
                })}
              />
              {errors.mobile && (
                <span className="text-danger">{errors.mobile}</span>
              )}
            </div>
          </div>
          <div className="btn-wrap">
            {registerLoading ? (
              <button className="btn btn-yellow" type="button">
                <img src={btnLoader} alt="shapemore-login-loader" width={15} />
              </button>
            ) : (
              <button className="btn btn-yellow" type="submit">
                Send OTP
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { actionSendVerificationCode })(
  ChangeMobileNumber
);
