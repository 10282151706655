import React, {Component,Fragment} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import FavoritePropertyList from "./favorite-property-list";
import Footer from "../global-components/footer";
import {FAVOURITE} from "../../actions/types";

class FavoriteProperty extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Fragment>
                <Navbar scenario={FAVOURITE}/>
                <PageHeader headertitle="Favorite Property"/>
                <FavoritePropertyList/>
                <Footer/>
            </Fragment>
        );
    }
}

export default FavoriteProperty;