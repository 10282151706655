import React, {Component, Fragment} from 'react';
import Login from "../auth/Login";
import Register from "../auth/Register";
import ChangeMobileNumber from "../auth/ChangeMobileNumber";
import MobileVerification from "../auth/MobileVerificationCard";
import {connect} from "react-redux";


class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            webPathName: window.location.pathname
        }
    }


    render() {
        return (
            <div className="register-page-area pd-bottom-50">
                <div className="container">
                    <div className="row justify-content-center">
                        {window.location.pathname === '/register' && <Fragment>
                            <Login history={this.props.history}/>
                            <Register history={this.props.history}/>
                        </Fragment>}

                        {window.location.pathname === '/change-mobile-number' &&
                        <ChangeMobileNumber history={this.props.history}/>}

                        {window.location.pathname === '/mobile-verification' &&
                        <MobileVerification history={this.props.history}/>}

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};
export default connect(mapStateToProps, {})(Registration);