import React from 'react';
import ContentLoader from "react-content-loader";

const PropertyListLoaderCard = () => {
    return (
        <div className="col-lg-4 col-md-6">
            <div className="single-user-list text-center bg-white">
                {/*<ContentLoader*/}
                    {/*width={300}*/}
                    {/*height={300}*/}
                    {/*viewBox="0 0 450 400"*/}
                    {/*backgroundColor="#d4d4d4"*/}
                    {/*foregroundColor="#dedede"*/}
                    {/*style={{width: '100%'}}*/}

                {/*>*/}
                    {/*/!*<rect x="43" y="304" rx="4" ry="4" width="271" height="9" />*!/*/}
                    {/*/!*<rect x="44" y="323" rx="3" ry="3" width="119" height="6" />*!/*/}
                    {/*<rect x="42" y="77" rx="10" ry="10" width="388" height="217"/>*/}

                    {/*<rect x="43" y="310" rx="0" ry="0" width="320" height="22"/>*/}
                    {/*/!*<rect x="18" y="14" rx="0" ry="0" width="303" height="6" />*!/*/}
                    {/*<rect x="44" y="340" rx="0" ry="0" width="108" height="13"/>*/}
                    {/*<rect x="129" y="340" rx="0" ry="0" width="60" height="13"/>*/}
                    {/*<rect x="200" y="340" rx="0" ry="0" width="90" height="13"/>*/}
                    {/*<rect x="44" y="360" rx="0" ry="0" width="90" height="13"/>*/}

                {/*</ContentLoader>*/}

                <ContentLoader
                    speed={2}
                    width={400}
                    height={450}
                    viewBox="0 0 400 650"
                    backgroundColor="#d4d4d4"
                    foregroundColor="#dedede"
                    style={{width: '100%'}}

                >
                    <rect x="39" y="332" rx="4" ry="4" width="271" height="9" />
                    <rect x="39" y="373" rx="3" ry="3" width="119" height="6" />
                    <rect x="40" y="75" rx="10" ry="10" width="388" height="217" />
                    <circle cx="359" cy="303" r="32" />
                    <rect x="40" y="352" rx="3" ry="3" width="209" height="8" />
                    <rect x="596" y="576" rx="0" ry="0" width="5" height="14" />
                    <rect x="36" y="410" rx="0" ry="0" width="6" height="0" />
                    <rect x="36" y="530" rx="0" ry="0" width="44" height="27" />
                    <rect x="116" y="531" rx="0" ry="0" width="44" height="27" />
                    <rect x="272" y="528" rx="0" ry="0" width="105" height="29" />
                </ContentLoader>


            </div>
        </div>
    );
};

export default PropertyListLoaderCard;