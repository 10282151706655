import React, {Component} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import AgentProfileDropdownMenu from "../global-components/Agent-Profile/agent-profile-dropdown-menu";
import Chat from "../section-components/chat";
import Footer from "../global-components/footer";

class PrivateChat extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        if (!localStorage.jwtToken) {
            window.location ='/register';
            // location.replace("/register")
        }
    }

    render() {
        return (
            <div>
                <Navbar/>
                <PageHeader headertitle="Private Chat"/>
                <AgentProfileDropdownMenu match={this.props.match}/>
                <Chat match={this.props.match} showSidebar={false}/>
                <Footer/>
            </div>
        );
    }
}

export default PrivateChat;