import React from 'react';
import propertyImage from "../../assets/img/feature/5.png";
import connect from "react-redux/es/connect/connect";
import {actionDeletePropertyImage} from "../../actions/propertyAction";
import swal from 'sweetalert';

const PropertyImageCard = (props) => {
    const style = {

        position: 'absolute',
        top: '2px',
        right: '3px',
        color: 'red',
        cursor: 'pointer',
        padding: '0px 7px',
        borderRadius: '63%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 2px 10px #b3b3b3',
        background: 'white',
        fontSize: '13px',
        fontWeight: 'bold',

    };


    return (
        <div className='col-md-4 col-sm-6 col-xl-3' key={props.i}>
            <div className="card" style={{ boxShadow: 'none'}}>

                <img className="card-img-top" src={props.img} alt="Card image cap" style={{borderRadius: '15px'}}/>
                <p className="closebtn " style={style} onClick={() =>{

                    swal({
                        title: "Are you sure?",
                        text: "Once deleted, you will not be able to recover this image file!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                        .then((willDelete) => {
                            if (willDelete) {
                                props.actionDeletePropertyImage(props.id);
                                swal("Poof! Your image file has been deleted!", {
                                    icon: "success",
                                });

                            } else {
                                swal("Your image file is safe!");
                            }
                        });

                } }>X</p>


            </div>
        </div>
    );
};

export default PropertyImageCard;

