import React from 'react';

const   AgentDataItemCard = ({titile,value,icon,url,email,mobile}) => {
    return (
        <div className="col-sm-12  col-md-6 col-lg-6 col-xl-6  mb-2 px-1">
            <li>
                <div className="link_img_wrapper">

                    <i className={icon}/>
                </div>
                <p> <a href={url &&`https://${url.replace(/^https?:\/\//,'')}` ? url &&`https://${url.replace(/^https?:\/\//,'')}` : email &&`mailto://${email}`} target='_blank'>{value ? value : 'N/A'}</a></p>
                {mobile && <p><a href={`tel:${mobile}`}>{mobile ? mobile : 'N/A'}</a></p>}
            </li>
        </div>
    );
};

export default AgentDataItemCard