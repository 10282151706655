import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/home/Home";
// import About from './components/section-components/about-us';
import Routes from "./components/routing/Routes";
import { AUTH_SUCCESS, LOGOUT } from "./actions/types";

// Redux
import { Provider } from "react-redux";
import store from "./store";
//import {loadUser} from './actions/auth';
import setAuthToken from "./utils/setAuthToken";

import "./App.css";
import AgentList from "./components/agent-list/agent-list";
import Auth from "./components/auth/Auth";
import AgentProfile from "./components/agent-profile/AgentProfile";
import About from "./components/about-us/about";
import Contact from "./components/contact-us/contact";
import Privacy from "./components/privacy/privacy";
import Terms from "./components/terms/terms";
import PropertyDetail from "./components/property-details/property-detail";
import PropertyListing from "./components/property-listing/property-list";
import AgentFriendList from "./components/agent-frient-list/agent-friend";
import JawaharDodani from "./components/section-components/Jawahar-Dodani";
import jwt_decode from "jwt-decode";
import { BASE_URL } from "./web-config";
import axios from "axios";
import PrivateChat from "./components/private-chat/private_chat";
import Messages from "./components/private-chat/Messages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AgentProperty from "./components/agent-property-list/agent-property";
import UserProfile from "./components/user-profile/UserProfile";
import ResidentialProperty from "./components/add-property/Add-Property";
import MyProperty from "./components/my-property-list/my-property";
import ForgotPassword from "./components/auth/ForgotPassword";
import PropertyImages from "./components/property-details/property-images";
import ChangePassword from "./components/auth/change-password";
import PrivateRoute from "./components/common/PrivateRoute";
import * as actionTypes from "./actions/types";
import PageNotFound404 from "./components/404/PageNotFound404";
import MobileVerification from "./components/auth/mobile-verification";
import FavoriteProperty from "./components/favorite-property/favorite-property";
import Testimonials from "./components/Testimonials/testimonials";
import Process from "./components/section-components/faq";
import cities from "./components/cities/cities";

if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);

  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);

  // Load User data
  try {
    store.dispatch({ type: actionTypes.GET_USER_DATA_LOADER, payload: true });
    axios
      .post(
        `${BASE_URL}/get-user-data`,
        JSON.stringify({ user_id: parseInt(decoded.uid) })
      )
      .then((result) => {
        store.dispatch({
          type: actionTypes.GET_USER_DATA_LOADER,
          payload: false,
        });
        if (parseInt(result.data.status) === 200) {
          store.dispatch({ type: AUTH_SUCCESS, payload: result.data.data });
        } else {
          console.log("executing this part");
          //store.dispatch(setAlert(result.data.message, 'error'));
        }
      });
  } catch (error) {
    store.dispatch({ type: actionTypes.GET_USER_DATA_LOADER, payload: false });
    console.error({ "error getting user-profile data": error });
    // store.dispatch(setAlert(error.message, 'error'));
  }

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    // store.dispatch(logoutUser());
    // Redirect to login
    //window.location.href = '/auth';
  }
}

const App = () => {
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    //store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer position="bottom-right" />
      <Router>
        <Fragment>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/agent-list" component={AgentList} />
            <Route exact path="/register" component={Auth} />
            <Route exact path="/change-mobile-number" component={Auth} />
            <Route
              exact
              path="/mobile-verification"
              component={MobileVerification}
            />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/property-listing" component={PropertyListing} />
            <Route
              path="/property-listing/:location/:query"
              component={PropertyListing}
            />
            <Route
              exact
              path="/property-detail/:property_id"
              component={PropertyDetail}
            />
            <Route path="/agent-profile/:userId" component={AgentProfile} />
            <Route
              exact
              path="/agent-friend-list/:userId"
              component={AgentFriendList}
            />
            <Route
              exact
              path="/agent-property-list/:userId"
              component={AgentProperty}
            />

            <Route exact path="/jawahar-dodani" component={JawaharDodani} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/terms-of-service" component={Terms} />
            <Route
              exact
              path="/change-password/:user_id"
              component={ChangePassword}
            />
            <Route
              exact
              path="/property-images/:propertyId"
              component={PropertyImages}
            />
            <Route
              exact
              path="/cities"
              component={cities}
            />
            <Route exact path="/faq" component={Process} />

            {/*PRIVATE ROUTES*/}
            <PrivateRoute
              exact
              path="/add-property"
              component={ResidentialProperty}
            />
            <PrivateRoute
              exact
              path="/update-property/:propertyId"
              component={ResidentialProperty}
            />
            <PrivateRoute exact path="/my-properties" component={MyProperty} />
            <PrivateRoute
              exact
              path="/favourite-properties"
              component={FavoriteProperty}
            />
            <PrivateRoute exact path="/testimonials" component={Testimonials} />
            <PrivateRoute
              exact
              path="/private-chat/:userId"
              component={PrivateChat}
            />
            <PrivateRoute exact path="/messages" component={Messages} />
            <PrivateRoute path="/profile/:userId" component={UserProfile} />
            <Route component={PageNotFound404} />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
