import React, {Component,Fragment} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import TestimonialsList from "./testimonials-list";
import {TESTIMONIAL} from "../../actions/types";


class Testimonials extends Component {
    render() {
        return (
            <Fragment>
                <Navbar scenario={TESTIMONIAL}/>
                <PageHeader headertitle="Testimonials"/>
                <TestimonialsList/>
                <Footer/>
            </Fragment>
        );
    }
}

export default Testimonials;