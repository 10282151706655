import React, {Component} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import AgentPropertyList from "../section-components/agent-property";
import Footer from "../global-components/footer";

class AgentProperty extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <PageHeader headertitle="Agent Profile /Agent Property List"/>
                <AgentPropertyList match={this.props.match}/>
                <Footer/>
            </div>
        );
    }
}

export default AgentProperty;