import React, {Component} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import AgentFriendList from "../section-components/agent-friend-list";
import Footer from "../global-components/footer";
import {FRIENDS} from "../../actions/types";

class AgentFriend extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Navbar scenario={FRIENDS}/>
                <PageHeader headertitle="Agent Profile /Agent Friends List"/>
                <AgentFriendList match={this.props.match}/>
                <Footer/>
            </div>
        );
    }
}

export default AgentFriend;