import React from 'react';
import locationIcon from "../../assets/img/icons/1.png";
import currentLocationIcon from "../../assets/img/icons/current-location.png";

const SuggestedLocationItem = ({locationName, formatted_address, lat, lng, handleSelectLocation}) => {
    return (
        <div
            onClick={() => locationName === 'Current Location' ? handleSelectLocation(locationName) : handleSelectLocation(locationName, lat, lng)}
            className="suggested-location-item d-flex align-items-center align-content-between w-100 p-2">
            <div
                className="location-icon d-flex align-content-center justify-content-center">
                <img src={locationName === 'Current Location' ? currentLocationIcon : locationIcon}
                     alt="Agent Network Location Icon"/>
            </div>

            <div className="location-name pl-2 d-flex flex-column">
                <b className="location-heading">{locationName}</b>
                <small className="formatted-address">{formatted_address}</small>
            </div>

        </div>
    );
};

export default SuggestedLocationItem;