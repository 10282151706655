import React from 'react';
import defaultUser from "../../assets/img/nophoto.png";
import classnames from "classnames";

const BroadcastModelChatUser = ({user_id, name, lastMessage, profile_pic, handleSelectChatPerson, chatPerson, isActive, forBroadCastSelect,index}) => {

    return (
        <li className={classnames('contact', {
            'active-chat': isActive
        })} onClick={() => handleSelectChatPerson(user_id)}>
            <div className="wrap">
                <div className="row">
                    <div className="col-2 broad-chat-user-img">
                        <img src={profile_pic ? profile_pic : defaultUser} alt=""/>
                    </div>
                    <div className="col-10 broad-chat-recent-msg my-auto">
                        <div className="meta">
                            <p className="name text-dark" >{name}</p>

                        </div>
                    </div>
                </div>

            </div>
        </li>
    );
};

export default BroadcastModelChatUser;