import * as actionTypes from '../actions/types';

const initialState = [];

export default  function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case actionTypes.SET_ALERT:
            return [payload];
        case actionTypes.REMOVE_ALERT:
            return state.filter(alert => alert.id !== payload);
        case actionTypes.REMOVE_ALL_ALERT:
            return [];
        default:
            return state;
    }
}