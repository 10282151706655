import React from 'react';
import {useParams} from "react-router-dom";
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import PropertyDetailsSection from '../section-components/property-details-section';
import RecomandProperties from '../section-components/recomand-properties';
import Footer from '../global-components/footer';

const PropertyDetails = (props) => {
    window.scrollTo(0,0);
    let {property_id} = useParams();
    return <div>
        <Navbar/>
        <PageHeader headertitle="Property Details"/>
        <PropertyDetailsSection propertyId={property_id}/>
        {/*<RecomandProperties/>*/}
        <Footer/>
    </div>
};

export default PropertyDetails;

