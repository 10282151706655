import React from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import PrivacyPolicy from '../section-components/privacy-policy';
import Footer from '../global-components/footer';

const Privacy = () => {
    window.scrollTo(0, 0);
    return <div>
        <Navbar />
        <PageHeader headertitle="Privacy Policy" />
        <PrivacyPolicy/>
        <Footer />
    </div>
}

export default Privacy;