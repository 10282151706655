import axios from 'axios';
import * as actionTypes from '../actions/types';
import {BASE_URL} from "../web-config";
import showToast from "../utils/showToast";
import {getUserData} from "./authAction";


export const actionGetAgents = (q = '', offset) => async (dispatch, getState) => {
    console.log({offset});
    dispatch({type: actionTypes.GET_AGENTS_LOADING, payload: true});
    (q && offset === 'offset=0') && dispatch({
        type: actionTypes.REMOVE_AGENTS,
        payload: []
    });
    (!q.length > 0 && offset === 'offset=0') && dispatch({
        type: actionTypes.REMOVE_AGENTS,
        payload: []
    });
    try {
        let result = await axios.get(`${BASE_URL}/get-agents?q=${q}&${offset}&limit=62`);
        dispatch({type: actionTypes.GET_AGENTS_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            (q && offset === 'offset=0') && dispatch({
                type: actionTypes.REMOVE_AGENTS,
                payload: []
            });
            (!q.length > 0 && offset === 'offset=0') && dispatch({
                type: actionTypes.REMOVE_AGENTS,
                payload: []
            });
            dispatch({type: actionTypes.GET_AGENTS, payload: result.data.data});
        }
        else {
            dispatch({
                type: actionTypes.REMOVE_AGENTS,
                payload: []
            })
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionGetAgentFriendList = (user_id, offset) => async (dispatch, getState) => {
    console.log(offset)
    dispatch({type: actionTypes.GET_AGENTS_LOADING, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-agent-friend-list?user_id=${user_id}&${offset}`);
        dispatch({type: actionTypes.GET_AGENTS_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            (offset === 'offset=0') && dispatch({
                type: actionTypes.REMOVE_AGENTS,
                payload: []
            });
            // (!q.length > 0 && offset === 'offset=0') && dispatch({
            //     type: actionTypes.REMOVE_AGENTS,
            //     payload: []
            // });
            dispatch({type: actionTypes.GET_AGENTS, payload: result.data.data});
        }
        else {
            dispatch({
                type: actionTypes.REMOVE_AGENTS,
                payload: []
            })
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionGetAgent = (agentId) => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_AGENT_LOADING, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-agent?user_id=${agentId}`);
        dispatch({type: actionTypes.GET_AGENT_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_AGENT, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_AGENT, payload: {}});
            showToast(result.data.message, 'error');
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionSendFriendRequest = (user_id, related_user_id) => async (dispatch, getState) => {
    dispatch({type: actionTypes.SEND_FRIEND_REQUEST_LOADING, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/send-friend-request?user_id=${user_id}&related_user_id=${related_user_id}`);
        dispatch({type: actionTypes.SEND_FRIEND_REQUEST_LOADING, payload: false});
        parseInt(result.data.status) === 200 && dispatch({
            type: actionTypes.MAKE_REQUESTED, payload: {
                related_user_id: related_user_id,
                data: result.data.data
            }
        });
        showToast(result.data.message, parseInt(result.data.status) === 200 ? 'success' : 'error');
    } catch (error) {
        dispatch({type: actionTypes.SEND_FRIEND_REQUEST_LOADING, payload: false});
        showToast(error.message, 'error');
    }
};

export const actionAcceptFriendRequest = (request) => async (dispatch, getState) => {
    dispatch({type: actionTypes.ACCEPT_FRIEND_REQUEST_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/accept-friend-request`, JSON.stringify(request));
        dispatch({type: actionTypes.ACCEPT_FRIEND_REQUEST_LOADING, payload: false});
        request.notification_id ? dispatch({
            type: actionTypes.REMOVE_NOTIFICATION,
            payload: request.notification_id
        }) : dispatch(getUserData(request.user_id));

        dispatch({type: actionTypes.ACCEPT_FRIEND_REQUEST, payload: {itemId: request.itemId, data: result.data.data}});

        showToast(result.data.message, parseInt(result.data.status) !== 200 ? 'error' : 'success');
    } catch (error) {
        dispatch({type: actionTypes.ACCEPT_FRIEND_REQUEST_LOADING, payload: false});
        showToast(error.message, 'error');
    }
};

export const actionUnFriend = (request) => async (dispatch, getState) => {
    dispatch({type: actionTypes.UN_FRIEND_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/un-friend`, JSON.stringify(request));
        dispatch({type: actionTypes.UN_FRIEND_LOADING, payload: false});
        dispatch({type: actionTypes.UN_FRIEND, payload: request.related_user_id});
        showToast(result.data.message, parseInt(result.data.status) === 200 ? 'success' : 'error');
    } catch (error) {
        dispatch({type: actionTypes.UN_FRIEND_LOADING, payload: false});
        showToast(error.message, 'error');
    }
};

export const actionRejectRequest = (request) => async (dispatch, getState) => {
    dispatch({type: actionTypes.REJECT_FRIEND_REQUEST_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/reject-request`, JSON.stringify(request));
        dispatch({type: actionTypes.REJECT_FRIEND_REQUEST_LOADING, payload: false});
        dispatch(getUserData(request.user_id));
        request.itemId && dispatch({type: actionTypes.RESET_USER_RELATION, payload: request.itemId});
        showToast(result.data.message, parseInt(result.data.status) === 200 ? 'success' : 'error');
    } catch (error) {
        dispatch({type: actionTypes.REJECT_FRIEND_REQUEST_LOADING, payload: false});
        showToast(error.message, 'error');
    }
};


// Action Add Testimonials
export const actionAddTestimonial = (testimonialData, history) => async (dispatch, getState) => {
    dispatch({type: actionTypes.ADD_TESTIMONIAL_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/add-update-testimonials`, JSON.stringify(testimonialData));
        dispatch({type: actionTypes.ADD_TESTIMONIAL_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            showToast(result.data.message, 'success');
            dispatch({type: actionTypes.ADD_TESTIMONIAL, payload: result.data.data});// propertyData.property_id && history.push(`/my-properties`)
        } else
            dispatch({type: actionTypes.ADD_TESTIMONIAL_FAILED, payload: result.data.data});
    } catch (error) {
        showToast(error.message, 'error');
    }
};

// Update Add Testimonials
export const actionUpdateTestimonial = (testimonialData, history) => async (dispatch, getState) => {
    console.log(testimonialData);
    dispatch({type: actionTypes.UPDATE_TESTIMONIALS_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/add-update-testimonials`, JSON.stringify(testimonialData));
        dispatch({type: actionTypes.UPDATE_TESTIMONIALS_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            showToast(result.data.message, 'success');
            console.log(result.data.data)
            dispatch({type: actionTypes.UPDATE_TESTIMONIALS, payload: result.data.data});// propertyData.property_id && history.push(`/my-properties`)
        } else
            dispatch({type: actionTypes.UPDATE_TESTIMONIALS_FAILED, payload: result.data.data});
    } catch (error) {
        showToast(error.message, 'error');
    }
};


// Action Get Testimonials
export const actionGetTestimonials = (loggedId) => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_TESTIMONIALS_LOADING, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-testimonial?loggedin_id=${loggedId}`);
        dispatch({type: actionTypes.GET_TESTIMONIALS_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_TESTIMONIALS, payload: result.data.data});

        } else {
            dispatch({type: actionTypes.GET_TESTIMONIALS, payload: []});
            showToast(result.data.message, 'error');
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

//Upload Testimonials Image
export const uploadTestimonialImage = (propertyImgInfo, setTestimonialImagePreview) => async dispatch => {
    try {
        const result = await axios.post(`${BASE_URL}/upload-testimonial-image`, propertyImgInfo, {headers: {'Content-Type': 'multipart/form-data'}});
        if (result.data.status === 200) {
            setTestimonialImagePreview && setTestimonialImagePreview(result.data.data);
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};



//Delete Testimonials
export const actionDeleteTestimonials = (testimonial_id) => async (dispatch, getState) => {

    dispatch({type: actionTypes.DELETE_TESTIMONIALS_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/delete-testimonials?testimonial_id=${testimonial_id}`);
        dispatch({type: actionTypes.DELETE_TESTIMONIALS_LOADING, payload: false});

        if (parseInt(result.status) === 200) {
            dispatch({type: actionTypes.DELETE_TESTIMONIALS_SUCCESS, payload: parseInt(testimonial_id)});
            showToast(result.message, 'success');
        } else {
            showToast(result.message, 'Failed');
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};
