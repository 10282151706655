import React from 'react';
import ContentLoader from "react-content-loader";

const PartnerLoaderCard = () => {
    return (

            <div className="client-slider item bg-white p-3 border-radius-10 mr-4 row justify-content-center align-items-center">
                <div className="thumb text-center">

                    <ContentLoader
                        width={400}
                        height={40}
                        backgroundColor="#ababab"
                        foregroundColor="#fafafa"
                    >
                        {/*<rect x="70" y="15" rx="5" ry="5" width="300" height="15" />*/}
                        {/*<rect x="70" y="39" rx="5" ry="5" width="220" height="9" />*/}
                        <rect x="100" y="10" rx="0" ry="0" width="40" height="100" />
                        <rect x="195" y="10" rx="0" ry="0" width="40" height="100" />
                        <rect x="280" y="10" rx="0" ry="0" width="40" height="100" />
                    </ContentLoader>

                    {/*<ContentLoader*/}
                        {/*viewBox="0 0 400 160"*/}
                        {/*height={60}*/}
                        {/*width={150}*/}
                        {/*backgroundColor="transparent"*/}

                    {/*>*/}
                        {/*<circle cx="150" cy="86" r="8" />*/}
                        {/*<circle cx="194" cy="86" r="8" />*/}
                        {/*<circle cx="238" cy="86" r="8" />*/}
                    {/*</ContentLoader>*/}
                </div>
            </div>

    );
};

export default PartnerLoaderCard;