import React, {Fragment} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import MobileVerificationCard from "./MobileVerificationCard";
import {useHistory} from "react-router-dom";

const MobileVerification = () => {
    const history = useHistory();
    return (
        <Fragment>
            <Navbar/>
            <PageHeader headertitle={"Mobile Verification"}/>
            <div className="register-page-area pd-bottom-50">
                <div className="container">
                    <div className="row justify-content-center">
                        <MobileVerificationCard history={history}/>
                    </div>
                </div>
            </div>

            <Footer/>
        </Fragment>
    );
};

export default MobileVerification;