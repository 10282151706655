import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {actionForgotPassword, actionLogin} from "../../actions/authAction";
import btnLoader from "../../assets/img/button-loader.svg";

class ForgotPasswordCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errors: {}
        }
        document.title = 'Forgot Password';
        window.scrollTo(0, 0);
    }

    onChange = e => this.setState({[e.target.name]: e.target.value});

    handleChangePasswordValidation = () => {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        const validEmailRegex = RegExp(
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );

        if (!fields.email) {
            formIsValid = false;
            errors.email = "Email must be Required!";
        }
        else if (!validEmailRegex.test(fields.email)) {
            formIsValid = false;
            errors.email = "Email must be  be  Valid!";
        }
        this.setState({errors: errors});
        return formIsValid;
    };


    onSubmitChangePassword = e => {
        e.preventDefault();
        if (this.handleChangePasswordValidation()) {
            this.props.actionForgotPassword(this.state.email);
            this.setState({email: '',});

        }
    };

    render() {
        const {forgotPasswordLoader} = this.props.auth;
        const {errors} = this.state;
        return (
            <div className="register-page-area pd-bottom-50">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-5 col-md-6 register-container">
                            <form className="contact-form-wrap contact-form-bg" autoComplete="off" noValidate={true}
                                  onSubmit={this.onSubmitChangePassword}>
                                {/*<h4>Forgot Password</h4>*/}
                                <div id="user">
                                    <div className="rld-single-input">
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            className="rdl-mob"
                                            value={this.state.email.trim()}
                                            onChange={this.onChange}
                                            placeholder="Enter Your Email"/>
                                        {errors.email && <span className='text-danger'>{errors.email}</span>}
                                    </div>
                                </div>
                                <div className="btn-wrap text-center">

                                    {forgotPasswordLoader ?
                                        <button
                                            className="btn btn-yellow"
                                            type="button">
                                            <img src={btnLoader} alt="shapemore-login-loader"
                                                 width={15}/>
                                        </button> :
                                        <button
                                            className="btn btn-yellow"
                                            type="submit">Forgot Password
                                        </button>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps, {actionForgotPassword})(ForgotPasswordCard);


