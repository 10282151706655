import React from 'react';
import classnames from "classnames";

const PropertyCommonInput = (props) => {
    return (
        <div className="row mt-4 ">
            <div className="col-md-7  col-sm-12 text-md-right mt-1 mx-auto">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-4 "><label>{props.label}<span className={'text-danger'}>{props.required}</span></label></div>
                    <div className="col-md-8">
                        <div className="rld-single-input mt-0 text-center ">
                            <input type={props.intype ? props.intype: 'text'}
                                   name={props.name}
                                   id={props.name}
                                   value={props.value}
                                   onChange={props.onChange}
                                   className={classnames({
                                       'is-invalid': props.error
                                   })}
                                   placeholder={props.placeholder}
                                   min={props.min ? props.min: ''}
                                   max={props.len ? props.len: ''}
                                   maxDate={props.len}
                                   list={props.list ? props.list : ''}
                            />
                            {
                                <datalist  id={props.list ? props.list :''}>

                                        {props.data && props.data.map((val, i) => <option value={val.name} key={i}>{val.name}</option>) ? props.data && props.data.map((val, i) => <option value={val.name} key={i}>{val.name}</option>) : null}
                                    {props.sdata && props.sdata.map((val,i)=><option value={val.placeName} key={i}>{val.placeName}</option>) ? props.sdata && props.sdata.map((val,i)=><option value={val.placeName} key={i}>{val.placeName}</option>) : null}
                                    {props.cdata && props.cdata.map((val,i)=><option value={val.placeName} key={i}>{val.placeName}</option>) ? props.cdata && props.cdata.map((val,i)=><option value={val.placeName} key={i}>{val.placeName}</option>) : null}

                               </datalist>
                                }
                            {props.error && <span className='text-danger'>{props.error}</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyCommonInput;