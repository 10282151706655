import React from 'react';
import DataNotFound from "../section-components/DataNotFound";
import img from  '../../assets/img/404.svg';

const PageNotFound404Card = () => {
    return (
        <div className="text-center mt-3 mb-3 pb-3" style={{lineHeight:'1px'}}>
            <img src={img} height={'400px'} width={'500px'} alt="Page Not Found"/>
            <h1 className="font-weight-bold" style={{color:'#fda94f'}}>404</h1>
            <p className="h4 font-weight-bold">Page Not Found</p>
        </div>
    );
};

export default PageNotFound404Card;